@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/headlines';

.select_category {
  display: flex;
  gap: var(--bzr-spacing-premedium);
  margin-top: var(--bzr-spacing-medium-24);
}

@media (max-width: 1439px) {
  .select_category {
    width: max-content;
  }
}

@include bzr-breakpoint('tablet') {
  .category,
  .sub_category,
  .under_sub_category,
  .allowed_ad_type {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    margin-top: -10px;

    h4 {
     @include --bzr-h4();
      color: var(--bzr-text-dark-black-primary);
      padding: 10px 0;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .category,
  .sub_category,
  .under_sub_category,
  .allowed_ad_type {
    gap: 0;

    h4 {
      padding: var(--bzr-spacing-premedium) 0;
    }
  }
}