.wrapper {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  color: #414245;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 18.85px */

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Количество строк, которые будут отображаться */
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  br {
    display: block;
  }
}

.action {
  display: inline-flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px !important;
}

.expanded {
  max-height: none !important; /* Убираем ограничение высоты */
  -webkit-line-clamp: unset !important; /* Снимаем ограничение строк */
}
