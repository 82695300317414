@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.create_event_wrapper {
  @include flex-column($gap: var(--bzr-spacing-large-44));
  width: 100%;
  margin-top: 24px;

  .controls {
    @include flex-column();
    width: 67%;

    .logo {
      svg {
        width: 143px;
        height: 44px;
      }
      .logo_poster {
        width: fit-content;
      }
    }

    h1 {
      @include font($size: 40px, $weight: 600, $color: var(--bzr-main-blue-default))
    }
  }

  .form {
    @include flex-column($gap: var(--bzr-spacing-large));
    width: 67%;

    .field {
      @include flex-column($gap: var(--bzr-spacing-medium-24));
      width: 100%;

      h2 {
        @include font($size: 24px, $weight: 600);
      }

      &_inner {
        display: flex;
        justify-content: space-between;
        width: 100%;

        p {
          @include font($color: var(--bzr-deep-medium-grey));
          line-height: 23.2px;
          span {
            color: var(--bzr-secondary-error);
          }
        }

        .values {
          position: relative;
          display: flex;
          gap: var(--bzr-spacing-xs);
          flex-wrap: wrap;
          width: 67%;
          min-width: 67%;
          label {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
            border: 0.5px solid var(--bzr-secondary-light-grey);
            border-radius: var(--bzr-radius-s);
            cursor: pointer;
            @include font($size: 14px);
            input {
              display: none;
            }
          }
          .age {
            height: 40px;
          }
          div input {
            height: 40px;
            padding: var(--bzr-spacing-xxs) var(--bzr-spacing-small) var(--bzr-spacing-xxs) 42px;
            &::placeholder {
              color: var(--bzr-medium-grey);
              font-weight: 400;
            }
          }
          svg {
            position: absolute;
            top: 8px;
            left: 10px;
            width: 24px;
            height: 24px;
            color: #A7AAAF;
          }
          input[type="date"],
          input[type="time"] {
            -moz-appearance: none !important;
            appearance: none !important;
            width: 217px;
            height: 40px;
            padding: var(--bzr-spacing-xxs) var(--bzr-spacing-small) var(--bzr-spacing-xxs) 42px;
            padding-left: 40px;
            border: 1px solid var(--bzr-secondary-light-grey);
            border-radius: var(--bzr-radius-s);
            background-color: white;
            @include font($size: 14px);
            &:focus {
              border-color: transparent;
              outline: 3px solid var(--secondary-light-green, #D9FFCB);
            }
          }
          input[type="date"]:before,
          input[type="time"]:before {
            position: absolute;
            z-index: 5;
            content: attr(placeholder);
            background-color: white;
            color: var(--bzr-medium-grey);
            font-weight: 400;
            padding-right: 2rem;
          }
          input[type="date"]:focus:before,
          input[type="date"]:valid:before,
          input[type="time"]:focus:before,
          input[type="time"]:valid:before {
            content: "";
          }
          input[type="date"]::-webkit-calendar-picker-indicator,
          input[type="time"]::-webkit-calendar-picker-indicator {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            opacity: 0;
            width: 40px;
            height: 40px;
          }

          p {
            @include font($size: 13px, $color: var(--bzr-text-medium-grey));
          }

          .images {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 10px;
            row-gap: 10px;
            width: 100%;
            .images_input_wrapper {
              @include flex-column($gap: var(--bzr-spacing-xs));
              label {
                position: relative;
                width: 100%;
                height: 108px;
                border: none;
                padding: 0;
                input {
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-color: var(--bzr-secondary-very-light-grey-2);
                  border-radius: var(--bzr-radius-s);
                  padding: 0;
                  cursor: pointer;
                  color: transparent;
                  &::file-selector-button {
                    opacity: 0;
                  }
                  &::-webkit-file-upload-button {
                    opacity: 0;
                  }
                  &::-ms-browse {
                    opacity: 0;
                  }
                  &:focus {
                    background-color: var(--bzr-secondary-very-light-grey);
                    outline: 3px solid var(--bzr-secondary-light-green);
                  }
                }
                svg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 48px;
                  height: 48px;
                  color: var(--bzr-text-dark-black-primary);
                }
              }
              .images_error {
                @include font($size: 12px, $color: var(--bzr-secondary-error));
              }
            }
            .chosen_image {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 108px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: var(--bzr-radius-s);
              }
              span {
                position: absolute;
                top: 7px;
                right: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                background-color: white;
                border-radius: 50%;
                cursor: pointer;
                svg {
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  width: 16px;
                  height: 16px;
                  color: var(--bzr-text-dark-black-primary);
                }
              }
            }
          }

          .error {
            padding: 0;
            @include font($size: 12px, $color: var(--bzr-secondary-error));
          }
        }

        .price {
          @include flex-column($gap: var(--bzr-spacing-premedium));
          .from_to {
            display: flex;
            gap: var(--bzr-spacing-premedium);
            width: 100%;
          }

          .for_free {
            display: flex;
            align-items: center;
            gap: var(--bzr-spacing-premedium);
            @include font($size: 14px);
          }
        }
      }
    }

    .submit {
      padding-left: var(--bzr-spacing-large);
      padding-right: var(--bzr-spacing-large);
      font-size: 18px;
    }
  }
}

@media (max-width: 1024px) {
  .create_event_wrapper {
    gap: var(--bzr-spacing-xs);
    width: 100%;
    margin: 16px 0 20px 0;
    .form {
      gap: var(--bzr-spacing-large);
      width: 100%;
      .field {
        gap: var(--bzr-spacing-medium);
        &_inner {
          flex-direction: column;
          gap: var(--bzr-spacing-small);
          .values {
            width: 100%;
            div {
              width: 100%;
            }
            .date,
            .time {
              width: 100% !important;
            }
            .images {
              grid-template-columns: repeat(4, 1fr);
              gap: var(--bzr-spacing-small);
            }
          }
          &_date {
            grid-area: date;
          }
          &_time {
            grid-area: time;
          }
          .price {
            .from_to {
              gap: var(--bzr-spacing-small);
            }
          }
        }
        &_date {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            'header header'
            'date time';
          column-gap: var(--bzr-spacing-small);
          h2 {
            grid-area: header;
          }
        }
      }
      .submit {
        width: 100%;
        padding: 13.6px var(--bzr-spacing-premedium);
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .create_event_wrapper {
    margin-top: 12px;
    .form {
      gap: var(--bzr-spacing-medium-24);
      margin-top: 0;

      .field {
        gap: var(--bzr-spacing-premedium);
        &_inner {
          justify-content: flex-start;

          h2,
          .type_header {
            margin-bottom: 4px;
            @include font($size: 20px, $weight: 600);
            span {
              font-weight: 500;
            }
          }

          p {
            @include font($size: 16px, $weight: 600);
          }

          .values {
            width: 100%;

            .select_icon {
              position: absolute;
              top: 8px;
              left: unset;
              right: 10px;
              width: 24px;
              height: 24px;
              color: var(--bzr-text-dark-black-primary);
            }
            input[type="date"],
            input[type="time"] {
              width: 100%;
              background-color: var(--bzr-secondary-very-light-grey-2);
            }
            input[type="date"]:before,
            input[type="time"]:before {
              background-color: var(--bzr-secondary-very-light-grey-2);
            }

            .images {
              grid-template-columns: repeat(2, 1fr);
              column-gap: 9px;
            }
          }
          .price {
            .from_to {
              justify-content: space-between;
              gap: var(--bzr-spacing-xs);
            }
            .for_free {
              flex-direction: row-reverse;
              justify-content: space-between;
              width: 100%;
            }
          }
        }
        &_date {
          display: flex;
        }
      }
    }
  }
}
