@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/media-queries';

.item {
  display: flex;
  width: 100%;
  padding: 17px var(--bzr-spacing-medium-24);
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  border-radius: 12px;
  word-break: break-all;

  color: #fff;
  @include --bzr-small-button-medium;

  &_view {
    justify-content: center;
    border: 1px solid #fff;

    svg path {
      fill: #2c2d31;
    }
  }

  &_add {
    justify-content: flex-start;
    border: 1px dashed #fff;

    svg path {
      fill: #fff;
    }
  }

  &_text {
    max-width: 32ch;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}

@include bzr-breakpoint('tablet') {
  .item {
    @include --bzr-small-button-semibold;
    color: #47c612;
    width: 100%;

    &_view {
      border: 1px solid #47c612;

      svg {
        color: #47c612;
      }

      svg path {
        fill: #fff;
      }
    }

    &_add {
      border: 1px dashed #47c612;

      svg path {
        fill: #47c612;
      }
    }
  }
}
