@import '@/styles/variables/mixins/media-queries';

.secondary_header_wrapper {
  width: 100vw;
  height: 100px;
  background-color: var(--bzr-main-bg);

  .secondary_header {
    margin: 0 auto;
    padding: 24px 0 32px;
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    .logo {
      svg {
        width: 143px;
        height: 44px;
      }
    }

    .search_bar {
      width: 100%;
    }
  }

  .add_event_mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-small) !important;
    border-bottom: 0.5px solid var(--bzr-secondary-light-grey);

    h2 {
      font-size: 16px;
      font-weight: 600;
      color: var(--bzr-black-primary);
    }

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}

.add_event_header {
  height: 24px;
  .secondary_header {
    display: none;
  }
}

@media (max-width: 1200px) {
  .secondary_header_wrapper {
    .secondary_header {
      padding: 24px 20px 32px;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .secondary_header_wrapper {
    height: 67.5px;

    .secondary_header {
      padding: 12px 20px 8px;

      .back_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .secondary_header_wrapper {
    .secondary_header {
      padding: 12px 12px 8px;

      .back_btn {
        width: 40px;
        height: 40px;
      }
    }
  }
}
