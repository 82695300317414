@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/body';
@import '@/styles/variables/mixins/media-queries';

.header {
  margin-bottom: var(--bzr-spacing-xs-8);
}

.choose {
  margin-bottom: var(--bzr-spacing-medium-24);
  @include --bzr-body-medium-145(14px);
  color: var(--bzr-text-dark-black-primary);
}

.links {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-medium-24);

  .link {
    @include --bzr-small-button-medium();
    color: var(--bzr-text-dark-black-primary);
  }
}

@media (max-width: 768px) {
  .choose {
    margin-bottom: var(--bzr-spacing-small);
    @include --bzr-body-medium(12px);
  }

  .links {
    gap: var(--bzr-spacing-xs);

    .link {
      @include --bzr-body-medium(12px);
    }
  }
}

@include bzr-breakpoint('mobile') {
  .modal {
    gap: unset;

    [class*="modal__title"] {
      text-align: start;
      width: initial;
    }
  }
}
