@import '@/styles/variables/mixins/media-queries';

.form_wrapper {
  margin-top: var(--bzr-spacing-large-44);
  max-width: 812px;

  [class*="star-notification_wrapper"] {
    margin-bottom: var(--bzr-spacing-large-44);
  }

  .form {
    margin-bottom: var(--bzr-spacing-large-44);
  }
}

@include bzr-breakpoint('tablet') {
  .form_wrapper {
    max-width: none;
    margin-top: 0;
    position: relative;

    [class*="star-notification_wrapper"] {
      margin-bottom: var(--bzr-spacing-large);
    }
  }
}

@include bzr-breakpoint('mobile') {
  .form_wrapper {

    [class*="star-notification_wrapper"] {
      margin-bottom: var(--bzr-spacing-medium-24);
    }
  }
}