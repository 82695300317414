@import '@/styles/variables/mixins/fonts/buttons';

.svg_icon {
  font-size: 24px;
  color: var(--bzr-text-dark-black-primary);
}

.save {
  padding: 0;
  font-size: 14px;
  @include bzr-montserrat();
}