.posters_container {
  .calendar {
    padding: 0px 0px 24px 0px;
  }

  .list {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .not_events {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      align-self: stretch;

      &_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        h5 {
          color: #2c2d31;
          text-align: center;
          font-family: Montserrat;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          color: var(--text-medium-grey, #a7aaaf);
          text-align: center;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      img {
        width: 254px;
        height: 254px;
      }
    }

    .popular {
      width: 100%;
      padding-top: 80px;
    }
  }
}

@media (max-width: 1024px) {
  .posters_container {
    .calendar {
      padding: 0px 0px 20px 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .posters_container {
    .calendar {
      padding: 0px 0px 8px 0px;
    }

    .list {
      margin-top: 32px;

      .not_events {
        gap: 12px;

        &_title {
          gap: 12px;

          h5 {
            font-size: 18px;
          }

          p {
            font-size: 14px;
          }
        }

        img {
          width: 180px;
          height: 180px;
        }
      }

      .popular {
        padding-top: 44px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .posters_container {
    .list {
      margin-top: 16px;

      .not_events {
        &_title {
          gap: 8px;
        }

        img {
          width: 150px;
          height: 150px;
        }
      }

      .popular {
        padding-top: 36px;
      }
    }
  }
}
