@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/media-queries';

.button {
  flex: 1 1 calc(50% - 8px);
  @include --bzr-small-button-medium;
  color: #fff;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.toast {
  top: 101%;
}

@include bzr-breakpoint('tablet') {
  .button {
    flex: 1 1 100%;
    @include --bzr-small-button-semibold;
  }
}
