@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/media-queries';

.share_button {
  @include --bzr-m-button-medium;
}

@include bzr-breakpoint('tablet') {
  .share_button {
    @include --bzr-small-button-semibold;
  }
}
