@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/media-queries';

.images_wrapper {
  width: 545px;

  .descriptionFirst {
    @include --bzr-small-button-medium();
    color: var(--bzr-text-dark-black-primary);
    margin-bottom: var(--bzr-spacing-small);
  }

  .descriptionSecond {
    @include --bzr-caption-medium-fontsize(13px);
    color: var(--bzr-text-medium-grey);
    margin-bottom: var(--bzr-spacing-small);
  }

  .images {
    display: grid;
    grid-template-columns: repeat(3, 171px);
    gap: var(--bzr-spacing-small);
    align-items: center;

    .input {
      width: 171px;
      height: 108px;
      background-color: var(--bzr-secondary-very-light-grey-2);
      border-radius: var(--bzr-radius-s);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      input[type="file"] {
        width: 0;
        height: 0;
        opacity: 0;
      }

      svg {
        width: 40px;
        height: 36px;
        color: var(--bzr-text-dark-black-primary);
      }
    }

    .photo {
      width: 171px;
      height: 108px;
      background-size: cover;
      background-position: center;
      border-radius: var(--bzr-radius-s);
      border: 0.5px solid var(--bzr-secondary-light-grey);
      position: relative;

      span {
        padding: var(--bzr-spacing-xxs) var(--bzr-spacing-xs);
        border-radius: var(--bzr-radius-m);
        border: none;
        background-color: var(--bzr-main-bg);
        box-shadow: 0 0 6px 0 rgba(116, 116, 116, 0.25);
        @include --bzr-caption-medium-fontsize(12px);
        position: relative;
        top: 13px;
        left: 8px;
      }

      .delete_img {
        box-shadow: 0 0 6px 0 rgba(116, 116, 116, 0.25);
        display: flex;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: var(--bzr-radius-s);
        border: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }

  }
}

@media (max-width: 1024px) {
  .images_wrapper {
    width: 100%;

    .images {
      grid-template-columns: repeat(4, 173px);
      gap: var(--bzr-spacing-premedium) var(--bzr-spacing-small);

      .input {
        width: 173px;
      }
    }
  }
}

@media (max-width: 767px) {
  .images_wrapper {
    .images {
      grid-template-columns: repeat(3, 171px);
      gap: 8px 9px;

      .input {
        width: 171px;
      }
    }
  }
}
@media (max-width: 565px) {
  .images_wrapper .images {
    grid-template-columns: repeat(2, 171px);
  }
}