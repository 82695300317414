@mixin bzr-base-primary-button() {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 var(--bzr-spacing-premedium);
  gap: var(--bzr-spacing-xxs);
  border-radius: var(--bzr-radius-s);
  border: none;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;

  &:disabled {
    cursor: default;
  }
}
