@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/caption';

.price_wrapper {
  width: 545px;
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-premedium);

  .input_wrapper {
    position: relative;

    .input {
      height: 40px;
      width: 110px;
      padding: 12px 28px 12px 12px;
    }

    .icon {
      position: absolute;
      font-size: 16px;
      color: var(--bzr-dark-black-primary);
      top: 12px;
      left: 82px;
    }
  }

  .switcher_wrapper {
    width: max-content;
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);

    p {
      @include --bzr-caption-medium-fontsize(14px);
      color: var(--bzr-dark-black-primary);
    }
  }
}

.error {
  font-size: 12px !important;
  color: var(--bzr-secondary-error) !important;
}

@include bzr-breakpoint('mobile') {
  .price_wrapper {
    width: 100%;

    .input_wrapper {
      .input {
        width: 100%;
      }

      .icon {
        left: unset;
        right: 28px;
      }
    }
  }
}