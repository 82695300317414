.ads_block {
  max-width: 1200px;

  h3 {
    margin-bottom: 20px;
    color: #0085C5;
    font-size: 40px;
    font-weight: 600;
  }

  .ads_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 227px);
    gap: 16px;
    &__recent {
      grid-template-columns: repeat(10, 18.95%);
      overflow-x: auto;
    }
  }
}

.marginTop {
  padding-top: var(--bzr-spacing-xxl);
}

.recent {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      margin-bottom: 0;
    }

    .slider {
      display: flex;
      gap: var(--bzr-spacing-small);

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        padding: var(--bzr-spacing-xxs);
        border-radius: var(--bzr-radius-s);
        background: var(--bzr-secondary-very-light-grey);
      
        svg {
          flex-shrink: 0;
          width: 32px;
          height: 32px;
          color: var(--bzr-text-dark-black-primary);
        }
      
        &:hover {
          background: var(--bzr-secondary-light-grey);
        }
      
        &:disabled {
          cursor: default;
          pointer-events: none;
          svg {
            color: var(--bzr-text-medium-grey);
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .ads_block {
    .ads_container {
      grid-template-columns: repeat(3, 1fr);
      &__recent {
        grid-template-columns: repeat(10, 32.1%);
      }
    }
  }
}

@media (max-width: 1024px) {
  .ads_block {
    h3 {
      font-size: 32px;
    }
  }
  .marginTop {
    padding-top: var(--bzr-spacing-large-44);
  }
}

@media (max-width: 900px) {
  .ads_block {
    .ads_container__recent {
      grid-template-columns: repeat(10, 31.95%);
    }
  }
}

@media (max-width: 768px) {
  .ads_block {
    h3 {
      font-size: 32px;
    }

    .ads_container {
      gap: 9px;
      justify-content: space-evenly;
      &__recent {
        grid-template-columns: repeat(10, 32.5%);
        justify-content: start;
      }
    }
  }
  .marginTop {
    padding-top: 50px;
  }
}

@media (max-width: 740px) {
  .ads_block {
    .ads_container {
      grid-template-columns: repeat(2, 1fr);
      &__recent {
        grid-template-columns: repeat(10, 49.3%);
      }
    }
  }
}

@media (max-width: 600px) {
  .ads_block {
    .ads_container__recent {
      grid-template-columns: repeat(10, 49.1%);
    }
  }
}

@media (max-width: 480px) {
  .ads_block {
    h3 {
      margin-bottom: 12px;
      font-size: 24px;
    }

    .ads_container {
      gap: var(--bzr-spacing-small) 9px;
      &__recent {
        grid-template-columns: repeat(10, 48.55%);
      }
    }
  }
  .marginTop {
    padding-top: var(--bzr-spacing-large);
  }
  .recent .header {
    margin-bottom: var(--bzr-spacing-small);
    .slider {
      gap: var(--bzr-spacing-xs);
      .button {
        width: 24px;
        height: 24px;
        border-radius: var(--bzr-radius-xs);
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .ads_block {
    .ads_container {
      grid-template-columns: repeat(auto-fit, 171px);
      &__recent {
        grid-template-columns: repeat(10, 48%);
      }
    }
  }
}

@media (max-width: 374px) {
  .ads_block {
    .ads_container {
      grid-template-columns: 1fr 1fr;
      &__recent {
        grid-template-columns: repeat(10, 48%);
      }
    }
  }
}