@mixin base-styles-text() {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;

  color: #2c2d31;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  word-break: break-word;
  white-space: pre-line;
}

.wrapper_recipient {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.wrapper_sender {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.container {
  display: flex;
  max-width: 465px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  word-break: break-all;
  white-space: normal;

  &_recipient {
    display: flex;
    width: 100%;
    align-items: flex-end;
    gap: 12px;

    &_time {
      flex-shrink: 0;
      padding-bottom: 16px;
    }

    .recipient {
      flex-direction: column;
    }

    .support_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 100px;
      width: 32px;
      height: 32px;
      background-color: var(--bzr-secondary-white-grey);
      svg {
        width: 17.03px;
        height: 23.42px;
        color: #47C712;
      }
    }
  }

  &_status {
    flex-shrink: 0;
    display: flex;
    padding-bottom: 16px;
    align-items: flex-end;
    gap: 4px;
    align-self: stretch;
  }
}

.sender {
  @include base-styles-text;
  border-radius: 16px 16px 0 16px;
  background: #d9ffcb;
}

.recipient {
  @include base-styles-text;
  border-radius: 16px 16px 16px 0;
  background: #daf3ff;
}

.time {
  color: #a7aaaf;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.message_image_container {
  position: relative;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #606060e5;
    border-radius: var(--bzr-radius-s);
  }
}

.message_image {
  width: 190px;
  height: 160px;
  border-radius: var(--bzr-radius-s);
  object-fit: cover;
}

@media (max-width: 1024px) {
  .container {
    max-width: 82%;

    &_recipient {
      &_time {
        padding-bottom: var(--bzr-spacing-xs);
      }
    }

    &_status {
      padding-bottom: var(--bzr-spacing-xs);
    }
  }

  .sender,
  .recipient {
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
    font-size: 13px;
  }
}
