@mixin bzr-secondary-black-button() {
  color: var(--bzr-text-dark-black-primary);
  transition: background-color 0.15s, color 0.15s;

  &:hover:not(:disabled) {
    color: var(--bzr-main-blue-default);
  }
  &:active:not(:disabled) {
    color: var(--bzr-main-blue-pressed);
  }
}
