.city_selection {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .current_location {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 0.5rem 1rem;
    color: #0085c5;
  }

  .list_locations {
    list-style-type: none;

    .city {
      color: #2c2d31;
      padding: 4px;
      height: 40px;
      cursor: pointer;

      &:hover {
        color: #0093db;
      }
      &:active {
        color: #005f8d;
      }
    }
  }
}
