@import '../../../../styles/variables/mixins/media-queries.scss';

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin font-size($size: 14px, $weight: 400) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
}

.search_bar {
  width: 100%;
  position: relative;

  .mobile_button_close {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .search_bar {
    position: inherit;
  }
}

@include bzr-breakpoint('mobile') {
  .mobile_search_bar {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: #ffff;

    .field {
      width: 100%;
      display: flex;
      gap: 8px;
      padding: 8px 12px 16px 12px;
      align-items: center;

      .mobile_button_close {
        width: 40px;
        height: 40px;
        font-size: 24px;
        color: #2C2D31;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
