.buttonRequest {
  height: fit-content;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: var(--bzr-text-dark-black-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
