@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/body';
@import '@/styles/variables/mixins/fonts/buttons';

.buttons_wrapper {
  display: flex;
  gap: var(--bzr-spacing-small);

  button {
    @include --bzr-body-medium(18px);
  }

  @include bzr-breakpoint('tablet') {
    width: 100%;
    padding: 16px 20px;
    position: fixed;
    z-index: 1001;
    left: 0;
    bottom: 0;
    background-color: #fff;

    button {
      width: 100%;
    }

    .button {
      &_publication {
        left: 20px;
        right: 20px;
        bottom: 81px;
      }
    }
  }

  @include bzr-breakpoint('mobile') {
    padding: 12px;

    button {
      width: 100%;
      @include --bzr-small-button-semibold;
    }

    .button {
      &_publication {
        left: 12px;
        right: 12px;
        bottom: 61px;
      }
    }
  }
}

.button {
  &_publication {
    position: absolute;
    right: 0;
    display: inline-flex;
    flex-shrink: 0;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: var(--modal-modal-40, rgba(19, 19, 21, 0.4));

    @include --bzr-body-medium-155(16px);
    color: #fff;
  }

  &_no_internet {
    @extend .button_publication;
    background: rgba(232, 19, 19, 0.4);
  }
}
