@mixin form-styles($gap: 20px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
  width: 100%;
  margin-top: 20px;
}

@mixin form-header($fs: 32px, $ln: 41px) {
  font-size: $fs;
  font-style: normal;
  font-weight: 600;
  line-height: $ln;
  color: #0085c5;
  text-align: center;
}

@mixin form-button-action {
  border-radius: 8px;
  background-color: #56b947;
  color: #fff;
  padding: 12px 48px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
}

@mixin form-input {
  border-radius: 8px;
  border: 1px solid #adadad;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  width: 100%;
}

.auth_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 60;
  width: 439px;
  border-radius: 12px;
  background: #fff;
  padding: 24px;

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      transform: translateY(-10px);
      @include form-header;
    }

    .login_form {
      @include form-styles;

      .login_signin_section {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;

        .action_password {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          button {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
          }
        }
      }

      input {
        @include form-input;
      }

      input[type='password'] {
        width: 100%;
        padding-right: 55px;
      }

      .login_button_submit {
        @include form-button-action;
      }

      .login_options {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: 100%;
      }

      .registerr {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h6 {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;

          span {
            font-weight: 600;
          }
        }

        .register_button {
          @include form-button-action;
          background-color: #fff;
          border: 1px solid #56b947;
          color: #000;
        }
      }

      .agreement_text {
        padding: 0 20px;

        h6 {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0em;
          text-align: center;

          a {
            text-decoration: underline;
          }
        }
      }

      .login_other {
        display: flex;
        justify-content: center;
        gap: 16px;

        cursor: pointer;
      }
    }
  }

  .register {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      @include form-header;
    }

    .register_form {
      @include form-styles;

      .register_form_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .input {
        @include form-input;
      }

      .phone_input {
        border-radius: 8px;
        border: 1px solid #adadad;
        padding: 12px 20px;
        input {
          margin-left: 10px;
        }
      }

      .register_button {
        @include form-button-action;
      }

      &_sendAgain {
        margin-bottom: 5px;
        text-align: center;

        p {
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0em;
          text-align: center;
          text-decoration: underline;
          text-underline-offset: 2px;
          cursor: pointer;
        }
      }

      .agreement_text {
        padding: 0 20px;

        h6 {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0em;
          text-align: center;

          a {
            text-decoration: underline;
            text-underline-offset: 1px;
          }
        }
      }

      .forgot {
        text-align: end;
      }

      .login {
        font-size: 12px;
        text-align: center;
        font-weight: 600;
        line-height: 17px;
      }

      .login_other {
        display: flex;
        justify-content: center;
        gap: 16px;
        font-size: 30px;
        cursor: pointer;
      }
    }
  }

  .register_code {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 {
      @include form-header;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      margin-top: 16px;
      text-align: center;
    }

    .register_button {
      @include form-button-action;
    }
  }

  .forgot_password {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      @include form-header;
    }

    &_description {
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      line-height: 17px;
      margin-top: 16px;
    }

    .forgot_form {
      @include form-styles;

      input {
        @include form-input;
      }

      &_button {
        @include form-button-action;

        &_disabled {
          @include form-button-action;
          background-color: rgba(87, 186, 71, 0.8);
        }
      }
    }
  }

  .enter_code {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submit_btn {
      @include form-button-action;
    }

    h2 {
      @include form-header;
    }

    &_description{
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      margin: 16px 0 20px 0;
      text-align: center;

    }

    .container{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .reset_password {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      @include form-header;
    }

    .password_form {
      @include form-styles(16px);

      input {
        @include form-input;
      }

      .reset_button {
        @include form-button-action;
        margin-top: 4px;
      }
    }
  }

  .reset_confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      @include form-header;
    }

    .confirmation_form {
      @include form-styles;

      .confirmation_button {
        @include form-button-action;
      }
    }
  }

  .register_confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h2 {
      @include form-header;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
    }

    form {
      width: 100%;
    }
  }

  .register_completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h2 {
      @include form-header;
    }

    .completed_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      width: 100%;

      &_homepage {
        @include form-button-action;
        padding: 12px 13px;
        background-color: transparent;
        border: 1px solid #56b947;
        font-weight: 500;
        color: #252528;
      }

      &_account {
        @include form-button-action;
        padding: 12px 13px;
      }

      &_button {
        @include form-button-action;
      }
    }
  }

  .error {
    color: #ff0000;
    margin-top: 5px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    text-align: start;
  }

  .informational {
    margin-top: 5px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    text-align: start;
  }
}

@media screen and (max-width: 748px) {
  .auth_modal {
    width: 380px;

    .register_code {
      .code_inputs {
        gap: 10px;

        input {
          font-size: 14px;
          width: 45px;
          height: 42px;
        }
      }
    }

    .enter_code {
      .code_inputs {
        gap: 10px;

        input {
          font-size: 14px;
          width: 45px;
          height: 42px;
        }
      }
    }

    .register_completed {
      gap: 16px;

      h2 {
        @include form-header(24px, 31px);
      }

      .completed_container {
        flex-wrap: wrap;
        gap: 12px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .auth_modal {
    top: 45%;
    padding: 16px;

    .login {
      h2 {
        @include form-header(24px, 31px);
      }

      .login_form {
        @include form-styles(16px);

        .registerr {
          gap: 12px;
        }
      }
    }

    .register {
      h2 {
        @include form-header(24px, 31px);
      }

      .register_form {
        @include form-styles(10px);
      }
    }

    .forgot_password {
      h2 {
        @include form-header(24px, 31px);
      }

      .forgot_form {
        @include form-styles(16px);
      }
    }

    .reset_password {
      h2 {
        @include form-header(24px, 31px);
      }

      .password_form {
        @include form-header(24px, 31px);
      }
    }

    .reset_confirmation {
      h2 {
        @include form-header(24px, 31px);
      }

      .confirmation_form {
        margin-top: 16px;
      }
    }

    .register_confirmation {
      h2 {
        @include form-header(24px, 31px);
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .auth_modal {
    width: 335px;
  }
}
