@import '@/styles/variables/mixins/fonts/caption';

@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.field {
  @include flex-column($gap: var(--bzr-spacing-medium-24));
  width: 100%;

  h2 {
    @include font($size: 24px, $weight: 600);
  }
  
  &_inner {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      @include font($color: var(--bzr-deep-medium-grey));
      line-height: 23.2px;
      span {
        color: var(--bzr-secondary-error);
      }
    }

    .values {
      position: relative;
      display: flex;
      gap: var(--bzr-spacing-premedium);
      flex-wrap: wrap;
      width: 67%;

      .search_wrapper {
        position: relative;
        width: 100%;
        .search_input {
          width: 100%;
          height: 32px;
          padding: var(--bzr-spacing-xxs) var(--bzr-spacing-small);
          border: 1px solid var(--bzr-secondary-light-grey);
          border-radius: var(--bzr-radius-s);
          @include font($size: 14px);
          &:focus {
            border-color: transparent;
            outline: 3px solid var(--secondary-light-green, #D9FFCB);
          }
          &_error {
            border-color: var(--bzr-secondary-error);
          }
          &::placeholder {
            color: var(--bzr-medium-grey);
            font-weight: 400;
          }
        }
        .no_address_error {
          @include font($size: 12px, $color: var(--bzr-secondary-error));
        }
        svg {
          position: absolute;
          right: 10px;
          top: 4px;
          width: 24px;
          height: 24px;
          background-color: white;
          cursor: pointer;
        }
      }

      .list {
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 1000;
        @include flex-column($gap: 0);
        width: 100%;
        background-color: white;
        box-shadow: 0px 4px 12px 0px #09090926, 0px 0px 1px 0px #2020200D;
        border-radius: var(--bzr-radius-s);
        list-style-type: none;
        li {
          padding: 10px var(--bzr-spacing-small);
          p {
            cursor: pointer;
            span {
              @include font($size: 14px);
              b {
                font-weight: 600;
              }
            } 
          } 
        }
      }

      .error {
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 1000;
        width: 100%;
        background-color: white;
        box-shadow: 0px 4px 12px 0px #09090926, 0px 0px 1px 0px #2020200D;
        border-radius: var(--bzr-radius-s);
        padding: var(--bzr-spacing-medium) 0;
        @include font($size: 14px);
        p {
          padding: 10px var(--bzr-spacing-small);
          color: var(--bzr-text-medium-grey);
          text-align: center;
          a {
            color: var(--bzr-main-blue-default);
          }
        }
      }

      .map_container {
        width: 100%;
        height: 242px;
        border-radius: var(--bzr-radius-xs);
      }

      .map_info {
        width: 100%;
        padding: var(--bzr-spacing-premedium) var(--bzr-spacing-small);
        display: flex;
        gap: var(--bzr-spacing-xs);
        border-radius: var(--bzr-radius-s);
        background: var(--bzr-secondary-very-light-blue);

        svg {
          font-size: 24px;
          color: var(--bzr-main-blue-default);
          flex-shrink: 0;
        }
        p {
          @include --bzr-caption-medium-145();
          font-size: 13px;

          a {
            color: var(--bzr-main-blue-default);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'header header'
      'city district'
      'address address';
    column-gap: 0;
    row-gap: var(--bzr-spacing-medium);
    &_inner {
      flex-direction: column;
      gap: var(--bzr-spacing-small);
      width: 100%;
      .values {
        width: 100%;
      }
    }
    h2 {
      grid-area: header;
    }
    .city {
      grid-area: city;
    }
    .district {
      grid-area: district;
    }
    .address {
      grid-area: address;
    }
  }
}

@media (max-width: 480px) {
  .field {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-premedium);
    &_inner {
      justify-content: flex-start;

      h2 {
        @include font($size: 20px, $weight: 600);
        span {
          font-weight: 500;
        }
      }

      p {
        @include font($size: 16px, $weight: 600);
      }

      .values {
        width: 100%;
        .map_container {
          height: 304px;
        }
        .map_info p {
          font-size: 12px;
        }
      }
    }
  }
}
