@import '@/styles/variables/mixins/media-queries';

@mixin --bzr-font-style($fonSize, $lineHeight) {
  font-family: Montserrat;
  font-size: $fonSize;
  font-style: normal;
  font-weight: 500;
  line-height: $lineHeight;
}

.email_notification_wrapper {
  width: 100%;
  padding: var(--bzr-spacing-premedium) 0;
  background-color: var(--bzr-secondary-very-light-blue);
  box-shadow: 0 0 6px 0 rgba(116, 116, 116, 0.25);

  .email_notification {
    width: 100%;
    max-width: 1200px;
    display: flex;
    gap: var(--bzr-spacing-xs);
    margin:  0 auto;

    .svg {
      width: 32px;

      svg {
        font-size: 32px;
        color: var(--bzr-main-blue-default);
      }
    }

    .text {
      display: flex;

      p,
      span {
        align-self: center;
        @include --bzr-font-style(16px, 155%);
        color: var(--bzr-dark-black-primary);

        a {
          color: var(--bzr-main-blue-default);
        }
        button {
          padding: 0;
          font-size: 16px;
        }
      }
    }
  }

  .toast {
    padding: var(--bzr-spacing-small) var(--bzr-spacing-medium);
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .email_notification_wrapper {
    padding: var(--bzr-spacing-premedium) 120px;
  }
}

@include bzr-breakpoint('tablet') {
  .email_notification_wrapper {
    padding: var(--bzr-spacing-premedium) var(--bzr-spacing-medium);

    .email_notification {
      .svg {
        width: 24px;

        svg {
          font-size: 24px;
        }
      }

      .text {
        p,
        span {
          align-self: start;
          @include --bzr-font-style(12px, 145%);
          button {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .email_notification_wrapper {
    padding: var(--bzr-spacing-premedium) var(--bzr-spacing-xs);
  }
}