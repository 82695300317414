@import './font.scss';

@mixin --bzr-h1() {
  @include bzr-montserrat();
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
}

@mixin --bzr-h2() {
  @include bzr-montserrat();
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
}

@mixin --bzr-h3() {
  @include bzr-montserrat();
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

@mixin --bzr-h4() {
  @include bzr-montserrat();
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

@mixin --bzr-h5() {
  @include bzr-montserrat();
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
@mixin --bzr-h5-135() {
  @include bzr-montserrat();
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
}

// для storybook
.bzr-h1 { @include --bzr-h1(); }
.bzr-h2 { @include --bzr-h2(); }
.bzr-h3 { @include --bzr-h3(); }
.bzr-h4 { @include --bzr-h4(); }
.bzr-h5 { @include --bzr-h5(); }
.bzr-h5-135 { @include --bzr-h5-135(); }
