.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: var(--spacing-spacing_premedium, 16px);
  pointer-events: none;
}

.modal__box {
  pointer-events: auto;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  background: var(--bz-modal-background, #fff);
  padding: var(--modal-padding, 32px);
  border-radius: var(--modal-border-radius, 12px);
  box-shadow: -4px -4px 7px 0px rgba(31, 54, 92, 0.03), 4px 4px 7px 0px rgba(31, 54, 92, 0.03);
  line-height: normal;
}

.modal__box_mobile {
  padding: var(--bzr-spacing-medium);
}

.modal__box_clean {
  gap: 0;
  padding: 0;
  overflow: hidden;
}

.modal__close {
  pointer-events: auto;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  display: flex;

  opacity: 0;
  transition: opacity 0.3s ease;

  &.modal__close_visible {
    opacity: 1;
  }

  svg {
    font-size: 44px;
    color: rgba(255, 255, 255, 0.7);
  }
}

.modal__header {
  width: 100%;
  display: flex;
  gap: 16px;
  line-height: normal;
  justify-content: space-between;
  align-items: center;

  &_contentCenter {
    justify-content: center;
  }
  &_titleStart {
    .modal__title {
      order: 1;
      flex: 1 0;
    }
    .modal__action {
      display: none;
    }
  }
}

.modal__action {
  align-self: flex-start;
  min-width: 24px;
  svg {
    font-size: 24px;
  }
}

.modal__title {
  color: var(--text-dark-black-primery, #2C2D31);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.modal__footer {
  padding: 0 var(--bzr-spacing-small);
}

@media screen and (max-width: 480px) {
  .modal {
    width: var(--modal-min-width, calc(100% - 8px));
    .modal__box_mobile {
      padding: var(--bzr-spacing-medium);
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    min-width: 400px;
  }
}

@media screen and (min-width: 1025px) {
  .modal {
    min-width: 400px;
  }
}