@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/media-queries';

.user_contacts,
.user_ad_contacts {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-small);

  .phone,
  .chat,
  .telegram {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
}

@include bzr-breakpoint('tablet') {
  .user_contacts,
  .user_ad_contacts {
    .phone,
    .chat {
      @include --bzr-small-button-semibold();
      padding: 13.5px var(--bzr-spacing-premedium);
    }

    .telegram {
      @include --bzr-small-button-medium();
      padding: 13.5px var(--bzr-spacing-premedium);
    }
  }
}

@media (max-width: 768px) {
  .user_ad_contacts {
    width: 100%;
    padding: 12px;
    position: fixed;
    top: calc(100dvh - 128px);
    right: 0;
    z-index: 200;
    flex-direction: row-reverse;
    flex-shrink: 0;

    .phone,
    .chat {
      flex-shrink: 1;
    }
    .telegram {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .user_contacts {
    width: 100%;
    flex-direction: row;
    gap: 8px;

    .telegram,
    .chat,
    .phone {
      width: 100%;
      flex-shrink: 1;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
    }
  }
}
