@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.field_inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    max-width: 160px;
    @include font($color: var(--bzr-deep-medium-grey));
    line-height: 23.2px;
    span {
      color: var(--bzr-secondary-error);
    }
  }
  .values {
    position: relative;
    display: flex;
    gap: var(--bzr-spacing-xs);
    flex-wrap: wrap;
    width: 67%;

    .error {
      max-width: unset;
      width: 100%;
      padding: 0;
      @include font($size: 12px, $color: var(--bzr-secondary-error))
    }
  }
}

@media (max-width: 1024px) {
  .field {
    gap: var(--bzr-spacing-medium);
    width: 100%;
    &_inner {
      flex-direction: column;
      gap: var(--bzr-spacing-small);
      p,
      .values {
        width: 100%;
        max-width: unset;
      }
    }
  }
}

@media (max-width: 480px) {
  .field {
    gap: var(--bzr-spacing-premedium);
    &_inner {
      justify-content: flex-start;

      p {
        max-width: unset;
        @include font($size: 16px, $weight: 600);
      }

      .values {
        width: 100%;
      }
    }
  }
}
