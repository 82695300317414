.mobile_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 40px;
  background-color: #fff;
  border-bottom: 0.5px solid #e2e2e2;

  svg {
    color: #2C2D31 !important;
  }

  .left_section,
  .right_section {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .center_section {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .icon_button {
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
      font-size: 24px;
    }
  }

  .left_content,
  .right_content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    svg {
      font-size: 24px;
    }
  }
}

@media (max-width: 1024px) {
  .mobile_header {
    padding: 0 20px;
    height: 48px;
  }
}

@media (max-width: 480px) {
  .mobile_header {
    padding: 0 12px;
    height: 40px;
  }
}
