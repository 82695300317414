@mixin size-svg($size: 16px) {
  font-size: $size;
}

.state {
  width: 16px;
  height: 16px;

  &_read,
  &_unread {
    @include size-svg;
    color: #47c612;
  }

  &_sending {
    @include size-svg;
    color: #a7aaaf;
  }

  &_error {
    @include size-svg;
    color: #e81313;
    cursor: pointer;
  }
}
