.container {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_selected {
    position: absolute;
    left: 2px;
    top: 2px;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--bzr-main-bg, #fff);
  }

  .colorOption {
    height: 24px;
    width: 24px;
    border-radius: 100%;
  }
}
