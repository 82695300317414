.primary_event {
  width: 100%;
  display: flex;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #fff;

  .logo {
    width: 130px;
    height: 32px;

    svg {
      width: 130px;
      height: 32px;
    }
  }

  .section {
    display: flex;
    align-items: center;
    gap: 12px;

    .button_add {
      display: flex;
      height: 32px;
      gap: 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

