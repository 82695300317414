:root {
  // global colors

  --color-global-blue-100: #0085C5;
  --color-white: #FFFFFF;

  --color-primary: var(--color-global-blue-100);


  // цвет текста
  --bzr-text-dark-black-primary: #2C2D31;
  --bzr-dark-black-primary: #252528;
  --bzr-text-dark-black-secondary: #414245;
  --bzr-dark-black-muted :#45474B;
  --bzr-dark-gray-primary: #202126;
  --bzr-text-deep-medium-grey: #787B80;
  --bzr-deep-medium-grey: #7C7C7C;
  --bzr-text-medium-grey: #A7AAAF;
  --bzr-text-grey-medium: #B9BCC0;
  --bzr-placeholder-medium-grey: #BEBEBE;
  --bzr-medium-grey: #ABABAB;
  --bzr-text-white: #FFFFFF;
  --bzr-placeholder-medium-grey: #BEBEBE;

  // просто белый
  --bzr-main-bg: var(--color-white);

  // цвет main
  --bzr-main-blue-default: #0085C5;
  --bzr-main-blue-hover: #0093DB;
  --bzr-main-blue-pressed: #005F8D;

  --bzr-main-green-default: #47C612;
  --bzr-main-green-hover: #4DCC54;
  --bzr-main-green-pressed: #279616;

  // цвет secondary
  --bzr-secondary-grey-scroll: #DCDBDB;
  --bzr-secondary-light-grey: #E2E2E2;
  --bzr-secondary-light-green: #D9FFCB;
  --bzr-secondary-very-light-grey: #EFEFEF;
  --bzr-secondary-very-light-grey-2: #F5F5F5;
  --bzr-secondary-very-light-blue: #f6fbff;
  --bzr-secondary-white-grey: #FBFBFB;
  --bzr-secondary-light-blue-categories: #DAF3FF;
  --bzr-secondary-grey-tag: #F5F5F5;
  --bzr-secondary-error: #E81313;
  --bzr-secondary-very-light-green-tags: #eaffe3;

  // радиусы
  --bzr-radius-xxs: 4px;
  --bzr-radius-xs: 8px;
  --bzr-radius-s: 12px;
  --bzr-radius-m: 16px;
  --bzr-radius-100: 100px;

  // padding/margin/gap
  --bzr-spacing-xxs: 4px;
  --bzr-spacing-xs: 8px;
  --bzr-spacing-small: 12px;
  --bzr-spacing-premedium: 16px;
  --bzr-spacing-medium: 20px;
  --bzr-spacing-medium-24: 24px;
  --bzr-spacing-large: 32px;
  --bzr-spacing-large-44: 44px;
  --bzr-spacing-xl: 64px;
  --bzr-spacing-xxl: 80px;
}
