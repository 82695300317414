//@import "@/styles/variables/mixins/fonts/caption";
//@import "@/styles/variables/mixins/fonts/buttons";
@import '../../../../styles/variables/mixins/fonts/buttons.scss';
@import '../../../../styles/variables/mixins/fonts/caption.scss'; // абсолютный импорт не работает в сторибуке

.select {
  width: 210px;

  &.size_xs {
    .control {
      height: 32px;
    }
  }

  &.size_s {
    .control {
      height: 40px !important;
    }
  }

  [class$='-container']:focus {
    outline: none;
  }

  .control {
    display: flex;
    border-radius: var(--bzr-radius-s);
    border: none;
    background-color: var(--bzr-secondary-very-light-grey-2);
    cursor: pointer;

    &:focus-within {
      border-color: #d9ffcb;
      box-shadow: #d9ffcb 0 0 0 3px;
      background-color: var(--bzr-secondary-very-light-grey-2);
    }
    &:hover:not(:focus-within) {
      background-color: var(--bzr-secondary-light-grey);
    }

    &:not(:has([class$='-ValueContainer'])) > *:first-child,
    &:has([class$='-ValueContainer']) > [class$='-ValueContainer'] {
      padding-left: var(--bzr-spacing-small) !important;

      [class$='-singleValue'] {
        @include --bzr-small-button-medium();
      }

      [class$='-placeholder'] {
        color: #2c2d31;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    &:not(:has([class*='IndicatorsContainer'])) {
      [class*='indicatorContainer'] {
        padding-right: var(--bzr-spacing-small) !important;

        [class$='-arrow'], [class$='-close'], svg {
          color: var(--bzr-text-dark-black-primary) !important;
          width: 24px !important;
          height: 24px !important;
          font-size: 24px;
          transition: transform 0.3s ease !important;
          transform: rotate(0deg) !important;
          flex-shrink: 0;
        }
      }
    }

    &:not(:has([class$='-IndicatorsContainer'])) > [class$='-indicatorContainer'] {
      padding-right: var(--bzr-spacing-small) !important;

      [class$='-arrow'], [class$='-close'], svg {
        color: var(--bzr-text-dark-black-primary) !important;
        width: 24px !important;
        height: 24px !important;
        font-size: 24px;
        transition: transform 0.3s ease !important;
        transform: rotate(0deg) !important;
      }
    }
  }

  &.open {
    .control {
      [class$='-indicatorContainer'] {
        .arrow {
          transform: rotate(180deg) !important;
        }
      }
    }
  }

  .scrollbar {
    width: 100%;
    height: 100%;

    :global(.os-scrollbar-handle) {
      stroke: #c7c7c7;
      background: #c7c7c7;
      border-radius: 30px;
      opacity: 0.8;
    }

    // Ширина скролла
    :global(.os-scrollbar-vertical) {
      right: 0;
      top: 0;
      height: 100%;
      padding: 8px 3px 8px 2.5px;
      border-left: 0.5px solid #d0d0d0;
      background: #fbfbfb;
      border-radius: 0;
      width: 12px;
    }
  }

  .scrollable {
    max-height: 180px;
  }

  .customMenu {
    border-radius: var(--bzr-radius-s);
    margin: var(--bzr-spacing-xxs) 0;
    box-shadow:
      0 0 1px 0 rgba(32, 32, 32, 0.05),
      0 4px 12px 0 rgba(9, 9, 9, 0.15);
    border: none;
    width: 100%;
    height: auto;
    max-height: 180px;
    overflow-y: hidden;

    .option {
      min-height: 36px;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border-radius: 0;
      cursor: pointer;
      @include --bzr-caption-medium-fontsize(14px);

      &:hover {
        background-color: var(--bzr-secondary-very-light-grey-2);
      }

      .check {
        font-size: 24px;
        color: #47c612;
      }
      // задаем бэкграунд опции (элементу), которая содержит SVG с классом .check
      &:has(.check) {
        background-color: var(--bzr-secondary-very-light-grey-2);
      }
    }
    [aria-selected='true'] {
      background-color: var(--bzr-secondary-very-light-grey-2);
    }

    &:nth-child(1) {
      border-top-left-radius: var(--bzr-radius-s);
    }
    &:nth-last-child(1) {
      border-bottom-left-radius: var(--bzr-radius-s);
    }

    [class$='-NoOptionsMessage'] {
      height: 84px;
      background-color: var(--bzr-main-bg);
      color: var(--bzr-text-medium-grey);
      display: flex;
      justify-content: center;
      align-items: center;
      @include --bzr-caption-medium-fontsize(14px);
    }
  }
}
