@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/caption';

.personal_account {
  display: flex;
  flex-direction: column;
  gap: var( --bzr-spacing-medium);

  .user_info {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 0 auto;

    .user_name {
      margin: var(--bzr-spacing-small) 0 var(--bzr-spacing-premedium) 0;

      .name {
        display: flex;
        align-items: center;
        @include --bzr-h4();
        color: var(--bzr-text-dark-black-primary);
        position: relative;

        span {
          flex: 1 1;
          text-align: center;
        }

        a {
          position: absolute;
          right: -32px;
          height: 24px;
          .pencil {
            font-size: 24px;
            fill: black;
          }
        }
      }

      .seller_type {
        @include --bzr-caption-medium-fontsize(12px);
        color: var(--bzr-text-deep-medium-grey);
        margin-top: var(--bzr-spacing-xxs);
        text-align: center;
      }
    }

    .rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--bzr-spacing-xxs);

      .rating_number {
        display: flex;
        gap: var(--bzr-spacing-xs);

        & > p {
          @include --bzr-h5();
        }

        p + div { // чтобы не лезть в компонент UserRating и там не менять стили
          gap: var(--bzr-spacing-xxs);

          svg {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }

      .reviews {
        @include --bzr-m-button-medium();
        color: var(--bzr-main-blue-default);
        display: block;
        text-align: center;
        padding: var(--bzr-spacing-xxs);
      }
    }
  }

  .links_block {
    width: max-content;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    position: relative;

    .link {
      width: 351px;
      padding: var(--bzr-radius-xs) var(--bzr-spacing-small);
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--bzr-secondary-very-light-grey-2);
      border-radius: var(--bzr-spacing-small);
      border: none;

      .text_wrapper {
        p {
          @include --bzr-small-button-semibold();
          color: var(--bzr-text-dark-black-primary);
          margin-bottom: 2px;
        }

        span {
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(--bzr-text-deep-medium-grey);
        }
        .secondary_active {
          color: var(--bzr-main-blue-default);
          font-weight: 500;
        }
      }

      svg {
        font-size: 24px;
        fill: var(--bzr-text-grey-medium);
      }
    }

    .logout {
      @include --bzr-small-button-medium();
      padding: 13.5px 0;
      margin-bottom: 72px;
    }

    .background {
      width: 100vw;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
      background-color: rgba(19, 19, 21, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;

      .modal {
        width: 327px;
        background-color: var(--bzr-main-bg);
        border-radius: var(--bzr-radius-s);
        border: none;
        padding: var(--bzr-spacing-medium);

        p {
          margin-bottom: var(--bzr-spacing-premedium);
        }

        .buttons_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: var(--bzr-spacing-);

          .exit_button {
            width: calc(50% - 4px);
            height: 32px;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 13.5px 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--bzr-radius-s);
            background: var(--bzr-secondary-very-light-grey);
          }
        }
      }
    }
  }
}
