@mixin base-text-styles($size: 18px, $weight: 600) {
  color: #2c2d31;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: normal;
}

.container {
  display: flex;
  min-width: 351px;
  width: 100%;
  height: 88px;
  padding: 16px 40px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #eaffe3;

  svg {
    font-size: 44px;
    color: #2c2d31;
  }

  &:hover {
    cursor: pointer;
    background: #d9ffcb;
  }

  .info {
    display: flex;
    height: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;

    &_title {
      @include base-text-styles;
    }

    &_description {
      @include base-text-styles($size: 14px, $weight: 500);
    }

    &_message {
      @include base-text-styles($size: 14px);
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
  }
}

.ellipse {
  width: 14px;
  height: 14px;
  background-color: #47c612;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .container {
    padding: 16px;
    gap: 16px;

    .info {
      &_title {
        @include base-text-styles($size: 16px);
      }
    }
  }

  .ellipse {
    width: 12px;
    height: 12px;
  }
}
