.confirm_ad {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: Montserrat;

  .text {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      text-align: left;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.9px;
      text-align: left;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 12px;

    button {
      flex-shrink: 1;
    }
  }
}

@media (max-width: 480px) {
  .modal_confirm {
    width: 327px;
    padding: 20px;

    .confirm_ad {
      width: 100%;


      .buttons {
        button {
          height: 32px;
        }
      }
    }
  }
}
