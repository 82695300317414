@mixin bzr-primary-black-button() {
  background-color: var(--bzr-text-dark-black-primary);
  color: var(--bzr-text-white);
  transition:
    background-color 0.15s,
    color 0.15s;

  &:hover:not(:disabled) {
    background-color: var(--bzr-dark-gray-primary);
  }
  &:active:not(:disabled) {
    background-color: var(--bzr-text-dark-black-primary);
  }
  &:disabled {
    background-color: var(--bzr-dark-black-muted);
    color: var(--bzr-text-medium-grey);
  }
}
