@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/buttons';

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  gap: 12px;
  width: 100%;
  grid-area: category_block;

  .row {
    display: flex;
    gap: 12px;
    width: max-content;
    height: 102px;
    padding: 0;
  }
}

.companies {
  height: 102px;
  width: 241px;
  padding: var(--bzr-spacing-premedium);
  border-radius: var(--bzr-radius-s);
  background: var(--bzr-main-green-default);
  @include --bzr-m-button-semibold();
  color: white;
  position: relative;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background: #daf3ff;
  }

  .svg {
    width: 75px;
    height: 73px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@include bzr-breakpoint('tablet') {
  .wrapper {
    overflow-x: unset;
  }
  .wrapper .row,
  .companies {
    height: 109.5px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    gap: var(--bzr-spacing-xs);
    .row {
      height: 111.5px;
      gap: var(--bzr-spacing-xs);
    }
  }

  .companies {
    height: 111.5px;
    width: 170px;
    font-size: 14px;

    .svg {
      width: 90px;
      height: 90px;
      right: -18px;
      bottom: -13px;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .wrapper {
    gap: 8px;

    .row {
      gap: 8px;
      height: auto;
    }
  }

  .companies {
    height: 81.5px;
    width: 155px;
    padding: var(--bzr-spacing-small);
    font-size: 12px;

    .svg {
      width: 70px;
      height: 70px;
      right: -16px;
      bottom: -15px;
    }
  }
}
