@import '@/styles/variables/mixins/media-queries';

.block_menu_paths {
  margin-bottom: var(--bzr-spacing-premedium);

  .menu_paths_route {
    display: flex;
    flex-direction: row;
    gap: 12px;
    list-style-type: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;

    li, li a {
      color: #adadad;
    }

    .menu_paths_selected {
      color: #252528;
    }
  }

  .menu_paths_route li:last-child {
    color: #252528;
  }
}

@include bzr-breakpoint('tablet') {
  .block_menu_paths {
    margin-bottom: var(--bzr-spacing-xs);
  }
}