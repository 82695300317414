.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.images {
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 1.635;
  position: relative;
}

.images__counter {
  display: none;
  border-radius: 16px;
  background: rgba(235, 235, 235, 0.9);
  padding: 8px;
  right: 12px;
  top: 12px;
  position: absolute;
  z-index: 50;
  font-weight: 500;
  color: var(--bzr-text-dark-black-secondary);
  font-size: 11px;
}

.images__backBlur {
  filter: blur(40px);
  position: absolute;
  inset: 0;
  opacity: 0.2;
  z-index: 3;
}

.images__item {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 5;
}

.prev, .next {
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 51px;
  box-shadow: 0px 0px 3.1px 0px rgba(127, 127, 127, 0.25);
  background: rgba(255, 255, 255, 0.60);
  color: #2C2D31;
}

.wrapper_hideArrow {
  .prev, .next {
    display: none;
  }
}

.prev {
  left: 12px;
}

.next {
  right: 12px;
}

.prev:hover, .next:hover {
  background: rgba(255, 255, 255, 0.9);
}

.footer {
  display: flex;
  gap: 8px;
}

.footer__navAction {
  width: 32px;
  border-radius: 12px;
  background: var(--bzr-secondary-very-light-grey-2);
  flex-shrink: 0;
  font-size: 32px;
  color: var(--bzr-text-medium-grey);
}

.wrapper_hideNavigateArrow .footer__navAction {
  display: none;
}

.navigate {
  display: flex;
  gap: 8px;
  overflow: auto;
  width: 100%;
}

.navigate__item {
  background-size: cover;
  width: 125px;
  height: 83px;
  flex-shrink: 0;
  border: none;
  border-radius: 8px;
  overflow: hidden;
}

.navigate__item_active {
  border: 3px solid #47C612;
}

@media (max-width: 480px) {
  .prev,
  .footer,
  .next {
    display: none;
  }

  .images {
    height: 220px;
  }

  .images__counter {
    display: block;
  }
}