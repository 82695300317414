.tags {
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
  gap: 8px 8px;
  cursor: pointer;
}

.active_tag {
  color: #fff;
  cursor: pointer;
}

@media (max-width: 480px) {
  .tags {
    flex-wrap: nowrap;
    overflow: scroll;
  }
}
