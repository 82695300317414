.select {
  width: 190px;
}

.button_select {
  padding: 8px 16px !important;

  label {
    color: #2c2d31;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    font-size: 16px;
    color: #2c2d31;
    flex-shrink: 0;
  }
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-small);

  .options {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);

    label {
      display: flex;
      align-items: center;
      gap: var(--bzr-spacing-small);
      height: var(--bzr-spacing-large);

      color: #2c2d31;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &_button {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

@media (max-width: 390px) {
  .button_select {
    label {
      width: 95.5px;
    }
  }
}
