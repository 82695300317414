.wrapper {

}

.items {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--bzr-spacing-xs);
  column-gap: var(--bzr-spacing-xxs);
}

.item {
  height: var(--bzr-spacing-large);
  padding: 8px 12px;
  border-radius: 16px;
  border: 0.5px solid var(--bzr-secondary-light-grey);
  background: var(--bzr-text-white);
  display: flex;
  justify-content: center;
  align-items: center;

  &_active {
    background: var(--bzr-secondary-very-light-grey);
  }
}