@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/media-queries';

.profile_bazaar {
  @include --bzr-small-button-medium;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.button {
  flex: 1 1 calc(50% - 8px);
  @include --bzr-small-button-medium;
}

.button_white {
  color: #fff;
}

.button_dark {
  color: #2c2d31;
}

.button_share {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

@include bzr-breakpoint('tablet') {
  .profile_bazaar {
    @include --bzr-small-button-semibold;
    max-width: 500px;
  }

  .container {
    gap: 8px;
    max-width: 500px;
    width: 100%;
  }

  .button {
    flex: 1 1 100%;
    @include --bzr-small-button-semibold;
  }
}
