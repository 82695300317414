.loader {
  width: 70px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #47c612 94%, #ffff) top/3px 3px
      no-repeat,
    conic-gradient(#ffff 5%, #47c612);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: l13 0.8s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
