.category_selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-premedium);

  .title {
    color: var(--bzr-text-dark-black-primary);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
