.contentModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100vh;

  h3 {
    color: var(--bzr-text-dark-black-primary, #2c2d31);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

@media (max-width: 480px) {
  .mobile {
    &_container {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 12px;
    }

    &_button {
      width: 100%;
    }
  }
}
