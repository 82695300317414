@mixin bzr-primary-light-blue-button() {
  background-color: var(--bzr-secondary-light-blue-categories);
  color: var(--bzr-main-blue-default);
  transition: background-color 0.15s, color 0.15s;

  &:hover:not(:disabled) {
    background-color: #B6E0F4;
  }
  &:active:not(:disabled) {
    background-color: var(--bzr-secondary-light-blue-categories);
  }
  &:disabled {
    background-color: var(--bzr-secondary-very-light-grey);
    color: var(--bzr-text-medium-grey);
  }
}
