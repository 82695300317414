@import '@/styles/variables/mixins/primary-buttons/base-primary';
@import '@/styles/variables/mixins/primary-buttons/primary-blue';
@import '@/styles/variables/mixins/primary-buttons/primary-grey';
@import '@/styles/variables/mixins/primary-buttons/primary-green';
@import '@/styles/variables/mixins/primary-buttons/primary-black';
@import '@/styles/variables/mixins/primary-buttons/primary-light-blue';
@import '@/styles/variables/mixins/secondary-buttons/base-secondary';
@import '@/styles/variables/mixins/secondary-buttons/secondary-black';
@import '@/styles/variables/mixins/secondary-buttons/secondary-blue';
@import '@/styles/variables/mixins/secondary-buttons/secondary-green';
@import '@/styles/variables/mixins/secondary-buttons/secondary-white';

@mixin button-size($size) {
  @if $size == xs {
    padding: 0.25px var(--bzr-spacing-premedium);
  } @else if $size == s {
    padding: 13.75px var(--bzr-spacing-premedium);
  } @else if $size == m {
    padding: 17.75px var(--bzr-spacing-premedium);
  }
}

.fullWidth {
  width: 100%;
}

.primary {
  @include bzr-base-primary-button();

  &.blue {
    @include bzr-primary-blue-button();
  }
  &.green {
    @include bzr-primary-green-button();
  }
  &.grey {
    @include bzr-primary-grey-button();
  }
  &.black{
    @include bzr-primary-black-button();
  }
  &.light_blue {
    @include bzr-primary-light-blue-button();
  }

  &.size_xs {
    @include button-size(xs);
  }
  &.size_s {
    @include button-size(s);
  }
  &.size_m {
    @include button-size(m);
  }
}

.secondary {
  @include bzr-base-secondary-button();

  &.black {
    @include bzr-secondary-black-button();
  }
  &.blue {
    @include bzr-secondary-blue-button();
  }
  &.green {
    @include bzr-secondary-green-button();
  }
  &.white {
    @include bzr-secondary-white-button();
  }
}