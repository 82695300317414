@import "@/styles/variables/mixins/fonts/headlines.scss";

@mixin font($size: 12px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.info_block {
  @include flex-column();
  width: 100%;
  font-family: Montserrat;
  grid-area: product_info;
  margin-bottom: var(--bzr-spacing-large-44);
  
  .block {
    width: 100%;
    @include flex-column($gap: var(--bzr-spacing-premedium));
    h2 {
      @include --bzr-h2();
      color: var(--bzr-text-dark-black-primary);
    }

    span {
      display: flex;
      align-items: center;
      gap: 6px;
      @include font($size: 16px, $color: var(--bzr-text-dark-black-secondary));
      svg {
        width: 24px;
        height: 24px;
        color: var(--bzr-text-medium-grey);
      }
    }

    p {
      @include font($size: 16px, $color: var(--bzr-text-dark-black-secondary));
      line-height: 23.2px;
      width: 100%;
      word-wrap: break-word;
    }
  }

  .location {
    width: 100%;
    .map_location {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--bzr-spacing-premedium);
      width: 100%;
      .show_map_button {
        font-size: 16px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .map_container {
        width: 100%;
        height: 300px;
        border-radius: var(--bzr-radius-xs);
      }
    }
  }

  .characteristics {
    width: 100%;
    .columns {
      width: 100%;
      column-width: auto;
	    column-count: 2;
	    column-gap: 30px;
      p {
        margin-bottom: var(--bzr-spacing-xs);
        @include font($size: 16px, $color: var(--bzr-text-dark-black-secondary), $weight: 600);
        break-inside: avoid;
        b {
          color: var(--bzr-main-blue-default);
        }
      }
    }
  }

  .additional {
    div {
      @include flex-column($gap: var(--bzr-spacing-xs));
      span {
        display: flex;
        align-items: center;
        gap: 6px;
        @include font($size: 16px, $color: var(--bzr-text-dark-black-secondary));
        svg {
          width: 24px;
          height: 24px;
          color: var(--bzr-main-green-default);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .info_block {
    width: 464px; // удалить

    .block {
      h2 {
        @include --bzr-h4();
      }
      span {
        @include font($size: 14px, $color: var(--bzr-text-dark-black-secondary));
      }
      p {
        @include font($size: 14px, $color: var(--bzr-text-dark-black-secondary));
        line-height: 20.3px;
      }
    }

    .characteristics {
      .columns {
        column-gap: var(--bzr-spacing-large);
        p {
          font-size: 14px;
        }
      }
    }

    .additional {
      div span {
        font-size: 14px;
      }
    }

    br {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .info_block {
    gap: var(--bzr-spacing-medium-24);
  }
}

@media (max-width: 480px) {
  .info_block {
    width: 95vw; // удалить

    .block {
      h2 {
        @include --bzr-h5();
      }
      p {
        @include font($size: 14px, $color: var(--bzr-text-dark-black-primary));
        line-height: 18.9px;
      }
      .show_button {
        padding: 0;
        @include font($size: 12px, $weight: 600, $color: var(--bzr-main-green-default));
      }
    }

    .description {
      gap: var(--bzr-spacing-xxs);
      h2 {
        margin-bottom: var(--bzr-spacing-small);
      }
      .trimmed {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .characteristics {
      gap: var(--bzr-spacing-xxs);
      h2 {
        margin-bottom: var(--bzr-spacing-small);
      }
      .columns {
        @include flex-column($gap: var(--bzr-spacing-xxs));
        column-width: unset;
	      column-count: unset;
	      column-gap: unset;
        p {
          @include font($size: 14px, $color: var(--bzr-text-dark-black-primary));
          line-height: 18.9px;
          margin-bottom: 0;
          b {
            font-weight: 500;
            color: var(--bzr-text-deep-medium-grey);
          }
        }
      }
    }

    .additional div span {
      @include font($size: 14px, $color: var(--bzr-text-dark-black-primary));
      line-height: 18.9px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
