.button {
  border-radius: 8px;
  background-color: #56b947;
  color: #fff;
  padding: 12px 48px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  cursor: pointer;

  &:disabled {
    background-color: rgba(87, 186, 71, 0.8);
    cursor: not-allowed;
  }
}