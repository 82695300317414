.dot,
.active {
  position: relative;
  width: 6px;
  height: 6px;
  background: #ebebeb59;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s;
}

.active {
  width: 48px;
  height: 6px;
}

.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #fff;
  border-radius: 100px;
  animation: expand 4s forwards;
}

.ad_card_dot {
  width: 4px;
  height: 4px;
  background: #ebebeb66;
  &_active {
    background: var(--bzr-main-blue-default);
    &::after {
      animation: none;
    }
  }
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .dot,
  .active {
    width: 4px;
    height: 4px;
  }
  .active {
    width: 40px;
    height: 4px;
  }
  .ad_card_dot {
    width: 4px;
  }
}
