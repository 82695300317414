@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/caption';

.secondary_event_wrapper {
  width: 100vw;
  height: 151.5px;
  background-color: var(--bzr-main-bg);

  .secondary_event {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 0 auto;
    padding: 24px 0 32px;

    .button_returnHome {
      @include --bzr-caption-medium-fontsize(14px);
      gap: 4px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .svgIconHeader {
    width: 24px !important;
    height: 24px !important;
    color: #2c2d31 !important;
    padding: 0 !important;
    font-size: 24px;
  }

  .section {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    .logo {
      width: 177px;
      height: 44px;

      svg {
        width: 177px;
        height: 44px;
      }

      flex-shrink: 0;
    }

    .button_add {
      @include --bzr-caption-medium-fontsize(16px);
      display: flex;
      height: 44px;
      color: #fff;
      white-space: nowrap;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;

      .search {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .secondary_event_wrapper .secondary_event {
    padding: 24px 120px 32px;
  }
}

@include bzr-breakpoint('tablet') {
  .secondary_event_wrapper {
    height: 79.5px;

    .secondary_event {
      padding: 24px 20px 8px;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .secondary_event_wrapper {
    height: 60px;
    padding: 8px 12px 12px;

    .secondary_event {
      display: unset;
      padding: 0;
      max-width: unset;

      .section {
        gap: 12px;

        .wrapper {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          width: 56px;

          .search {
            width: 24px;

            svg {
              width: 24px !important;
              height: 24px !important;
            }

            svg path {
              fill: #2c2d31 !important;
            }
          }
        }
      }
    }
  }
}
