@mixin flex-center {
  display: flex;
  align-items: center;
}

.primary_header {
  width: 100%;
  height: 52px;
  padding: 8px 20px;
  background: #47c612;
  @include flex-center;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.desktop_header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  &_links {
    @include flex-center;
    gap: 20px;
    color: #fff;

    .desktop_header_lang {
      @include flex-center;
      justify-content: center;
      gap: 4px;
      width: 42px;
      height: 32px;
      position: relative;

      button {
        color: #fff;
        @include flex-center;
        justify-content: center;
      }

      svg {
        font-size: 24px;
      }
    }
  }

  &_bar {
    @include flex-center;
    gap: 24px;

    &_actions {
      @include flex-center;
      gap: 20px;

      button,
      a {
        color: #fff;
        @include flex-center;
        gap: 4px;

        svg {
          width: 32px;
          height: 32px;
        }

        &:hover,
        svg:hover {
          color: #0093db;
        }
        &:active,
        svg:active {
          color: #005f8d;
        }
      }

      &_chat,
      &_favourite {
        position: relative;
        top: 0;
        left: 0;

        &_count {
          position: absolute;
          top: 0;
          left: 50%;
        }
      }
    }

    &_ad {
      @include flex-center;
      justify-content: center;
      height: 32px;
      padding: 0px 16px;
      gap: 4px;
      border-radius: 12px;
      background: #0085c5;
      color: #fff;

      &:hover {
        background: #0093db;
      }
      &:active {
        background: #005f8d;
      }
    }
  }
}

.mobile_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .mobile_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;

    &_logo {
      @include flex-center;

      svg {
        width: 77px;
        height: 24px;
      }
    }

    &_tools {
      @include flex-center;
      gap: 12px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .primary_header {
    height: auto;
    padding: 0;
  }
  .mobile_wrapper .mobile_header {
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-medium) 0;
    background: #fff;
  }
}

@media screen and (max-width: 480px) {
  .mobile_wrapper .mobile_header {
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-small) 0;
  }
}
