.tooltip {
  background-color: white !important;
  padding: var(--bzr-spacing-small) !important;
  box-shadow:
    0 0 1px 0 rgba(32, 32, 32, 0.05),
    0 4px 12px 0 rgba(9, 9, 9, 0.15);
  border-radius: var(--bzr-spacing-small) !important;
  color: var(--text-dark-black-primery) !important;
  font-family: Montserrat !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.card {
  width: 250px !important;
  color: #787b80 !important;
  font-size: 12px !important;
  font-style: normal !important;
  line-height: 145% !important;
}

.gray {
  max-width: 205px !important;
  width: fit-content !important;
  color: #787b80 !important;
  font-size: 12px !important;
  line-height: 145% !important;
}

.arrow {
  &_start {
    left: 6px !important;    
  }
  &_end {
    right: 6px !important;    
  }
  &_top {
    top: 6px !important;    
  }
  &_bottom {
    bottom: 6px !important;    
  }
}

.arrow::before {
  background-color: white !important;
}
