.colorPicker {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.white {
  border: 0.5px solid var(--bzr-secondary-light-grey, #e2e2e2);
}

.silver {
  border: 0.5px solid var(--bzr-secondary-light-grey, #e2e2e2);
  background: linear-gradient(180deg, #fff 0%, #999 100%);
}

.golden {
  border: 0.5px solid var(--bzr-secondary-light-grey, #e2e2e2);
  background: linear-gradient(180deg, #fffa8a 0%, #ecc440 100%);
}


@media (max-width: 480px) {
  .colorPicker {
    width: 100%;
  }
}