@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($gap: var(--bzr-spacing-premedium)) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.answer {
  @include flex-column();
  margin-left: var(--bzr-spacing-premedium);
  margin-top: var(--bzr-spacing-small);
  padding-left: var(--bzr-spacing-premedium);
  border-left: 3px solid var(--bzr-secondary-light-grey);
  &_recipient {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);
    p {
      @include font($weight: 600);
    }
  }
  .comment {
    @include font($size: 14px);
    line-height: 20px;
    word-break: break-word;
    white-space: pre-line;
  }
  .date {
    @include font($size: 14px, $color: var(--bzr-text-medium-grey));
  }
}

@media (max-width: 480px) {
  .answer {
    &_recipient {
      p {
        @include flex-column($gap: var(--bzr-spacing-xxs));
        @include font($size: 14px, $weight: 600);
        .date {
          @include font($size: 11px, $color: var(--bzr-text-medium-grey));
        }
      }
    }
  
    .comment {
      @include font($size: 12px);
      line-height: 17.4px;
    }
  }
}
