.container {
  width: 100%;
  border: 1px solid #ADADAD;
  border-radius: 0.5rem;

  input {
    padding: 12px 20px !important;
  }

  button[aria-label="Open"],
  button[aria-label="Close"] {
    margin-right: 13.5px;
    svg[data-testid="ArrowDropDownIcon"] {
      display: none; // дефолтная стрелка
    }
  }

  button[aria-label="Open"] {
   content: url('/assets/icons/arrow-down.svg');
   transition: transform 0.3s ease;
  }
  button[aria-label="Close"] {
    content: url('/assets/icons/arrow-down.svg');
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
}

