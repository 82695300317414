.container {
  display: flex;
  width: 378px;
  height: 341px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--bzr-spacing-large, 32px);
}

.optionsWrapper {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;

  .optionRow {
    cursor: pointer;
    display: flex;
    height: 44px;
    align-items: center;
    gap: var(--bzr-spacing-xs, 8px);
    align-self: stretch;

    color: var(--bzr-text-dark-black-primary, #2c2d31);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.nextButton {
  padding: 12px 16px !important;
  color: var(--bzr-text-dark-black-primary, #2c2d31);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .container {
    width: 346px;
    height: 332px;
    gap: var(--bzr-spacing-medium-24, 24px);
  }

  .nextButton {
    padding: 15px 20px !important;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
    justify-content: space-between;
  }
}
