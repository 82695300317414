@mixin baseStylesButton($width:198px, $height: 44px, $gap: 8px, $bcColor: #0085c5) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $width;
  height: $height;
  padding: 12px 20px;
  gap: $gap;
  flex-shrink: 0;
  border-radius: 12px;
  background: $bcColor;
  transition: background 0.3s;

  // text
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.category_button {
  @include baseStylesButton;

  &_open {
    @include baseStylesButton;
    outline: 3px solid #daf3ff;
  }

  &:hover {
    background: #0093db;
  }
}

.lineContainer {
  display: flex;
  justify-content: center;
  width: 18px;
  height: 14px;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;

  .line {
    width: 100%;
    height: 1.5px;
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1.5px;
      background-color: #fff;
      border-radius: 15px;
      transition: all 0.3s ease;
      left: 0;
    }

    &::before {
      top: 45%;
    }

    &::after {
      top: 90%;
    }
  }

  &_active .line {
    background-color: transparent;

    &::before {
      top: 45%;
      transform: rotate(45deg);
    }

    &::after {
      top: 45%;
      transform: rotate(-45deg);
    }
  }
}


@media (max-width: 1024px) {
  .category_button {
    @include baseStylesButton(40px, 40px, 10px, #47C612);
    padding: 10px;

    &_open {
      @include baseStylesButton(40px, 40px, 10px, #279616);
      outline: none;
      padding: 10px;
    }

    &:hover {
      background: #279616;
    }
  }

  .hideText {
    display: none;
  }

  .lineContainer {
    &_active .line {
      background-color: #fff;

      &::before {
        top: 45%;
        transform: rotate(0deg);
      }

      &::after {
        top: 90%;
        transform: rotate(0deg);
      }
    }
  }
}