@mixin bzr-secondary-green-button() {
  color: var(--bzr-main-green-default);
  transition: background-color 0.15s, color 0.15s;

  &:hover:not(:disabled) {
    color: var(--bzr-main-green-hover);
  }
  &:active:not(:disabled) {
    color: var(--bzr-main-green-pressed);
  }
}
