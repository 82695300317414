.ad_title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  color: var(--bzr-text-dark-black-primary);

  .icon {
    width: 10px;
    height: 16px;
    color: var(--bzr-text-dark-black-primary);
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}

@media (max-width: 1024px) {
  .ad_title {
    font-size: 12px;
    line-height: 14.63px;
    gap: 2px;

    span {
      gap: 2px;
    }

    &__one_chat {
      font-size: 11px;
      line-height: 13.41px;
    }
    .icon__one_chat {
      width: 7px;
      height: 13px;
    }
  }
}
