.mobile_card {
  width: 100%;
  display: flex;
  gap: 12px;

  .image {
    width: 152px;
    aspect-ratio: 1.58;
    overflow: hidden;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;

    img {
      width: 100%;
      height: 100%;
      border: 0.5px solid #e2e2e2;
      object-fit: cover;
      border-radius: 12px;
      cursor: pointer;
    }

    svg {
      width: 32px;
      height: 45px;
    }
  }

  .card_content {
    flex-grow: 1;
    display: flex;
    gap: 8px;

    .ad_info {
      flex-grow: 1;
      display: flex;
      gap: 8px;
      flex-direction: column;

      .title {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-family: Montserrat;

        p {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 18px;
          font-weight: 600;
          line-height: 21.94px;
          text-align: left;

          svg {
            font-size: 24px;
          }
        }

        h4 {
          font-size: 14px;
          font-weight: 600;
          line-height: 17.07px;
          text-align: left;
          cursor: pointer;

          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .location {
        display: flex;
        align-items: center;
        gap: 4px;

        p {
          color: #787b80;
          font-size: 14px;
          font-weight: 500;
        }

        svg {
          width: 24px;
          height: 24px;
          color: #787b80;
        }
      }
    }

    .actions {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: var(--bzr-spacing-xxs);

      svg {
        font-size: 24px;
      }

      .hide {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      .main_edit svg {
        color: var(--bzr-main-blue-default);
      }

      .open {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      .drafts_buttons {
        width: 100%;
        display: flex;
        align-items: flex-start;
        align-items: center;
        gap: 12px;

        .edit {
          cursor: pointer;
          width: 24px;
          height: 24px;
          flex-shrink: 1;
        }

        .delete {
          cursor: pointer;
          width: 24px;
          height: 24px;
          flex-shrink: 1;
        }
      }
    }
  }
}


@media (max-width: 480px) {
  .mobile_card {
    .image {
      width: 112px;
      flex-shrink: 0;

      svg {
        width: 23px;
        height: 32px;
      }
    }

    .card_content {
      .ad_info {
        .title {
          p {
            font-size: 16px;
            line-height: 19.5px;

            svg {
              font-size: 16px;
            }
          }
          h4 {
            width: 199px;
            font-size: 12px;
            line-height: 16.2px;
          }
        }

        .location {
          p {
            font-size: 12px;
            line-height: 125%;
          }

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }

      .actions {
        .drafts_buttons {
          position: relative;

          .menu {
            cursor: pointer;
            width: 24px;
            height: 24px;
            svg {
              font-size: 24px;
            }
          }

          .action_modal {
            width: 183px;
            position: absolute;
            top: 28px;
            right: 0;
            z-index: 1;
            padding: 10px 12px;
            background: #fff;
            border-radius: 12px;
            box-shadow:
              0px 0px 1px 0px rgba(32, 32, 32, 0.05),
              0px 4px 12px 0px rgba(9, 9, 9, 0.15);
              display: flex;
              flex-direction: column;

            .edit_button {
              display: flex;
              align-items: center;
              justify-content: space-between;

              svg {
                width: 16px;
                height: 16px;
              }
            }

            .delete_button {
              display: flex;
              align-items: center;
              justify-content: space-between;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
