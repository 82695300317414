.confirm_deletion {
  max-width: 378px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-medium-24);
  font-family: Montserrat;

  .title {
    color: #2c2d31;
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 145%;
    color: var(--bzr-text-dark-black-secondary);
    white-space: wrap;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: var(--bzr-spacing-small);
    align-self: stretch;

    button {
      flex-shrink: 1;
    }
  }
}

.confirm_modal {
  width: 502px;
}
