@import '@/styles/variables/mixins/media-queries';

.accordion {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;
  border-radius: 16px;
  border: 0.5px solid #e2e2e2;
  width: 100%;

  &_button {
    width: 32px;
    height: 32px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
    border-radius: 100px;
    flex-shrink: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #2c2d31;
      transition: transform 0.3s;
    }

    &::before {
      width: 20px;
      height: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
    }

    &::after {
      width: 2px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
    }

    &_open::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover {
      background: #efefef;
    }

    &:active {
      background: #e2e2e2;
    }
  }
}

.question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  &_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    color: #2c2d31;
  }
}

.answer {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  text-align: left;
  color: #414245;

  a {
    text-decoration: underline;
  }
}

@include bzr-breakpoint('mobile') {
  .accordion {
    padding: 16px;
    gap: 20px;

    &_button {
      width: 24px;
      height: 24px;

      &::before {
        width: 15px;
      }

      &::after {
        height: 15px;
      }
    }
  }

  .question {
    align-items: flex-start;
    gap: 12px;

    &_title {
      font-size: 18px;
      line-height: 21.94px;
    }
  }

  .answer {
    font-size: 13px;
    line-height: 18.85px;
  }
}
