.reason_modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--bzr-spacing-large);
  font-family: Montserrat;

  .reason_list {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      padding: 4px 0px;
      align-items: center;
      gap: var(--bzr-spacing-xs);
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: var(--bzr-text-dark-black-primary);
    }
  }

  .continue_button {
    flex-grow: 0;
    max-width: 125px;
  }
}
