@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/media-queries';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .title {
    @include --bzr-h4;

    color: #fff;
    text-align: center;
  }

  .networks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
  }
}

@include bzr-breakpoint('tablet') {
  .container {


    .title {
      color: #2c2d31;
    }

    .networks {
      gap: 8px;
    }

    &.container_view {
      height: unset;
    }

    &.container_view .networks {
      flex-direction: row;
      justify-content: center;
      gap: 16px;
    }
  }
}
