@import '@/styles/variables/mixins/media-queries';

.event_block {
  grid-area: event_block;
  width: min-content;

  a {
    margin-bottom: 16px;
  }

  .no_events {
    display: block;
    margin-bottom: 0;
    width: 330px;
    height: 525px;
    border: 1px solid #56B947;
    border-radius: 12px;
    background: lightgray linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%) no-repeat center;
    // оставил серый бэкграунд на случай, если картинка не загрузилась
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@include bzr-breakpoint('tablet') {
  .event_block {
    .no_events {
      width: 231px;
      height: 231px;
    }
  }
}

@media (max-width: 480px) {
  .event_block {
    a {
      display: none;
    }
    .no_events {
      width: 171px;
      height: 171px;
    }
  }
}