@mixin scroll-styles() {
  right: 0;
  top: 0;
  height: 100%;
  padding: 8px 3.5px;
  border-right: 0.5px solid #e2e2e2;
  border-left: 0.5px solid #e2e2e2;
  background: #fbfbfb;
  border-radius: 0px;
}

.scroll {
  width: 100%;
  height: 100%;
  overflow: auto;

  &_large :global(.os-scrollbar-vertical) {
    @include scroll-styles();
    width: 16px;
  }

  :global(.os-scrollbar-handle) {
    stroke: #DCDBDB ;
    background: #DCDBDB;
    border-radius: 30px;
  }
}