@import '@/styles/variables/mixins/media-queries';

.ads_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-xxl);
}

@include bzr-breakpoint('tablet') {
  .ads_wrapper {
    gap: var(--bzr-spacing-large-44);

    .resent {
      padding-top: 50px;
    }
  }
}

@media (max-width: 768px) {
  .ads_wrapper {
    .resent {
      padding-top: var(--bzr-spacing-large-44);
    }
  }
}

@include bzr-breakpoint('mobile') {
  .ads_wrapper {
    gap: var(--bzr-spacing-large);

    .resent {
      padding-top: var(--bzr-spacing-large);
    }
  }
}