@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/media-queries';

.progress_bar {
  display: flex;
  margin-bottom: var(--bzr-spacing-large-44);
  position: relative;

  .button_back {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0 0 3.1px 0 rgba(127, 127, 127, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -86px;

    svg {
      font-size: 51.2px;
    }
  }

  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    width: 93px;
    min-height: 86px;

    .border {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      gap: 10px;
      border-radius: 50%;
    }

    .pending {
      border: 3px solid var(--bzr-secondary-light-grey);
    }

    .active::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      background: linear-gradient(90deg, #D9FFCB 0%, #47C612 100%);
      z-index: 0;
    }

    .active::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: inherit;
      background: white;
      z-index: 1;
    }

    .completed {
      background-color: var(--bzr-main-green-default);
      border: 3px solid var(--bzr-main-green-default);
    }

    .step_number {
      position: relative;
      z-index: 2;
      color: var(--bzr-secondary-light-grey);
      @include --bzr-h3();
    }

    .step_number_active {
      color: var(--bzr-main-green-default);
    }

    svg {
      font-size: 24px;
      color: var(--bzr-main-bg);
    }

  }

  .title {
    @include --bzr-caption-regular();
    color: var(--bzr-text-grey-medium);
    text-align: center;
  }

  .line {
    width: 115px;
    height: 3px;
    background-color: var(--bzr-secondary-light-grey);
    border-radius: 1.5px;
    border: none;
    margin-top: var(--bzr-spacing-medium-24);
  }

  .line_active {
    border: none;
    background: linear-gradient(90deg, rgb(71, 198, 18), rgb(217, 255, 203));
  }

  .line_completed {
    border: none;
    background: var(--bzr-main-green-default);
  }
}

@include bzr-breakpoint('tablet') {
  .progress_bar {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    margin-bottom: var(--bzr-spacing-premedium);

    .step_description {
      display: flex;

      .title {

        .step_count {
          font-weight: 600;
          color: var(--bzr-text-dark-black-primary);
        }
      }
    }

    .line_wrapper {
      width: 100%;
      height: 5px;
      background-color: var(--bzr-secondary-light-grey);
      border-radius: 2.5px;
      display: flex;

      .line {
        width: 33.33%;
        height: 5px;
        border-radius: 2.5px;
        margin: 0;
      }
      .line_active:nth-child(1) {
        border-radius: 2.5px;
      }
      .line_active:nth-child(2),
      .line_active:nth-child(3) {
        border-radius: 0 2.5px 2.5px 0;
      }
      .line_completed:nth-child(1) {
        border-radius: 2.5px 0 0 2.5px;
      }
      .line_completed:nth-child(2) {
        border-radius: 0;
      }
      .line_completed:nth-child(3){
        border-radius: 0 2.5px 2.5px 0;
      }
    }
  }
}
