@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/caption';

.description_and_photos {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-large-44);

  .block {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-medium-24);

    .block_title {
      @include --bzr-h3();
      color: var(--bzr-text-dark-black-primary);
    }

    .field {
      display: flex;
      justify-content: space-between;

      .secondary_title {
        @include --bzr-m-button-medium();
        line-height: 145%;
        color: var(--bzr-text-deep-medium-grey);

        span {
          color: var(--bzr-secondary-error);
        }
      }

      .field_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: var(--bzr-spacing-xxs);
        flex-wrap: wrap;
        width: 67%;

        .universal_field, .vertical_field {
          display: flex;
          gap: var(--bzr-spacing-small);
          color: var(--bzr-dark-black-primary);

          .info {
            @include --bzr-caption-medium-fontsize(13px);
            color: var(--bzr-text-medium-grey);
            word-wrap: break-word;
            margin-top: -4px;
          }
        }

        .error {
          @include --bzr-caption-medium-fontsize(13px);
          color: var(--bzr-secondary-error);
          word-wrap: break-word;
        }
      }

      .vertical_field {
        flex-direction: column;
      }
    }
  }
}

.marginBottom { // для дополнительных характеристик
  margin-bottom: var(--bzr-spacing-large-44);
}

@include bzr-breakpoint('tablet') {
  .description_and_photos .block .field {
    flex-direction: column;
    gap: var(--bzr-spacing-small);

    .universal_field {
      width: 100%;
      max-width: unset;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .description_and_photos .block {
    gap: var(--bzr-spacing-premedium);

    .block_title {
      font-size: 20px;
    }

    .field .field_wrapper {
      width: 100%;

      .universal_field {
        flex-wrap: wrap;
      }
    }
  }
}