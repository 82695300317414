@import '@/styles/variables/mixins/media-queries';

.container {
  width: 838px;
  height: 460px;

  .content_block {
    display: flex;
    width: 826px;
    height: 460px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .header {
    width: 208px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    &_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;

      &:hover {
        cursor: pointer;
        color: #0085c5;
      }

      &.arrow {
        display: none;
      }
    }
  }

  .list {
    display: flex;
    width: 100%;
    height: 399px;
    gap: 48px;
    align-self: stretch;
  }

  .column {
    max-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }

  .thirdColumn {
    max-width: 240px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .textContainer {
      max-width: 240px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
    }

    .imageContainer {
      /* Автоматический отступ сверху для перемещения картинки вниз */
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 232px;

      .image {
        width: 232px;
        height: 232px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .container {
    .content_block {
      width: fit-content;
    }

    .list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .container {
    padding-top: var(--bzr-spacing-medium-24);
    height: 90%;

    .header {
      width: 100%;
      &_title {
        display: inline;
        font-size: 16px;
      }
      &.arrow {
        white-space: nowrap;
      }
    }

    .list {
      display: flex;
      gap: var(--bzr-spacing-large);
      flex-direction: column;

      .thirdColumn {
        .imageContainer {
          display: none;
        }
      }
    }

    .column {
      gap: var(--bzr-spacing-large);
    }
  }
}
