@mixin flex-center($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

.accordion {
  @include flex-center(column, flex-start, flex-start);
  gap: 8px;

  &_headline {
    width: 100%;
    @include flex-center(row, space-between, center);
    gap: 8px;

    &_text {
      @include flex-center(row, flex-start, center);
      gap: 8px;
      cursor: pointer;
      width: 100%;

      p {
        color: #2c2d31;
        font-size: 14px;
        font-weight: 500;
      }

      svg {
        font-size: 24px;
        margin-left: auto;
      }
    }
  }

  .accordion_content {
    @include flex-center(column, flex-start, flex-start);
    gap: 8px;

    p {
      color: #414245;
      font-size: 12px;
      font-weight: 500;
    }

    &_list {
      @include flex-center(row, flex-start, flex-start);
      gap: 8px 4px;
      flex-wrap: wrap;
      padding-bottom: 8px;

      .remove_tag {
        cursor: pointer;
        color: #2c2d31;
        @include flex-center(row, center, center);
        font-size: 16px;
      }
    }
  }

  .selected_tags {
    @include flex-center(row, flex-start, flex-start);
    gap: 8px 4px;
    flex-wrap: wrap;
  }
}

.tag,
.selected_tag {
  @include flex-center(row, center, center);
  gap: 4px;
  height: 24px;
  padding: 4px 8px !important;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer !important;
  color: #787b80 !important;
}

.selected_tag {
  background: #efefef !important;
  color: #2c2d31 !important;
}
