@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/dotted';

.container {
  display: flex;
  padding: 12px;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: var(--bzr-secondary-very-light-grey-2);
  }

  &_checkbox {
    flex-shrink: 0;
  }

  &_message {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
  }
}

.section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
  flex: 1 0 0;

  &_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    &_message {
      display: flex;
      flex-direction: row;
      gap: 4px;
      width: 100%;
      align-self: stretch;
      color: #787b80;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      span {
        @include bzr-dotted(1);
        word-break: break-word;
      }

      &_new {
        color: #2c2d31;
      }
    }
  }

  &_state {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    &_message {
      width: 24px !important;
      height: 24px !important;
    }

    &_box {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: var(--bzr-spacing-xs);
    }

    &_time {
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #2c2d31;

      &_read {
        color: #a7aaaf;
      }
    }
  }
}

@include bzr-breakpoint('tablet') {
  .container {
    border-radius: unset;
    gap: 20px;
    padding-bottom: 0;

    &_checkbox {
      order: 1;
      margin-top: 0;
    }

    &_message {
      gap: 8px;
      box-shadow: inset 0 -0.5px 0 0 var(--bzr-secondary-light-grey);
      padding-bottom: 12px;
    }
  }

  .section {
    gap: 8px;

    &_info {
      gap: 5px;

      &_message {
        font-size: 12px;
        line-height: 145%;
      }

      .name_ad {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }

    &_state {
      &_message {
        width: 16px !important;
        height: 16px !important;
      }

      &_time {
        font-size: 12px;
      }
    }
  }
}
