@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/headlines';

.side_menu {
  width: 100%;
  height: 734px;
  padding: 0 var(--bzr-spacing-premedium);

  .user_info {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    & + div {
      // avatar
    }

    .add_photo {
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: var(--bzr-secondary-light-grey);
      border: none;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 76px;
      right: 78px;

      .add_photo_icon {
        font-size: 24px;
      }
    }

    .user_name {
      margin: var(--bzr-spacing-small) 0 var(--bzr-spacing-medium) 0;

      p {
        text-align: center;
      }

      .name {
        @include --bzr-h3();
        margin-bottom: var(--bzr-spacing-xxs);
      }

      .seller_type {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--bzr-text-medium-grey);
      }
    }

    .rating {
      margin-bottom: var(--bzr-spacing-medium);

      .rating_number {
        display: flex;
        gap: var(--bzr-radius-xs);
        margin-bottom: var(--bzr-radius-xs);

        p {
          @include --bzr-h4();
          color: var(--bzr-text-dark-black-primary);
        }

        p + div {
          // чтобы не лезть в компонент UserRating и там не менять стили
          gap: var(--bzr-spacing-xxs);

          svg {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }

      .reviews {
        @include --bzr-m-button-medium();
        color: var(--bzr-main-blue-default);
        display: block;
        text-align: center;
      }
    }

    .share_profile {
      margin-bottom: var(--bzr-spacing-medium);
      width: 100%;
    }
  }

  .links {
    .menu_block {
      padding: var(--bzr-spacing-xs) 0;
      border-top: 0.5px solid var(--bzr-secondary-grey-scroll);
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-xxs);

      a {
        @include --bzr-m-button-medium();
        color: var(--bzr-text-dark-black-primary);
        width: 100%;
        padding: 10px var(--bzr-spacing-small);
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xs);

        svg {
          // для всех
          font-size: 24px;
          path {
            stroke: #cdcdcf;
            fill: #cdcdcf;
          }
        }
        .custom_svg {
          stroke: red;
          fill: green;
          path {
            stroke: white;
            fill: #cdcdcf;
          }
        }

        .alert_svg {
          margin-left: auto;

          path {
            stroke: #f46060;
            fill: #f46060;
          }
        }

        &:hover {
          border-radius: var(--bzr-spacing-small);
          border: none;
          background-color: var(--bzr-secondary-very-light-grey-2);
          color: var(--bzr-main-blue-default);
          @include --bzr-m-button-semibold();
        }
      }
      .active_link {
        border-radius: var(--bzr-spacing-small);
        border: none;
        background-color: var(--bzr-secondary-very-light-grey-2);
        color: var(--bzr-main-blue-default);
        @include --bzr-m-button-semibold();
      }
    }
  }
}

.chat,
.favourite {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  flex-shrink: 0;

  span {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);
  }
}