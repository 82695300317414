.my_ads_page {
  max-width: 872px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-large);

  .my_ads_tab {
    min-width: 361px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--bzr-spacing-large);
    align-self: stretch;

    .not_active_ads,
    .not_hidden_ads {
      color: var(--bzr-text-dark-black-primary);
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
    }

    .not_active_ads {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--bzr-spacing-medium-24);

      a {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
      }
    }
  }

  .my_ads_list {
    display: flex;
    flex-flow: column;
    gap: 20px;

    &_headline {
      display: flex;
      flex-flow: column;
      gap: 20px;

      .sorting {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-family: Montserrat;
          font-size: 24px;
          font-weight: 600;
          line-height: 29.26px;
          text-align: left;
          color: #2c2d31;
        }
      }

      .hiding {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        .hide {
          height: 40px;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 17.07px;
          text-align: left;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &_mobile_headline {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .hiding {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        .hiding_button {
          font-size: 14px;
          font-weight: 500;
          line-height: 17.07px;
          text-align: left;

          svg {
            font-size: 24px;
          }
        }
      }
    }

    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .button_add {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 68px;
    padding: 12px;
    background: #fff;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: 1024px) {
  .my_ads_page {
    max-width: inherit;

    .my_ads_tab {
      min-width: inherit;
      display: block;

      .not_active_ads,
      .not_hidden_ads {
        margin-top: 116px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.9px;
        text-align: center;
        color: #a7aaaf;
      }
    }

    .my_ads_list {
      margin-bottom: 150px;
    }

    .button_add {
      bottom: 68px;
      a {
        padding: 10px var(--bzr-spacing-premedium);
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 480px) {
  .my_ads_page {
    gap: 22px;
  }
}
