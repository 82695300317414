@import '../../../../styles/variables/mixins/media-queries.scss';

@mixin common-styles {
  padding: 12px 0;
  box-shadow: 0px 4px 12px 0px #09090926;
  border-radius: 12px;
}

@mixin list-styles {
  list-style-type: none;

  li {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #a7aaaf;
    cursor: pointer;

    &:hover,
    &:active {
      background: #f5f5f5;
    }

    &.selected {
      background: #f5f5f5;
    }
  }
}

.history {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 50px;
  background-color: #ffff;
  @include common-styles;

  &_headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
    }

    .clear_history {
      color: #a7aaaf;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      cursor: pointer;
    }
  }

  &_list {
    @include list-styles;
  }
}

@media (max-width: 768px) {
  .history {
    top: 115px;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0px 4px 4px 0px #09090926;
    padding: 0;

    &_headline {
      padding: 0 16px;
    }
  }
}

@media (max-width: 480px) {
  .history {
    top: 70px;
    left: 0;
    transform: none;
    box-shadow: none;

    &_headline {
      padding: 6px 12px;
    }
  }
}
