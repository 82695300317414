@mixin bzr-primary-green-button() {
  background-color: var(--bzr-main-green-default);
  color: var(--bzr-main-bg);
  transition: background-color 0.15s, color 0.15s;

  &:hover:not(:disabled) {
    background-color: var(--bzr-main-green-hover);
  }
  &:active:not(:disabled) {
    background-color: var(--bzr-main-green-pressed);
  }
  &:disabled {
    background-color: var(--bzr-secondary-very-light-grey);
    color: var(--bzr-text-medium-grey);
  }
}
