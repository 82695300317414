.block_personal_advs {
  width: 100%;
  border-radius: 12px;
  background: #ffff;
  box-shadow: -4px 4px 15px 0px rgba(31, 54, 92, 0.08);

  .personal_advs_title {
    width: 100%;
    padding: 24px;
    height: 95px;
    border-bottom: 1px solid #c7ecff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      width: auto;
      font-size: 32px;
      color: #0085c5;
      font-weight: 600;
      line-height: 41px;
    }
    span {
      background-color: #0085c5;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        padding: 4px 4px;
      }
    }
  }

  .personal_advs_table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #c7ecff;

        th {
          max-width: 10vw;
          padding: 16px 24px;
          text-align: start;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
        }
      }
    }

    tbody {
      max-height: 300px;

      tr {
        td {
          max-width: 10vw;
          padding: 16px 24px;
          border-bottom: 1px solid #c7ecff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          text-transform: capitalize;

          .poster_card_img {
            width: 50px;
            height: 50px;
            border-radius: 4px;

            img {
              border-radius: 4px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .poster_card_title {
            cursor: pointer;
            text-transform: capitalize;
            word-wrap: break-word;
          }

          .adv_title {
            cursor: pointer;
          }

          .personal_card_rating {
            display: flex;
            gap: 6px;
            p {
              font-size: 14px;
              font-weight: 600;
              line-height: 19px;
            }

            span {
              font-size: 14px;
              color: #adadad;
              font-weight: 600;
              line-height: 19px;
              cursor: pointer;
            }
          }

          .personal_ads_card_actions {
            display: flex;
            gap: 6px;
            button {
              font-size: 24px;
              cursor: pointer;
            }
          }

          .poster_card_actions {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            img, svg {
              cursor: pointer;
            }
          }
        }
      }
      .description {
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .deactivated_poster_description {
        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .block_personal_advs {
    .personal_advs_table {
      thead,
      tbody {
        tr th,
        tr td {
            padding: 16px 9px;
          }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .block_personal_advs {
    margin: 0 auto;
    width: 90%;
    .personal_advs_title {
      gap: 10px;
      padding: 12px;
      h2 {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .personal_advs_table {
      width: 100%;
      thead {
        tr th {
          padding: 10px 18px;
          text-align: start;
          border-bottom: 1px solid #c7ecff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 18px;
            .personal_ads_card_actions {
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
}
