@import '@/styles/variables/mixins/media-queries';

.modal {
  display: grid;
  grid-template-columns: 441px 421px;
  justify-content: center;
  align-items: flex-start;

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 16px;
    width: 100%;
    padding: 32px;
  }

  &.modal_view_no_network {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-height: 528px;
    height: 100%;
    width: 457px;
  }

  &.modal_view_no_network .modal_wrapper {
    padding: 32px 40px;
  }
}

.profile_details {
  width: 100%;
  height: 100%;
}

.social_media {
  border-radius: 0 12px 12px 0;
  width: 100%;
  height: 100%;
  background: #2c2d31;
  padding: 32px 40px;
}

@include bzr-breakpoint('tablet') {
  .modal {
    display: flex;
    padding: 0 0 18px 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    height: unset;

    &_wrapper {
      padding: 0;
      max-width: 500px;
    }

    &.modal_view_no_network {
      height: unset;
      width: 100%;
      max-width: 500px;
    }

    &.modal_view_no_network .modal_wrapper {
      padding: 0;
    }
  }

  .container {
    gap: 0;
  }

  .header {
    padding: 6px var(--bzr-spacing-small);
  }

  .social_media {
    border-radius: 0;
    background: inherit;
    padding: 0;
    height: unset;
    max-width: 500px;
  }
}
