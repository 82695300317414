@import '@/styles/variables/mixins/media-queries';

.have_anything {
  .button_wrapper {
    width: fit-content;
    margin-bottom: var(--bzr-spacing-large);
  }
}

@include bzr-breakpoint('tablet') {
  .have_anything {
    .button_wrapper {
      width: 100%;
      margin-bottom: var(--bzr-spacing-medium);
    }
  }
}