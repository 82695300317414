@import '@/styles/variables/mixins/media-queries';

@mixin text-styles($color: #fff, $f-size: 11px, $f-weight: 400) {
  font-family: Montserrat;
  font-size: $f-size;
  font-weight: $f-weight;
  color: $color;
  font-style: normal;
  line-height: normal;
}

@mixin backgroundCount($background: #f46060) {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: $background;
}

.sidebar {
  @include backgroundCount();
  @include text-styles($f-size: 14px, $f-weight: 500);
  height: 24px;
  min-width: 28px;
  max-width: 49px;
}

.chat {
  @include backgroundCount($background: #47c612);
  @include text-styles($f-size: 16px, $f-weight: 600);
  height: 24px;
  min-width: 28px;
  padding: 0 8px;
}

.header {
  @include backgroundCount();
  @include text-styles();
  height: 16px;
  min-width: 20px;
  max-width: 33px;
}

.mobile_nav_bar {
  @include backgroundCount();
  @include text-styles($f-size: 10px);
  max-width: 31px;
  min-width: 16px;
  height: 14px;
  box-shadow: 0 0 0 1.5px #fff;
}

.red {
  background-color: #f46060;
}

.grey {
  background-color: #CDCDCF;
}

.green {
  background-color: var(--bzr-main-green-default);
}

@include bzr-breakpoint('tablet') {
  .chat {
    height: 16px;
    min-width: 20px;
    padding: 0 4px;
    font-size: 12px;
  }
}
