@mixin bzr-base-secondary-button() {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: var(--bzr-spacing-xxs);
  gap: var(--bzr-spacing-xxs);
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;

  &:disabled {
    color: var(--bzr-text-medium-grey);
    cursor: default;
  }
}
