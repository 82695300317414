.pagination_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

@media (max-width: 1024px) {
  .pagination_list {
    grid-template-columns: repeat(3, minmax(152px, 1fr));
    gap: 8px;
  }
}

@media (max-width: 767px) {
  .pagination_list {
    grid-template-columns: repeat(auto-fit, minmax(171px, 1fr));
    justify-items: center;
    gap: 9px;
  }
}
@media (max-width: 375px) {
  .pagination_list {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
