
@mixin base-text-styles($color: #787b80, $size: 12px, $weight: 400) {
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
}

.calendar {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 0;
  gap: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }


  .month {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    &_header {
      @include base-text-styles();
    }

    &_column {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }

    .day {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 67px;
      padding: 12px;
      border-radius: 16px;
      gap: 4px;

      &:hover {
        cursor: pointer;
        background: var(--bzr-secondary-very-light-grey-2, #f5f5f5);
      }

      &_pressed {
        background: var(--bzr-secondary-light-blue-categories, #daf3ff);
      }

      &_number {
        @include base-text-styles($color: #2c2d31, $size: 20px, $weight: 600);
      }

      &_week {
        @include base-text-styles();
      }
    }
  }
}

@media (max-width: 1024px) {
  .calendar {
    .month {
      .day {
        padding: 8px 12px;
      }
    }
  }
}

@media (max-width: 480px) {
  .container{
    gap: 5px;
  }

  .calendar {
    gap: 12px;

    .month {
      &_header {
        @include base-text-styles($color: #a7aaaf, $size: 10px, $weight: 500);
      }

      &_column {
        gap: 4px;
      }

      .day {
        width: 36px;
        height: 52px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 0;

        &_number {
          @include base-text-styles($color: #2c2d31, $size: 18px, $weight: 600);
        }

        &_week {
          @include base-text-styles($color: #b9bcc0, $size: 10px, $weight: 500);
        }
      }
    }
  }
}
