.linear_list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--bzr-spacing-xs);
}

.all_categories_button {
  width: 114px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px var(--bzr-spacing-small);
  border-radius: var(--bzr-radius-s);
  background: var(--bzr-secondary-very-light-grey);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: var(--bzr-text-dark-black-primary);

  &:hover,
  &:active {
    background: var(--bzr-secondary-light-blue-categories);
  }
}
