.label {
  position: relative;
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 50%;
  padding: 0;
  input {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--bzr-secondary-very-light-grey-2);
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    color: transparent;
    &::file-selector-button {
      opacity: 0;
    }
    &::-webkit-file-upload-button {
      opacity: 0;
    }
    &::-ms-browse {
      opacity: 0;
    }
    &:focus {
      background-color: var(--bzr-secondary-very-light-grey);
      outline: 3px solid var(--bzr-secondary-light-green);
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    cursor: pointer;
    color: var(--bzr-text-dark-black-primary);
  }
}

.label_small {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  svg {
    width: 16px;
    height: 16px;
  }
}
