@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/media-queries';


.create_ad_page {

  // скрываем mobile navigation bar на странице создания объявления, но показываем на succes page
  ~ .mobile_navigation {
    display: none;
  }

  .title {
    h1 {
      @include --bzr-h1();
      color: var(--bzr-main-blue-default);
    }
  }
}

@include bzr-breakpoint('tablet') {
  .create_ad_page {
    form {
      margin: 0 0 100px;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .create_ad_page {
    form {
      margin: 44px 0 80px;
    }
  }
}