@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.field_inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    max-width: 160px;
    @include font($color: var(--bzr-deep-medium-grey));
    line-height: 23.2px;
    span {
      color: var(--bzr-secondary-error);
    }
  }
  .values {
    position: relative;
    display: flex;
    gap: var(--bzr-spacing-small);
    flex-wrap: wrap;
    width: 67%;

    .input_wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-xs);
      width: 100%;

      div input {
        height: 32px;
        padding: var(--bzr-spacing-xxs) 44px var(--bzr-spacing-xxs) var(--bzr-spacing-small);
        &::placeholder {
          color: var(--bzr-medium-grey);
          font-weight: 400;
          font-size: 14px;
        }
      }
  
      .length {
        position: absolute;
        top: 10px;
        right: 12px;
        @include font($size: 11px, $weight: 400, $color: var(--bzr-placeholder-medium-grey));
      }

      .error {
        max-width: unset;
        width: 100%;
        padding: 0;
        @include font($size: 12px, $color: var(--bzr-secondary-error));
      }
    }

    .tip {
      width: 100%;
      max-width: unset;
      @include font($size: 14px);
      line-height: 18.9px;
    }
  }
}

@media (max-width: 1024px) {
  .field {
    gap: var(--bzr-spacing-medium);
    width: 100%;
    &_inner {
      flex-direction: column;
      gap: var(--bzr-spacing-small);
      p,
      .values {
        width: 100%;
        max-width: unset;
      }
    }
  }
}

@media (max-width: 480px) {
  .field {
    gap: var(--bzr-spacing-premedium);
    &_inner {
      justify-content: flex-start;

      p {
        max-width: unset;
        @include font($size: 16px, $weight: 600);
      }

      .values {
        width: 100%;
        .input_wrapper{
          .length {
            top: 15px;
          }
          .length_company {
            bottom: 14px;
            top: unset;
          }
          div input {
            height: 40px;
          }
        }
      }
    }
  }
}
