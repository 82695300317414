.menu_button {
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;

  svg {
    font-size: 24px;
    color: var(--bzr-text-dark-black-primary);
  }
}

.menu_dropdown {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 12;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  border-radius: 12px;
  background: #fff;
  width: auto;
  min-width: 183px;
  max-width: 200px;
  box-shadow:
    0 0 1px 0 rgba(32, 32, 32, 0.05),
    0 4px 12px 0 rgba(9, 9, 9, 0.15);

  ul {
    width: 100%;
    padding: 0;
  }

  ul > li {
    list-style-type: none;
    width: 100%;
  }

  &_item {
    display: flex;
    align-items: flex-start;
    gap: var(--bzr-spacing-xs);
    padding: 10px 12px;
    background: #fff;
    border-radius: 12px;

    color: var(--bzr-text-dark-black-primary);
    font-family: inherit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: normal;
    width: 100%;

    svg {
      font-size: 16px !important;
      color: inherit;
      flex-shrink: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
    }

    &__disabled {
      color: var(--bzr-text-medium-grey);
    }
  }
}
