.mascot {
  display: flex;
  align-items: center;
  height: 310px;
  position: relative;
  top: 0;
  left: 0;

  &_numbers {
    display: flex;
    flex-direction: row;
    gap: 90px;

    p {
      display: inline-flex;
      color: var(--secondary-very-light-grey, #efefef);
      font-family: Montserrat;
      font-size: 250px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &_label {
    color: var(--text-medium-grey, #a7aaaf);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &_image {
    position: absolute;
    top: 0;
    left: 25%;

    img {
      width: 348px;
      height: 348px;
    }
  }
}

.buttonM {
  padding: 15px 20px !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.buttonS {
  padding: 15px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

@media (max-width: 740px) {
  .mascot {
    height: 245px;

    &_numbers {
      gap: 25px;

      p {
        font-size: 200px;
      }
    }

    &_image {
      top: 14%;
      left: 32%;

      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}

@media (max-width: 480px) {
  .mascot {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-spacing_xs, 8px);
    height: 245px;

    &_image {
      position: static;

      width: 220px;
      height: 220px;

      img {
        width: 220px;
        height: 220px;
      }
    }
  }
}
