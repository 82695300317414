@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/media-queries';

@mixin font-styles(
  $weight: normal,
  $size: 12px,
  $line-height: normal,
  $color: #2c2d31
) {
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $line-height;
  color: $color;
}

.user_profile,
.user_ad {
  display: grid;
  width: fit-content;
  align-items: flex-start;
  gap: 8px 16px;
  grid-template-areas:
    'avatar contact'
    '. information';

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .name {
      h2 {
        @include font-styles($weight: 600, $size: 24px, $color: #2c2d31);
      }
    }

    .tech_info {
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-xxs);

      .created {
        @include font-styles($size: 14px);
      }
  
      .status {
        @include font-styles($size: 14px, $color: var(--bzr-text-medium-grey));
        &__online {
          color: var(--bzr-main-green-default);
        }
      }
    }
  }

  .information {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    grid-area: information;

    .user {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;
      width: 100%;

      &_block {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }

      &_rating {
        @include font-styles($weight: 600, $size: 16px, $color: #2c2d31);
      }

      &_reviews {
        @include font-styles($weight: 500, $size: 16px, $color: #0085c5);
      }
    }
  }
}

.telegram, .telegram_ad {
  opacity: 0;
}

@include bzr-breakpoint('tablet') {
  .user_profile,
  .user_ad {
    gap: 12px;

    .contact {
      h2 {
        @include font-styles($weight: 600, $size: 16px, $color: #2c2d31);
      }

      .tech_info { 
        .created {
          font-size: 12px;
          color: var(--bzr-text-dark-black-secondary);
          b {
            font-weight: 600;
          }
        }
    
        .status {
          font-size: 12px;
        }
      }
    }

    .information {
      .user {
        flex-direction: column !important;
        align-items: flex-start;

        &_rating {
          @include font-styles($weight: 600, $size: 14px, $color: #252528);
        }

        &_reviews {
          @include font-styles($weight: 500, $size: 14px, $color: #0085c5);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .user_ad {
    .contact {
      h2 {
        @include font-styles($weight: 600, $size: 18px, $color: #2c2d31);
      }
    }

    .information {
      gap: 4px;

      .user {
        &_rating {
          @include font-styles($weight: 600, $size: 12px, $color: #252528);
        }

        &_reviews {
          @include font-styles($weight: 500, $size: 12px, $color: #0085c5);
        }
      }
    }
  }

  .telegram {
    opacity: 1;
    padding: 8.75px var(--bzr-spacing-premedium) !important;
    @include --bzr-caption-medium-fontsize(12px);
  }
  .telegram_ad {
    opacity: 1;
    margin-top: 16px;
    padding: 8.75px var(--bzr-spacing-premedium) !important;
    @include --bzr-caption-medium-fontsize(12px);
  }
}

@include bzr-breakpoint('mobile') {
  .user_profile {
    gap: 8px 16px;

    .contact {
      h2 {
        @include font-styles($weight: 600, $size: 24px, $color: #2c2d31);
      }
    }

    .information {
      gap: 8px;

      .user {
        flex-direction: initial !important;

        &_rating {
          @include font-styles($weight: 600, $size: 14px, $color: #252528);
        }

        &_reviews {
          @include font-styles($weight: 500, $size: 14px, $color: #0085c5);
        }
      }

      .company_info {
        display: flex;
        align-items: center;
        gap: 4px;
        @include font-styles($weight: 500, $size: 14px, $color: #a7aaaf);

        svg {
          font-size: 24px;
          color: #a7aaaf;
        }
      }
    }
  }
}
