.wrapper {
  position: relative;
  width: 100%;

  .price_input {
    width: 100%;
    height: 40px;
    padding: 11px;
    border-radius: var(--bzr-spacing-small);
    border: 1px solid var(--bzr-secondary-light-grey);
    padding: var(--bzr-spacing-xxs) var(--bzr-spacing-small) !important;

    &:focus {
      border-color: transparent;
      outline: 3px solid var(--bzr-secondary-light-green);
    }

    &:disabled {
      background: var(--bzr-secondary-very-light-grey);
      border: none;
      color: var(--bzr-text-medium-grey);
    }
  }

  .icon {
    position: absolute;
    top: 12px !important;
    left: unset !important;
    right: 10px;
    width: 16px !important;
    height: 16px !important;
    color: var(--bzr-text-dark-black-primary) !important;
  }
}
