@mixin base-black-text() {
  color: #2c2d31;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  font-style: normal;
}

.base {
  display: inline-flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
}

.light-green {
  background: #d9ffcb;
  border-radius: var(--bzr-spacing-premedium);
  border: 0.5px solid var(--bzr-main-green-default);
}

.green-default {
  background: #47c612;
}

.very-light-grey {
  background: #f5f5f5;

  &:hover {
    background: #e2e2e2;
  }
}

.white {
  background: #fff;
  border-radius: var(--bzr-spacing-premedium);
  border: 0.5px solid var(--bzr-secondary-light-grey);
}

.statusXS {
  @include base-black-text;
  height: 21px;
  padding: 4px 8px;
  font-size: 11px;
}

.statusS {
  @include base-black-text;
  height: 24px;
  padding: 4px 8px;
  font-size: 12px;
}

.statusM {
  @include base-black-text;
  height: 28px;
  padding: 0px 8px;
  font-size: 13px;
}

.tagMobile {
  @include base-black-text;
  height: 28px;
  padding: 4px 12px;
}

.tagMobile_white {
  @include base-black-text;
  height: 28px;
  padding: 4px 12px;

  color: #fff;
  font-weight: 600;
}

.tagM {
  @include base-black-text;
  height: 28px;
  padding: 4px 12px;
  gap: 4px;
  font-size: 14px;
}

.tagM_white {
  @include base-black-text;
  height: 28px;
  padding: 4px 12px;
  gap: 4px;

  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
