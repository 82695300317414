.container {
  display: flex;
  height: 44px;
  width: 310px;
  padding: 0 12px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  transition: background 0.3s;

  &:hover {
    background: #f5f5f5;
    cursor: pointer;

    .text {
      color: #0085c5;
      font-weight: 600;

      svg {
        fill: #0085c5;
      }
    }
  }

  img {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
  }

  .text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: #2c2d31;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    gap: 4px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}