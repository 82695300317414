.input {
  display: inline-flex;
  padding: 11px;
  align-items: center;
  gap: var(--bzr-spacing-xs);
  border-radius: var(--bzr-radius-s);
  border: 1px solid var(--bzr-secondary-light-grey);
  background: var(--color-white);

  white-space: nowrap; // многоточие для placeholder
  text-overflow: ellipsis;

  &:focus {
    border-color: transparent;
    outline: 3px solid var(--bzr-secondary-light-green);
  }

  &:disabled {
    background: var(--bzr-secondary-light-grey);
    border: none;
  }
}

.input_error {
  border: 1px solid var(--bzr-secondary-error);
}

.wrapper {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.wrapper_fullWidth {
  width: 100%;

  .input {
    width: inherit;
  }
}

.wrapper_withPostfix {
  width: 100%;

  .input {
    width: inherit;
    padding-right: 28px;
  }
}

.wrapper_password {
  width: 100%;

  .input[type='password'] {
    width: inherit;
    height: 40px;
    padding-right: 28px;
    font-size: 18px;
    line-height: 1;
    font-weight: 900;
  }
}

.postfix {
  font-size: 24px;
  width: 24px;
  height: 24px;
  transform: translate(-5px, 8px);
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password_icon {
  svg {
    color: var(--bzr-text-medium-grey);
  }
}
