.comment {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--bzr-text-dark-black-primary);
  word-break: break-word;
  white-space: pre-wrap;
  .more {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: var(--bzr-main-blue-default);
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .comment,
  .comment .more {
    font-size: 12px;
  }
}