.my_ad_card {
  width: 100%;
  display: flex;
  padding: 12px 0;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;

  .card {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;

    .image {
      width: 227px;
      aspect-ratio: 1.6;
      overflow: hidden;
      flex-shrink: 0;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 0.5px solid #e2e2e2;
        border-radius: 12px;
        cursor: pointer;
      }

      svg {
        width: 32px;
        height: 45px;
      }
    }

    &_content {
      display: flex;
      align-items: flex-start;
      gap: 44px;
      flex: 1 0 0;
      align-self: stretch;

      .active_ads {
        max-width: 400px !important;
      }

      .ad_info,
      .active_ads {
        display: flex;
        flex-direction: column;
        min-width: 369px;
        max-width: 454px;
        width: 100%;

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-spacing_xs, 8px);
          align-self: stretch;

          h4 {
            color: #0085c5;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
          }

          p {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #2c2d31;
            font-size: 18px;
            font-weight: 600;

            svg {
              width: 11.196px;
              height: 17.5px;
            }
          }
        }

        .description {
          margin-top: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          p {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
          }
        }

        .location_date {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .city {
            display: flex;
            align-items: center;
            gap: 4px;

            p {
              color: #787b80;
              font-size: 14px;
              font-weight: 500;
            }

            svg {
              width: 24px;
              height: 24px;
              color: #787b80;
            }
          }

          .date {
            color: #787b80;
            font-size: 14px;
            font-weight: 400;
            line-height: 135%;
          }
        }
      }

      .actions {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: var(--bzr-spacing-small);
        flex-grow: 1;

        svg {
          width: 24px;
          height: 24px;
        }

        .main_edit {
          padding: 4px var(--bzr-spacing-premedium);

          svg {
            color: var(--bzr-main-blue-default);
          }
        }

        .hide {
          height: 32px;
          color: #2c2d31;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }

        .open {
          height: 32px;
        }

        .drafts_buttons {
          width: 100%;
          display: flex;
          align-items: flex-start;
          align-items: center;
          gap: var(--spacing-spacing_xxs, 4px);

          .edit {
            flex-shrink: 1;
            height: 32px;
          }

          .delete {
            flex-shrink: 1;
            width: 32px;
            height: 32px;
            padding: 4px;
          }
        }
      }
    }
  }
}
