body.modal-open {
  overflow: hidden;
}

.modal {
  width: 100%;
  position: fixed;
  z-index: 1100;
  bottom: 0;
  left: 0;
  right:0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-spacing_premedium, 12px);
  border-radius: var(--spacing-spacing_small, 12px) var(--spacing-spacing_small, 12px) 0 0;
  box-shadow: -4px -4px 7px 0px rgba(31, 54, 92, 0.03), 4px 4px 7px 0px rgba(31, 54, 92, 0.03);
  padding-bottom: var(--bzr-spacing-small);
  background: var(--bz-modal-background, #fff);
}

.modal.modal_clean {
  padding-bottom: 0;
}

.modal_dark {
  background: #121314;
}

.modal__header {
  padding: var(--bzr-spacing-small);
  width: 100%;
  display: flex;
  gap: 16px;
  line-height: normal;
  justify-content: space-between;
  align-items: center;
}

.modal__header_bordered {
  border-bottom: 0.5px solid var(--bzr-secondary-grey-scroll);
  padding-bottom: calc(var(--bzr-spacing-small) - 0.5px);
}

.modal_dark .modal__header_bordered {
  border-color: #2C2D31;
}

.modal__action {
  min-width: 24px;
  align-self: flex-start;
  svg {
    font-size: 24px;
    color: var(--bz-modal-close-color, var(--bzr-text-dark-black-primary)); // для ios
  }

  .close_button {
    opacity: 0;
    transition: opacity 0.3s ease;

    &.close_visible {
      opacity: 1;
    }
  }
}

.modal_dark .modal__action svg {
  color: #ffffff;
}

.modal__title {
  color: var(--text-dark-black-primery, #2C2D31);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  max-width: calc(100% - 24px * 2 - 32px);
}

.modal_dark .modal__title {
  color: #FFFFFF;
}

.modal__content {
  max-height: 100%;
  overflow: auto;
  padding: 0 var(--bzr-spacing-small);
}

.modal__footer {
  padding: 0 var(--bzr-spacing-small);
}

.modal_clean .modal__footer {
  padding: 0;
}

.modal_fullHeight {
  top: 0;
  border-radius: 0;

  .modal__content {
    height: 100%;
  }
}

.modal_almostFullHeight {
  top: 44px;
}
