@mixin bzr-breakpoint($breakpoint) {
  @if $breakpoint == 'mobile' {
    @media (max-width: 480px) { // вставка переменной var(--bzr-mobile) не срабатывает
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

// пример использования
// @import 'относительный или абсолютный путь к файлу с миксином';
//
// .класс {
//   background-color: blue;
// }
//
// @include bzr-breakpoint('mobile') {
//   .класс {
//     background-color: red;
//   }
// }
//
// @include bzr-breakpoint('tablet') {
//   .класс {
//     background-color: green;
//   }
// }
//
// @include bzr-breakpoint('desktop') {
//   .класс {
//     background-color: yellow;
//   }
// }