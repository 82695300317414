@import '@/styles/variables/mixins/media-queries';

@mixin icon-styles($right, $bottom, $width) {
  position: absolute;
  right: $right;
  bottom: $bottom;
  width: $width;
  height: $width;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  top: 0;
  left: 0;
  height: 102px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: #efefef;
  box-shadow: 4px 4px 15px 0px rgba(0, 133, 197, 0.05);
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background: #daf3ff;
  }

  .text {
    color: #2c2d31;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }
}

// Транспорт
.transport {
  width: 178px;

  .icon {
    @include icon-styles($right: -10px, $bottom: -17px, $width: 89px);

    img {
      width: 89px;
      height: 89px;
    }
  }
}

// Личные вещи
.personal_items {
  width: 193px;
  //width: 204px;

  .icon {
    @include icon-styles($right: -17px, $bottom: -21px, $width: 97px);

    img {
      width: 97px;
      height: 97px;
    }
  }
}

// Недвижимость
.real_estate {
  width: 206px;

  .icon {
    @include icon-styles($right: -18px, $bottom: -26px, $width: 102px);

    img {
      width: 102px;
      height: 102px;
    }
  }
}

// Электроника
.electronics {
  width: 209px;

  .icon {
    @include icon-styles($right: -23px, $bottom: -23px, $width: 121px);

    img {
      width: 121px;
      height: 121px;
    }
  }
}

// Услуги
.services {
  width: 182px;

  .icon {
    @include icon-styles($right: -19px, $bottom: -14px, $width: 105px);
    transform: rotate(-42.302deg);

    img {
      width: 105px;
      height: 105px;
    }
  }
}

// Для дома
.home {
  width: 213.5px;

  .icon {
    @include icon-styles($right: -20.5px, $bottom: -21px, $width: 118px);

    img {
      width: 118px;
      height: 118px;
    }
  }
}

// Животные
.animals {
  width: 213.5px;

  .icon {
    @include icon-styles($right: -26.166px, $bottom: -21px, $width: 121px);

    img {
      width: 121px;
      height: 121px;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .container {
    height: 109.5px;
  }
}

@media (max-width: 768px) {
  .container {
    height: 111.5px;

    .text {
      font-size: 14px;
    }
  }

  // Транспорт
  .transport {
    width: 124px;

    .icon {
      @include icon-styles($right: -13px, $bottom: -18px, $width: 100px);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  // Личные вещи
  .personal_items {
    width: 124px;

    .icon {
      @include icon-styles($right: -31px, $bottom: -18px, $width: 100px);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  // Недвижимость
  .real_estate {
    width: 158px;

    .icon {
      @include icon-styles($right: -18px, $bottom: -18px, $width: 100px);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  // Электроника
  .electronics {
    width: 156px;

    .icon {
      @include icon-styles($right: -17px, $bottom: -12.5px, $width: 100px);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  // Услуги
  .services {
    width: 124px;

    .icon {
      @include icon-styles($right: -17px, $bottom: -14px, $width: 100px);
      transform: rotate(-43.28deg);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  // Для дома
  .home {
    width: 152px;

    .icon {
      @include icon-styles($right: -15px, $bottom: -14.5px, $width: 100px);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  // Животные
  .animals {
    width: 144px;

    .icon {
      @include icon-styles($right: -13px, $bottom: -17.5px, $width: 100px);

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .container {
    height: 81.5px;
    padding: 12px;

    .text {
      font-size: 12px;
    }
  }

  // Транспорт
  .transport {
    width: 99px;

    .icon {
      @include icon-styles($right: -9px, $bottom: -7.5px, $width: 65px);

      img {
        width: 65px;
        height: 65px;
      }
    }
  }

  // Личные вещи
  .personal_items {
    width: 105px;

    .icon {
      @include icon-styles($right: -18px, $bottom: -14.5px, $width: 81px);

      img {
        width: 81px;
        height: 81px;
      }
    }
  }

  // Недвижимость
  .real_estate {
    width: 127px;

    .icon {
      @include icon-styles($right: -20px, $bottom: -12.5px, $width: 75px);

      img {
        width: 75px;
        height: 75px;
      }
    }
  }

  // Электроника
  .electronics {
    width: 133.3px;

    .icon {
      @include icon-styles($right: -15px, $bottom: -8px, $width: 70px);

      img {
        width: 70px;
        height: 70px;
      }
    }
  }

  // Услуги
  .services {
    width: 86px;

    .icon {
      @include icon-styles($right: -9px, $bottom: -7px, $width: 62.255px);
      transform: rotate(-44.006deg);

      img {
        width: 62.255px;
        height: 62.255px;
      }
    }
  }

  // Для дома
  .home {
    width: 133.3px;

    .icon {
      @include icon-styles($right: -17px, $bottom: -16px, $width: 78px);

      img {
        width: 78px;
        height: 78px;
      }
    }
  }

  // Животные
  .animals {
    width: 133.3px;

    .icon {
      @include icon-styles($right: -15px, $bottom: -10px, $width: 69px);

      img {
        width: 69px;
        height: 69px;
      }
    }
  }
}
