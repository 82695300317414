@import '@/styles/variables/mixins/media-queries';

.wrapper {
  width: 100%;
  flex: 1;

  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15px;
    box-shadow: inset 0px -10px 15px -10px rgba(9, 9, 9, 0.1);
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16px;
    box-shadow: inset 0 10px 16px -10px rgba(9, 9, 9, 0.1);
    pointer-events: none;
    z-index: 1;
  }
}

.container {
  width: 100%;
  display: flex;
  padding: 32px 24px 32px 24px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  background: linear-gradient(90deg, #fff 0%, #f6f6f6 50%, #fff 100%);
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;

  &_time {
    color: #2c2d31;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &_messages {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  &_block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    flex: 1 0 0;
    width: 100%;

    &_system {
      &_last {
        padding-top: 32px;
        border-top: 0.5px solid #e2e2e2;
        gap: 20px;
      }

      &_middle {
        padding: 32px 0;
        border-top: 0.5px solid #e2e2e2;
        border-bottom: 0.5px solid #e2e2e2;
        gap: 20px;
      }
    }
  }
}

.information {
  display: flex;
  width: 100%;
  padding-top: 32px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  color: #787b80;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@include bzr-breakpoint('tablet') {
  .wrapper {
    &::before,
    &::after {
      box-shadow: none;
    }
    :global(.os-scrollbar-vertical) {
      width: 0;
      padding: 0;
      border-right: none;
      border-left: none;
    }
  }

  .container {
    padding: calc(var(--bzr-spacing-medium) + 48px) 0 var(--bzr-spacing-medium) 0;
  }

  .day {
    &_time {
      font-size: 14px;
    }

    &_block {
      &_system {
        &_last {
          padding-top: 24px;
        }

        &_middle {
          padding: 24px 0;
        }
      }
    }
  }
}
