@import '@/styles/variables/mixins/media-queries';

@mixin text-style($f-size: 16px, $f-weight: 500, $color: #787b80) {
  font-size: $f-size;
  font-weight: $f-weight;
  color: $color;
  font-style: normal;
  line-height: normal;
  text-align: center;

  @include bzr-breakpoint('tablet') {
    font-size: 14px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}

.description {
  @include text-style;
}

.button {
  @include text-style($color: #fff);
}

@include bzr-breakpoint('mobile') {
  .wrapper {
    gap: 16px;
  }
}

@include bzr-breakpoint('mobile') {
  .button {
    @include text-style($f-weight: 600);
    width: 100%;
  }
}
