@import '@/styles/variables/mixins/fonts/buttons';

@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($gap: var(--bzr-spacing-premedium)) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.review_item {
  @include flex-column($gap: var(--bzr-spacing-small));
  width: 100%;

  .reviewer {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);
    width: fit-content;
    cursor: pointer;
    text-decoration: none;

    p {
      @include font($weight: 600);
    }
  }

  .deal {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);

    p {
      @include font($size: 14px, $color: var(--bzr-text-medium-grey));
      cursor: default;
    }

    span {
      @include font($size: 14px, $color: var(--bzr-text-medium-grey));
      cursor: pointer;
    }

    [class*="user-rating_userRating"] {
      align-self: start;
    }

    .deal_status {
      word-break: break-all;
      @include font($size: 14px, $color: var(--bzr-text-medium-grey));
      cursor: pointer;
    }
  }

  .comment {
    word-wrap: break-word;
  }

  .images {
    display: flex;
    gap: var(--bzr-spacing-xs);
    flex-wrap: wrap;
    img {
      width: 100px;
      height: 75px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .date {
    @include font($size: 14px, $color: var(--bzr-text-medium-grey));
  }

  .date_and_reply {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-small);

    .date {
      @include font($size: 14px, $color: var(--bzr-text-medium-grey));
    }

    .reply {
      @include --bzr-small-button-medium();
    }
  }
}

.your_answer {
  @include --bzr-m-button-semibold();
  margin-bottom: var(--bzr-spacing-small);
}

@media (max-width: 1024px) {
  .review_item {
    .deal .deal_status {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

@media (max-width: 480px) {
  .review_item {
    width: 100%;
    gap: var(--bzr-spacing-xs);
    background-color: var(--bzr-secondary-white-grey);
    border-radius: var(--bzr-radius-s);
    padding: var(--bzr-spacing-premedium);

    .reviewer {
      p {
        @include flex-column($gap: var(--bzr-spacing-xxs));
        @include font($size: 14px, $weight: 600);
        .date {
          @include font($size: 11px, $color: var(--bzr-text-medium-grey));
        }
      }
    }

    .deal .deal_status{
      @include font($size: 12px, $color: var(--bzr-text-deep-medium-grey));
    }

    .reply {
      width: fit-content;
      padding: 7.75px var(--bzr-spacing-premedium);
      align-self: end;
      margin-top: var(--bzr-spacing-xs);
    }

    .ad_title {
      @include font($size: 12px, $color: var(--bzr-text-deep-medium-grey));
    }
  }
}
