.name {
  color: var(--bzr-text-dark-black-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--bzr-spacing-xs);
  align-self: stretch;
  flex-wrap: wrap;

  &_name {
    display: flex;
    flex-direction: row;
    gap: var(--bzr-spacing-xs);
  }
}

.online {
  color: var(--bzr-main-green-default);
  font-size: 12px;
  font-weight: 600;
}

.offline {
  color: var(--bzr-text-medium-grey);
  font-size: 12px;
  font-weight: 600;
}

.only_name {
  display: flex;
  align-items: center;
  gap: var(--bzr-spacing-xs);
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .wrapper {
    gap: var(--bzr-spacing-xxs);

    &_name {
      gap: var(--bzr-spacing-xxs);
    }
  }

  .name {
    font-size: 14px;
  }

  .online {
    font-size: 11px;
    font-weight: 500;
  }

  .offline {
    font-size: 11px;
    font-weight: 500;
  }

  .only_name {
    gap: var(--bzr-spacing-xxs);
  }
}
