@import './font';

//Button XS/S
@mixin --bzr-small-button-medium() {
  @include bzr-montserrat();
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
@mixin --bzr-small-button-semibold() {
  @include bzr-montserrat();
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

//Button M
@mixin --bzr-m-button-medium() {
  @include bzr-montserrat();
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
@mixin --bzr-m-button-semibold() {
  @include bzr-montserrat();
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

// для storybook
.bzr-small-button-medium { @include --bzr-small-button-medium(); }
.bzr-small-button-semibold { @include --bzr-small-button-semibold(); }
.bzr-m-button-medium { @include --bzr-m-button-medium(); }
.bzr-m-button-semibold { @include --bzr-m-button-semibold(); }
