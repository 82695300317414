@import '@/styles/variables/mixins/media-queries';

.container {
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #e2e2e2;
  background: #fff;
  overflow: hidden;

  &_wrapper_mobile {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 12px;

    &::-webkit-scrollbar-track {
      background-color: #dcdbdb;
    }

    scrollbar-width: thin;
    scrollbar-color: #dcdbdb #fff;
  }

  &:focus-within {
    border: 1px solid transparent;
    box-shadow: 0 0 0 3px var(--bzr-secondary-light-green, #d9ffcb); // для outline не применялся border-radius на ios
  }
}

.error {
  border: 1px solid var(--bzr-secondary-error, #e81313);
}

.charCounter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 13px);
  display: flex;
  justify-content: flex-end;
  color: var(--bzr-text-medium-grey, #a7aaaf);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  padding: 8px 0 8px 0;
}

// Desktop
@mixin overlay-scrollbars-scroll-styles() {
  right: 0;
  top: 0;
  height: 100%;
  padding: 8px 3px;
  border-left: 0.5px solid #e2e2e2;
  background: #fbfbfb;
  border-radius: 0;
}

.overlay_scrollbars {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  :global(.os-scrollbar-handle) {
    stroke: #dcdbdb;
    background: #dcdbdb;
    border-radius: 30px;
  }

  // Ширина скролла
  &_width_12 :global(.os-scrollbar-vertical) {
    @include overlay-scrollbars-scroll-styles();
    width: 12px;
  }
}

.textarea {
  box-sizing: border-box;
  width: 100%;
  resize: none;
  padding: 12px 12px 8px 12px;
  border-radius: 12px;
  background: #fff;
  border: none;

  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  color: #2c2d31;

  &::placeholder {
    color: #a7aaaf;
  }
}

.textarea_padding {
  margin-bottom: 32px;
}

@include bzr-breakpoint('tablet') {
  .textarea {
    padding: 12px 12px 0 12px;
    margin-bottom: 0;
  }

  .charCounter {
    position: static;
    padding: 0 0 8px 0;
  }
}
