.geo_pointer {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  height: 32px;
  font-size: 24px;
  color: #0085c5;
  position: relative;
  white-space: nowrap;
}

.geo_pointer:hover {
  color: #0093db;
}

.geo_pointer:active {
  color: #005f8d;
}

.geo_pointer:disabled {
  color: #a7aaaf;
}

.geo_pointer p {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .geo_pointer {
    gap: 2px;
    font-size: 16px;

    p {
      font-size: 13px;
      line-height: 14.63px;
    }
  }
}
