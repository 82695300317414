@import "@/styles/variables/mixins/fonts/caption";

.link {
  color: var(--text-medium-grey, #a7aaaf) !important;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ad_title {
  @include --bzr-caption-medium-fontsize(12px);
  color: var(--bzr-dark-black-primary);
}

.activeLink {
  color: var(--text-dark-black-primary, #2c2d31) !important;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.link:hover,
.activeLink:active,
.activeLink:hover {
  color: #0085c5 !important;
}
