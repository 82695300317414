@import "@/styles/variables/mixins/dotted";

@mixin font-styles($size, $weight, $height, $color) {
  font-family: Montserrat;
  font-style: normal;
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  color: $color;
}

@mixin flexbox($direction, $jus-cont, $al-items) {
  display: flex;
  flex-direction: $direction;
  justify-content: $jus-cont;
  align-items: $al-items;
}
// высота картинки - 175px, ширина информации о продавце - 187px. Ширина описания объявления - все остальное пространство
.desktop_card {
  height: 100%;
  max-width: 876px;
  display: inline-flex;
  padding: var(--bzr-spacing-small) 0;
  align-items: flex-start;
  gap: var(--bzr-spacing-premedium);

  &_images {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    flex-shrink: 0;
    border-radius: var(--bzr-radius-s);
    overflow: hidden;

    img {
      height: 175px;
      aspect-ratio: 1.62;
      object-fit: cover;
      border-radius: var(--bzr-radius-s);
      border: 0.5px solid var(--bzr-secondary-light-grey);
    }

    .additional_images {
      display: flex;
      align-items: flex-start;
      gap: var(--bzr-spacing-xs);

      img {
        height: 85px;
        aspect-ratio: 1.62;
        border-radius: var(--bzr-radius-s);
        border: 0.5px solid var(--bzr-secondary-light-grey);
      }
    }
  }

  .description_wrapper {
    width: 100%;
    height: calc(100% + 1px); // Эвита попросила добавить 1px
    @include flexbox(column, space-between, flex-start);
    gap: var(--bzr-spacing-small);

    &.more_images {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--bzr-spacing-large-44);
    }

    .information {
      @include flexbox(column, none, flex-start);
      gap: var(--bzr-spacing-premedium);
      align-self: stretch;
      line-break: anywhere;

      .headline {
        @include flexbox(column, none, flex-start);
        gap: var(--bzr-spacing-small);
        align-self: stretch;

        .title {
          display: flex;
          align-items: flex-start;
          gap: var(--bzr-spacing-medium);
          align-self: stretch;
          flex: 1 1 0;

          p {
            @include font-styles(
                    18px,
                    600,
                    135%,
                    var(--color-global-blue-100)
            );
            @include bzr-dotted(2);
          }

          .favorite_button_wrapper {
            position: relative;
            button {
              position: absolute;
              right: 0;
            }
          }
        }

        .price {
          display: flex;
          align-items: center;
          gap: var(--bzr-spacing-xxs);

          p {
            @include font-styles(
                    18px,
                    600,
                    normal,
                    var(--bzr-text-dark-black-primary)
            );
          }

          svg {
            width: 11.196px;
            height: 17.5px;
          }
        }
      }

      .description,
      .more {
        flex: 1 1 0;
        align-self: stretch;

        p {
          @include font-styles(
                  14px,
                  400,
                  135%,
                  var(--bzr-text-dark-black-secondary)
          );

          color: var(--bzr-text-dark-black-secondary);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .more {
        p {
          -webkit-line-clamp: 5;
        }
      }
    }

    .location {
      @include flexbox(none, space-between, center);
      align-self: stretch;

      .city {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xxs);

        span {
          @include font-styles(
                  14px,
                  500,
                  normal,
                  var(--bzr-text-deep-medium-grey)
          );
        }

        svg {
          width: 24px;
          height: 24px;
          color: var(--bzr-text-deep-medium-grey);
        }
      }

      p {
        @include font-styles(
                14px,
                400,
                135%,
                var(--bzr-text-deep-medium-grey)
        );
      }
    }
  }
}

.desktop_card:hover {
  border-radius: var(--bzr-radius-s);
  background: var(--bzr-secondary-very-light-grey-2);
}
