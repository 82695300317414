.wrapper {
  max-width: 440px;
  min-width: 446px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    min-width: 350px;
  }

  @media (max-width: 500px) {
    min-width: 100%;
  }

  * {
    br {
      display: block;
    }
  }
}

.title {
  text-transform: uppercase;
  color: #2C2D31;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.subTitle {
  margin-top: 15px;
  color: #2C2D31;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}

.prizes {
  margin-top: 15px;
  width: 100%;
  display: flex;
  gap: 8px;
  flex-flow: wrap;

  > div {
    border-radius: 12px;
    background: #47C712;
    color: #FFFFFF;
    padding: 12px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    min-height: 112px;

    span:last-child {
      font-weight: 400;
      font-size: 14px;
      margin-top: 6px;
      display: block;
    }

    &.red {
      background: #D72525;
    }

    &.blue {
      background: #0085C5;
    }

    &.macbook {
      background: #47C712 url("./assets/macbook.png") center center no-repeat;
      background-size: 130px;
      width: calc(60% - 8px);
      background-position: calc(100% - 20px) 10px;
    }

    &.promotion1 {
      background: #D72525;
      width: calc(40%);
    }

    &.promotion2 {
      background: #D72525;
      width: calc(30%);

      @media (max-width: 480px) {
        display: none;
      }
    }

    &.dyson {
      background: #0085C5 url("./assets/dyson.png") center center no-repeat;
      background-size: 100px;
      width: calc(40% - 8px);
      background-position: 100% 100%;

      @media (max-width: 480px) {
        width: 70%;
      }
    }

    &.iphone {
      background: #47C712 url("./assets/iphone.png") center center no-repeat;
      background-size: 87px;
      width: calc(30% - 8px);
      background-position: 50% 100%;
    }

    &.super {
      background: #47C712 url("./assets/tickets.png") center center no-repeat;
      background-size: 270px 100%;
      background-position: 100% 100%;
      width: 100%;
    }
  }
}

.button {
  margin-top: 20px;
  width: 100%;
  color: #FFF;
  padding: 10px;
  background: #26292A;
  border-radius: 30px;
}

.darkTheme {
  --bz-modal-background: #18191C;
  --bz-modal-close-color: #fff;

  .allPrizes {
    background: url('./assets/all.png') center center no-repeat;
    height: 170px;
    background-size: contain;
    width: 100%;
  }

  .title {
    color: #FFFFFF;
  }

  .button {
    background: #2DB119;
  }

  .subTitle {
    color: #ffffff;
  }

  .wrapper {
    height: 100%;
  }
}