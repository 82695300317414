//@import '@/styles/variables/mixins/fonts/caption';
@import '../../../../../styles/variables/mixins/fonts/caption'; // абсолютный импорт не работает в сторибуке

.select_placeholder {
  display: flex;
  align-items: center;
  gap: var(--bzr-spacing-xs);

  .sorting_svg {
    color: #2C2D31;
    font-size: var(--bzr-spacing-premedium);
  }

  p {
    color: var(--bzr-text-dark-black-primary);
    @include --bzr-caption-medium-fontsize(13px);
  }
}