@import '@/styles/variables/mixins/media-queries';

.container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-medium-24);
  margin-left: -12px;
  width: 100%;
}


@include bzr-breakpoint('tablet') {
  .container {
    gap: 0;
    margin-left: 0;
  }
}