@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/primary-buttons/primary-green';
@import '@/styles/variables/mixins/fonts/body';


.edit_ad_page {
  padding-top: var(--bzr-spacing-large);

  .header {
    position: relative;

    h1 {
      @include --bzr-h1();
      color: var(--bzr-main-blue-default);
    }

    .button_back {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.80);
      box-shadow: 0 0 3.1px 0 rgba(127, 127, 127, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -86px;
      top: 86px;

      svg {
        font-size: 51.2px;
      }
    }
  }

  .edit_content {
    max-width: 812px;
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-large-44);
    position: relative;

    .form {
      gap: var(--bzr-spacing-large-44);

      .buttons {
        display: flex;
        gap: var(--bzr-spacing-small);
      }
    }
  }
}

.go_to_ad_link {
  @include --bzr-m-button-medium();
  @include bzr-primary-green-button();
  border-radius: var(--bzr-radius-s);
  padding: var(--bzr-spacing-premedium) var(--bzr-spacing-large);
  text-align: center;
}

.no_internet {
  @include --bzr-body-medium-155(16px);
  color: #fff;
  border-radius: 16px;
  background: rgba(232, 19, 19, 0.4);
  display: inline-flex;
  flex-shrink: 0;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

@include bzr-breakpoint('tablet') {
  .edit_ad_page {
    .edit_content {
      padding-top: var(--bzr-spacing-large-44);
      gap: var(--bzr-spacing-large);

      .form {
        gap: var(--bzr-spacing-medium-24);
        min-height: 100svh;

        .buttons {
          width: 100%;
          padding: var(--bzr-spacing-small);
          display: flex;
          align-items: center;
          gap: var(--bzr-spacing-xs);
          flex-direction: column;
          position: fixed;
          left: 0;
          bottom: 0;
          background-color: var(--bzr-main-bg);
        }
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .edit_ad_page {
    min-height: 100dvh; // если оставить min-height на .edit_content, то будет лишний скролл

    .edit_content {
      min-height: unset;
      padding-top: var(--bzr-spacing-medium-24);
      gap: var(--bzr-spacing-medium-24);

      .form {
        gap: var(--bzr-spacing-premedium);
      }
    }
  }

  .go_to_ad_link {
    width: 100%;
  }
}