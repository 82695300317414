@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/headlines';

.main {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;

  .main_section {
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 40px;

    aside {
      flex: 0 0 288px;
    }

    .main_content {
      flex: 1;

      .title {
        @include --bzr-h1();
        color: var(--bzr-main-blue-default);
        width: 100%;
        padding-bottom: var(--bzr-spacing-premedium);
        border-bottom: 0.5px solid var(--bzr-secondary-grey-scroll);
        margin-bottom: var(--bzr-spacing-medium-24);
      }
    }
  }
}

@media (max-width: 1200px) {
  .main {
    max-width: none;

    .main_section {
      padding: 0 40px;
      gap: 20px;

      .aside {
        width: 100%;
      }

      .main_content {
        width: 100%;
      }
    }
  }
}

@include bzr-breakpoint('tablet') {
  .main {
    .header {
      width: 100vw;
      background-color: var(--bzr-main-bg);
      position: fixed;
      z-index: 1001;
      top: 0;
      left: 0;
      border-bottom: 0.5px solid var(--bzr-secondary-light-grey);

      .primary_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--bzr-spacing-xs) var(--bzr-spacing-medium);
        height: 43px; // нужна высота, тк если передать только title, то высота станет меньше, потому что title абсолютно спозиционорован

        .header_left {
          justify-items: left;
        }

        .header_title {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-size: 16px;
          font-weight: 600;
        }

        .header_right {
          justify-items: right;
        }
      }
    }
    .main_section {
      padding: 0 20px;

      .main_content {
        width: 100%;
      }
      &__no_header {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .main {
    .header {
      .primary_header {
        padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
      }
    }

    .main_section {
      padding: 0 var(--bzr-spacing-small);

      &__no_header {
        margin-top: 0;
      }
    }
  }
}