.button {
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  flex-shrink: 0;

  svg {
    font-size: 32px;
    color: #2c2d31;
  }

  &:hover {
    background: #efefef;
  }
}
