@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($gap: var(--bzr-spacing-premedium)) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.review_block {
  @include flex-column($gap: var(--bzr-spacing-medium));
  width: 100%;
  font-family: Montserrat;

  .overview {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid var(--bzr-secondary-grey-scroll);
    padding-bottom: var(--bzr-spacing-medium-24);

    .rating {
      @include flex-column($gap: var(--bzr-spacing-xxs));
      width: 100%;

      &_stars {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xxs);

        span {
          @include font($size: 32px, $weight: 600);
        }
      }

      p {
        @include font();
      }
    }

    .scales {
      @include flex-column($gap: var(--bzr-spacing-xs));
      width: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h3 {
      @include font($weight: 600);
    }

    .block_filter_sorting {
      display: flex;
      align-items: center;
      gap: var(--bzr-spacing-medium);

      .filter {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xs);
        color: var(--bzr-text-dark-black-primary);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .review_list {
    @include flex-column($gap: var(--bzr-spacing-large-44));
    width: 100%;

    .main {
      @include flex-column($gap: var(--bzr-spacing-large-44));
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: var(--bzr-spacing-large);
    }
  }
}

@media (max-width: 1024px) {
  .review_block {
    .overview {
      @include flex-column();
      align-items: center;
      .rating p {
        font-size: 14px;
      }
    }

    .review_list {
      .header h3 {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .review_block {
    .overview {
      .rating {
        flex-direction: row;
        gap: var(--bzr-spacing-premedium);
        span {
          @include font($size: 40px, $weight: 600);
        }

        &_stars {
          flex-direction: column;
          align-items: flex-start;
          p {
            @include font($size: 12px, $color: var(--bzr-text-deep-medium-grey));
          }
        }
      }
    }

    .review_list {
      gap: var(--bzr-spacing-premedium);
      .header {
        justify-content: flex-end;
      }

      .main {
        gap: var(--bzr-spacing-premedium);
      }
    }
  }
}
