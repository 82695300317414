.password {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  position: relative;

  input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #adadad;
    padding: 12px 55px 12px 20px;
  }

  .password_eye {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 13px;
    cursor: pointer;

    img {
      width: 16px;
      cursor: pointer;
    }
  }
}
