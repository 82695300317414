.mascot {
  width: 300px;
  height: 300px;

  img {
    width: 300px;
    height: 300px;
  }
}

.buttonM {
  padding: 15px 20px !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.buttonS {
  padding: 15px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

@media (max-width: 740px) {
  .mascot {
    width: 240px;
    height: 240px;
    img {
      width: 240px;
      height: 240px;
    }
  }
}

@media (max-width: 480px) {
  .mascot {
    width: 220px;
    height: 220px;
    img {
      width: 220px;
      height: 220px;
    }
  }
}
