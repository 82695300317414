.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  &_title {
    font-size: 40px;
    color: #0085c5;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    text-align: start;
  }
}

.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  justify-content: center;
  gap: 16px;
}

@media (max-width: 1024px) {
  .wrapper {
    &_title {
      font-size: 32px;
    }
  }
}

@media (max-width: 980px) {
  .list {
    gap: 8px 16px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 590px) {
  .wrapper {
    gap: 12px;

    &_title {
      font-size: 24px;
    }
  }

  .list {
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
}
