.sidebar {
  padding: 16px;
  border-radius: 12px;
  background: var(--bg-bg-white, #fff);
  box-shadow: -4px 4px 15px 0px rgba(31, 54, 92, 0.08);
  min-width: 300px;
  height: 100%;

  .sidebar_header {
    .admin_ava {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      cursor: pointer;
      color: black;
      transition: 1s;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
      }

      span {
        h4 {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
        p {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }

    .admin_ava:hover {
      background-color: #c7ecff;
      border-radius: 12px;
    }

    .active_admin_ava {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      cursor: pointer;
      background-color: #0085c5;
      color: #ffff;
      transition: 1s;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
      }

      span {
        h4 {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
        p {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
  }

  .sidebar_links {
    margin-top: 8px;
    height: 100%;

    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .link {
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 12px;
        list-style-type: none;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 12px;

        p {
          text-decoration: none;
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 17px;
        }

        span {
          width: 20px;
          height: 20px;
          background-color: black;
          border-radius: 100%;
        }
      }

      .link:hover {
        background-color: #c7ecff;
        border-radius: 12px;
      }

      .active_link {
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 12px;
        list-style-type: none;
        cursor: pointer;
        transition: 0.3s;
        background-color: #0085c5;
        color: #fff;
        border-radius: 12px;

        p {
          text-decoration: none;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 17px;
        }

        span {
          width: 20px;
          height: 20px;
          background-color: black;
          border-radius: 100%;
        }
      }
    }

    .logout {
      margin-top: 8px;
      button {
        width: 100%;
        padding: 12px 0;
        border-radius: 8px;
        background: #c7ecff;
        font-size: 14px;
        color: #0085c5;
        font-weight: 600;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }
}
