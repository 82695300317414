@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/media-queries';

.have_nothing {
  h3 {
    @include --bzr-h3();
    color: var(--bzr-text-dark-black-primary);
    margin-bottom: var(--bzr-spacing-premedium);
  }

  p {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 23.2px;
    color: var(--bzr-text-dark-black-secondary);
  }
}

@include bzr-breakpoint('tablet') {
  .have_nothing {
    margin: 160px auto 0;
    text-align: center;

    h4 {
      @include --bzr-h4();
      margin-bottom: var(--bzr-spacing-small);
    }

    p {
      font-size: 14px;
      line-height: 18.9px;
      margin-bottom: var(--bzr-spacing-large);
      color: var(--bzr-text-medium-grey);
    }

    .have_nothing_icon {
      font-size: 92px;
      color: var(--bzr-secondary-grey-tag);
    }
  }
}