@import '@/styles/variables/mixins/media-queries';

@mixin base-styles-text($color: #fff) {
  color: $color;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include bzr-breakpoint('tablet') {
    font-size: 13px;
  }
}

.header {
  width: 350px;

  &_title {
    text-align: left;
  }
}

.container {
  width: 350px;

  &_description {
    color: #2c2d31;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }
}

.buttons {
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  &_confirm {
    @include base-styles-text();
  }

  &_keep {
    @include base-styles-text($color: #2c2d31);
  }
}

@include bzr-breakpoint('tablet') {
  .modalContainer {
    width: 327px !important;
    min-width: 327px !important;
  }

  .header {
    width: unset;

    &_title {
      padding: 20px 20px 0 20px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .container {
    width: unset;
    padding: 8px 20px 20px 20px;

    &_description {
      color: #a7aaaf;
      font-size: 12px;
      line-height: 145%;
    }
  }

  .buttons {
    margin-top: 16px;
    gap: 8px;

    &_confirm {
      @include base-styles-text();
    }

    &_keep {
      @include base-styles-text($color: #2c2d31);
    }
  }
}
