.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-top: 44px;

  .content {
    width: 583px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-spacing_premedium, 16px);

    h3 {
      color: var(--Main-blue-default, #0085c5);
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }

    p {
      color: var(--text-deep-medium-grey, #787b80);
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 145%;
      /* 23.2px */
      align-self: stretch;
    }
  }
}

@media (max-width: 740px) {
  .container {
    padding-top: 26px;
    gap: 26px;

    .content {
      width: 100%;
      gap: var(--spacing-spacing_small, 12px);
      align-self: stretch;

      h3 {
        font-size: 26px;
      }

      p {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    gap: 20px;
    padding-top: 20px;

    .content {
      width: 100%;
      gap: var(--spacing-spacing_small, 12px);
      align-self: stretch;

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
