.checkbox {
  position: relative;
  appearance: none;
  border-radius: 2px;
  border: 1px solid #D0D0D0;
  cursor: pointer;
  box-sizing: border-box;
  flex-shrink: 0;

  &:checked {
    background: var(--bzr-main-green-default) url("../svg-icon/svg/check.svg");
    border: none;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url("../svg-icon/svg/check.svg") no-repeat center;
      background-size: contain;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(1) contrast(1);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}



.checkbox_14 {
  width: 12px;
  height: 12px;
  margin: 1px;
}

.checkbox_24 {
  width: 18px;
  height: 18px;
  margin: 3px;

}
