@mixin font($size: 11px, $weight: 500, $color: #2c2d31) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-ul($gap: 1.3rem) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.contact {
  @include flex-ul();
  grid-area: contact;
  .phone {
    text-decoration: none;
    button {
      width: 100%;
      padding: 8px var(--bzr-spacing-premedium);
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .email {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    @include font($size: 14px, $color: #414245);
    text-decoration: none;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
