.content {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-premedium);
  width: 350px;
  color: var(--bzr-text-dark-black-primary);
  &_description {
    width: 414px;
  }

  .h3 {
    font-size: 24px;
  }

  .input_wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xxs);
    width: 100%;
    input {
      width: 100%;
    }
    p {
      font-size: 12px;
      color: var(--bzr-secondary-error);
    }
    .update_code_inputs {
      display: flex;
      gap: var(--bzr-spacing-xs);
      input {
        width: 44px;
        height: 44px;
      }
    }
    .error_code {
      input {
        border-color: var(--bzr-secondary-error);
      }
    }
  }
  .area {
    width: 350px;
  }
  .error {
    font-size: 12px;
    color: var(--bzr-secondary-error);
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
      padding: 12.4px var(--bzr-spacing-premedium);
      font-size: 16px;
    }

    .continue_button {
      width: 100%;
    }
  }

  .code_sent {
    font-size: 14px;
    line-height: 21.7px;
    color: var(--bzr-text-dark-black-secondary);
    white-space: break-spaces;
  }
  .code_resend {
    cursor: pointer;
    font-size: 14px;
    color: var(--bzr-main-green-default);
  }
  .code_resend_in {
    font-size: 14px;
    color: var(--bzr-medium-grey);
  }

  .unsaved_changes {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-small);
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: var(--bzr-text-dark-black-primary);
    }
    p {
      font-size: 14px;
      color: var(--bzr-text-dark-black-primary);
    }
  }

  .email_sent {
    line-height: 21.7px;
  }
  .email_resend_button {
    padding: 0;
    width: fit-content;
  }

  .password_confirm_button {
    padding: 12.4px var(--bzr-spacing-premedium);
    font-size: 16px;
  }

  .next_phone_change {
    display: flex;
    gap: var(--bzr-spacing-xs);
    background-color: var(--bzr-secondary-very-light-blue);
    padding: var(--bzr-spacing-premedium) var(--bzr-spacing-small);
    border-radius: var(--bzr-radius-s);
    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      color: var(--bzr-main-blue-default);
    }
    p {
      font-size: 13px;
      line-height: 18.85px;
      color: var(--bzr-dark-black-primary);
    }
  }
}

@media (max-width: 480px) {
  .content {
    position: relative;
    max-width: unset;
    width: 100%;
    height: 100%;
    .input_wrapper,
    input {
      width: 100%;
    }
    .input_wrapper {
      margin-top: 2px;
    }
    .button_wrapper {
      display: flex;
      align-items: flex-end;
      flex: 1 1;
      button {
        width: 100%;
      }
      &_up {
        flex: unset;
      }
    }
    .header {
      font-size: 24px;
      color: var(--bzr-text-dark-black-primary);
    }
    .text {
      color: var(--bzr-text-dark-black-primary);
    }
    .enter_password {
      line-height: 21.7px;
    }
    .next_phone_change p {
      font-size: 12px;
      line-height: 17.4px;
    }
  }
  .confirm_modal {
    max-width: 327px;
    min-width: unset;
    .unsaved_changes {
      gap: var(--bzr-spacing-xs);
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
        color: var(--bzr-text-medium-grey);
      }
    }
    .buttons button {
      padding: 8px var(--bzr-spacing-premedium);
      font-size: 13px;
    }
  }
}