@import '@/styles/variables/mixins/media-queries';

@mixin base-styles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  @include base-styles;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: rgba(230, 230, 230, 0.4);
  box-shadow: 0px 0px 2.067px 0px rgba(127, 127, 127, 0.25);

  svg {
    width: 9.5px;
    height: 18px;
    fill: #fff;
  }

  &:hover {
    @include base-styles;
    width: 44px;
    height: 44px;
    border-radius: 110px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 2.131px 0px rgba(127, 127, 127, 0.25);

    svg {
      width: 10px;
      height: 19px;
      fill: var(--bzr-text-dark-black-primary, #2c2d31);
    }
  }
}

.rectangular {
  width: 32px;
  display: inline-flex;
  height: 64px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 0px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--bzr-secondary-very-light-grey, #efefef);

  svg {
    width: 9.5px;
    height: 18px;
    fill: var(--bzr-text-dark-black-primary, #2c2d31);
  }

  &:hover {
    background: var(--bzr-secondary-light-grey, #e2e2e2);

    svg {
      width: 9.5px;
      height: 18px;
      fill: var(--bzr-text-dark-black-primary, #2c2d31);
    }
  }

  &_notActive {
    background: var(--bzr-secondary-very-light-grey-2, #f5f5f5);

    svg {
      width: 9.5px;
      height: 18px;
      fill: #a7aaaf;
    }

    &:hover {
      cursor: not-allowed;
      background: var(--bzr-secondary-very-light-grey-2, #f5f5f5);

      svg {
        width: 9.5px;
        height: 18px;
        fill: #a7aaaf;
      }
    }
  }
}

@include bzr-breakpoint('tablet') {
  .rectangular {
    border-radius: 8px;
  }
}
