.sorting_ads {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    color: var(--bzr-text-dark-black-primary);
  }
}

@media (max-width: 1024px) {
  .sorting_ads {
    span {
      font-size: 14px;
    }
  }
}
