@import '@/styles/variables/mixins/media-queries';

.chat {
  max-width: 872px;
  min-width: 351px;
  width: 100%;

  display: flex;
  flex-direction: column;
  height: 803px; // FIXME: убрать, когда не будет баннера черной пятницы в боковом меню
  //height: 738.5px;
}

.send_message {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 16px 20px 0 20px;
  background: #fff;
  flex-shrink: 0;
}

@include bzr-breakpoint('tablet') {
  .chat {
    max-width: unset;
    min-width: unset;
    width: 100%;
    height: 100dvh;
  }

  .chat_top {
    border-bottom: 0.5px solid var(--bzr-secondary-light-grey);
    margin: 0 -20px;
  }

  .send_message {
    gap: var(--bzr-spacing-xs);
    padding: var(--bzr-spacing-small);
    border-top: 0.5px solid var(--bzr-secondary-light-grey);
    margin: 0 -20px;
    width: 100vw;
  }
}

@media (max-width: 768px) {
  .chat_top {
    margin: 0 -12px;
  }

  .send_message {
    margin: 0 -12px;
  }
}