@mixin font($size: 11px, $weight: 500, $color: #2c2d31) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-ul($gap: 1.3rem) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.footer_container {
  @include flex-ul($gap: 1.5rem);
  width: 100%;
  margin: 60px auto 0;
  padding: 0 40px var(--bzr-spacing-small);
  font-family: Montserrat, sans-serif;

  .logo img {
    width: 102.5px;
  }

  .footer_nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'categories company contact'
      'categories media .'
      'categories media .'
      'categories media .';
    text-align: start;
    row-gap: 0.5rem;
    column-gap: 2.5rem;

    div {
      @include flex-ul();
      h2 {
        @include font($size: 14px, $weight: 600);
      }
      ul {
        @include flex-ul($gap: 0.8rem);
        list-style-type: none;

        li {
          a {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            @include font($color: #4B4D52);
            text-decoration: none;
            span {
              @include font($color: #4B4D52);
            }
            svg {
              width: 24px;
              height: 24px;
              color: #2C2D31;
            }
          }
        }
      }
    }

    .categories {
      grid-area: categories;
    }

    .company {
      grid-area: company;
    }

    .social_block {
      grid-area: media;
      @include flex-ul($gap: 0.8rem);
      h2 {
        @include font($size: 16px, $weight: 600);
      }
      div {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        a {
          text-decoration: none;
          svg {
            width: 32px;
            height: 35px;
          }
        }
      }
    }
  }

  .footer_ribbon {
    @include flex-ul($gap: 0.4rem);
    @include font($size: 12px, $weight: 400, $color: #7a7a7a);
  }
}

@media (max-width: 1024px) {
  .footer_container {
    padding: 0 var(--bzr-spacing-small) 80px;
  }
}

@media (max-width: 600px) {
  .footer_container {
    margin-top: var(--bzr-spacing-large);
    .logo img {
      width: 77px;
    }
    .footer_nav {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
  }
}

@media (max-width: 480px) {
  .footer_container {
    .footer_nav {
      div,
      .social_block {
        h2 {
          font-size: 14px;
        }
        ul a {
          font-size: 11px;
          line-height: 13.41px;
        }
      }
    }
  }
}
