.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--bzr-text-dark-black-primery);
    text-align: center;
    font-size: 18px;
  }

  p {
    margin-top: var(--bzr-spacing-small);
    color: var(--bzr-text-dark-black-secondary);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 145%;
  }

  svg {
    margin: var(--bzr-spacing-large) 0;
    width: auto;
    height: auto;
  }
}