.moderator_aside {
  border-radius: 12px;
  background: #ffff;
  box-shadow: -4px 4px 15px 0px rgba(31, 54, 92, 0.08);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  height: 85vh;

  .aside_links {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .aside_link_moderator_ava {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      cursor: pointer;
      color: black;
      transition: 1s;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
      }

      span {
        h4 {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
        p {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }

    .aside_link_moderator_ava:hover {
      background-color: #c7ecff;
      border-radius: 12px;
    }

    .active_moderator_ava {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      cursor: pointer;
      background-color: #0085c5;
      color: #ffff;
      transition: 1s;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
      }

      span {
        h4 {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
        p {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }

    .aside_link_menu {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      transition: 1s;
      border-radius: 12px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px;
    }

    .aside_link_menu:hover {
      background-color: #c7ecff;
      border-radius: 12px;
    }

    .aside_link_menu_active {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      cursor: pointer;
      background-color: #0085c5;
      color: #ffff;
      transition: 1s;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px;
    }
  }

  .aside_btn_logout {
    button {
      width: 100%;
      padding: 12px 0;
      border-radius: 8px;
      background: #c7ecff;
      font-size: 14px;
      color: #0085c5;
      font-weight: 600;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}
