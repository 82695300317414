@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/caption';

.container {
  display: flex;
  width: 187px;
  flex-direction: column;
  align-items: flex-start;
  height: 177px;
  justify-content: space-between;

  .avatar_and_contacts {
    display: flex;
    flex-direction: column;

    .avatar_wrapper {
      margin-bottom: 10px;
    }

    .contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;

      p {
        @include --bzr-small-button-semibold();
        color: var(--bzr-text-dark-black-primary);
        line-break: anywhere;
      }
    }
  }

  .information {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    margin-bottom: var(--bzr-spacing-xxs);

    .created {
      @include --bzr-caption-regular();

      span {
        @include --bzr-caption-semibold(12px);
      }
    }

    .user {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: var(--bzr-spacing-xxs);
      width: 100%;

      &_block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--bzr-spacing-xxs);
      }

      &_rating {
        @include --bzr-caption-semibold(12px);
      }

      &_reviews {
        @include --bzr-caption-medium-fontsize(12px);
        color: var(--bzr-main-blue-default);
      }
    }
  }
}
