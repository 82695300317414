.radiobutton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s, background-color 0.2s;
  padding: 3px;

  width: 24px;
  height: 24px;
  border: none;
  position: relative;

  /* скрываем стандартную радиокнопку */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1.5px solid var(--bzr-secondary-grey-scroll);
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: border-color 0.2s, background-color 0.2s;
  }

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s;
  }

  &:checked::after {
    background-color: inherit;
  }
}

.green {
  border-color: var(--bzr-main-green-default);

  &::before {
    border-color: var(--bzr-main-green-default);
  }

  &:checked::after {
    background-color: var(--bzr-main-green-default);
  }
}

.black {
  border-color: var(--bzr-text-dark-black-secondary);

  &::before {
    border-color: var(--bzr-text-dark-black-secondary);
  }

  &:checked::after {
    background-color: var(--bzr-text-dark-black-secondary);
  }
}
.radiobutton:not(:checked)::before {
  border-color: var(--bzr-secondary-grey-scroll);
}
