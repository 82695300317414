.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}

.lds-spinner {
  --size: 80px;

  color: currentColor;
  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
}

.lds-spinner div {
  transform-origin: calc(var(--size) / 2) calc(var(--size) / 2);
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: calc(var(--size) * 0.04);
  left: calc(var(--size) * 0.46);
  width: calc(var(--size) * 0.0625);
  height: calc(var(--size) * 0.208);
  border-radius: calc(var(--size) * 5);
  background: currentColor;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -0.4s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
