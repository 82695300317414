@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/body';

.list {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style-type: none;
  cursor: pointer;
  li {
    color: #fff;
    &:hover {
      color: var(--bzr-main-blue-hover);
    }
    &:active {
      color: var(--bzr-main-blue-pressed);
    }
  }
}

@include bzr-breakpoint('tablet') {
  .list {
    gap: 36px;
    width: 100%;
    height: 42px;
    background-color: var(--bzr-main-green-default);
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-medium);
    cursor: default;
    li {
      @include --bzr-body-regular-135(14px);
      &:hover,
      &:active {
        color: #fff;
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .list {
    justify-content: space-between;
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
    li {
      @include --bzr-body-medium(13px);
    }
  }
}
