.modal {
  width: 327px !important;
  min-width: unset !important;
  &_content {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-premedium);
    p {
      font-size: 14px;
      line-height: 18.9px;
      color: var(--bzr-dark-black-primary);
    }
    &__button {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      font-size: 13px;
    }
  }
}
