@import '@/styles/variables/mixins/dotted';

@mixin font($size: 16px, $line-height: normal, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.personal_account_content {
  @include flex-column($gap: var(--bzr-spacing-large-44));
  width: 100%;

  .verification {
    @include flex-column($gap: var(--bzr-spacing-small));
    width: 100%;
    border-radius: var(--bzr-radius-s);
    padding: var(--bzr-spacing-premedium) var(--bzr-spacing-medium);
    background-color: var(--bzr-secondary-very-light-green-tags);

    h2 {
      @include font($size: 24px, $weight: 600, $line-height: 29.26px);
    }

    p {
      @include font($line-height: 23.2px);
    }

    &_button {
      padding: 12.4px 17.45px;
      font-size: 16px;
    }
  }

  .form {
    @include flex-column($gap: var(--bzr-spacing-large-44));
    width: 100%;

    .field {
      @include flex-column($gap: var(--bzr-spacing-medium-24));
      width: 100%;

      h2 {
        @include font($size: 24px, $weight: 600);
      }

      &_inner {
        display: flex;
        justify-content: space-between;
        width: 100%;

        p {
          @include font($line-height: 23.2px, $color: var(--bzr-deep-medium-grey));
        }

        .title_wrapper {
          display: flex;
          align-items: center;
          gap: var(--bzr-spacing-xs);
          height: fit-content;
          @include font($line-height: 23.2px, $color: var(--bzr-deep-medium-grey));
          .tooltip_card {
            width: 205px !important;
            @include font($line-height: 23.2px, $color: var(--bzr-deep-medium-grey));
          }
          span {
            height: 24px;
            svg {
              width: 24px;
              height: 24px;
              color: var(--bzr-main-blue-default);
            }
          }
        }

        .values {
          position: relative;
          display: flex;
          gap: var(--bzr-spacing-small);
          flex-wrap: wrap;
          width: 67%;
          min-width: 67%;

          .input_wrapper {
            @include flex-column($gap: var(--bzr-spacing-xs));
            
            .logo_preview {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100px;
              height: 100px;
              border: none;
              border-radius: 50%;
              padding: 0;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            .tax_id__input {
              width: 270px;
            }
            .error {
              padding: 0;
              @include font($size: 12px, $color: var(--bzr-secondary-error));
            }
            .placeholder {
              @include font($size: 12px, $color: var(--bzr-text-medium-grey));
            }
          }
          .tip {
            width: 100%;
            max-width: unset;
            @include font($size: 14px);
            line-height: 18.9px;
          }
        }

        .docs {
          @include flex-column($gap: var(--bzr-spacing-medium-24));
          width: 67%;
          min-width: 67%;

          ul {
            @include flex-column($gap: var(--bzr-spacing-xs));
            list-style-type: none;
            @include font($size: 14px);
            p {
              margin-bottom: var(--bzr-spacing-xxs);
              @include font($size: 14px);
            }
            li {
              display: flex;
              align-items: center;
              svg {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                color: var(--bzr-dark-black-primary);
              }
            }
          }

          .label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 200px;
            border: 1px dashed var(--bzr-secondary-light-grey);
            border-radius: var(--bzr-spacing-small);

            &_button {
              @include flex-column($gap: var(--bzr-spacing-premedium));
              align-items: center;
              width: 250px;
              button {
                width: 169px;
                font-size: 16px;
              }
              p {
                text-align: center;
                @include font($size: 13px, $weight: 400, $line-height: 18.85px, $color: var(--bzr-text-deep-medium-grey));
              }
            }
          }

          .file_list {
            @include flex-column($gap: var(--bzr-spacing-small));
            width: 100%;
            .file_wrapper {
              width: 100%;
              .file {
                display: flex;
                justify-content: space-between;
                gap: var(--bzr-spacing-xs);
                align-items: center;
                width: 100%;
                .doc_icon {
                  flex-shrink: 0;
                  width: 44px;
                  height: 44px;
                  color: var(--color-global-blue-100);
                }
                &_details {
                  @include flex-column($gap: 2px);
                  flex: 1 1;
                  overflow: hidden;
                  &_name {
                    max-width: 100%;
                    @include bzr-dotted(1);
                    @include font($size: 14px);
                  }
                  &_size {
                    @include font($size: 12px, $weight: 400, $color: var(--bzr-text-medium-grey));
                  }
                }
                .delete_icon {
                  flex-shrink: 0;
                  width: 24px;
                  height: 24px;
                  cursor: pointer;
                  color: var(--bzr-text-medium-grey);
                }
              }
              .file_error {
                @include font($size: 14px, $color: var(--bzr-secondary-error));
              }
            }
            .error {
              padding: 0;
              @include font($size: 14px, $color: var(--bzr-secondary-error));
            }
          }
        }
      }
    }

    .submit {
      padding-left: var(--bzr-spacing-large);
      padding-right: var(--bzr-spacing-large);
      font-size: 18px;
    }
  }

  .sent {
    @include flex-column($gap: var(--bzr-spacing-premedium));
    &_header {
      @include font($size: 24px, $weight: 600);
    }
    &_text {
      @include font($size: 16px, $line-height: 23.2px);
      a {
        cursor: pointer;
        @include font($size: 16px, $line-height: 23.2px, $color: var(--bzr-main-blue-default));
      }
    }
  }
}

@media (max-width: 1025px) {
  .personal_account_content {
    padding: var(--bzr-spacing-large) 0 var(--bzr-spacing-small);
  }
}

@media (max-width: 480px) {
  .personal_account_content {
    gap: var(--bzr-spacing-medium);
    padding-top: var(--bzr-spacing-xs);

    .verification {
      p {
        @include font($size: 14px, $line-height: 20.3px);
      }
      &_button {
        padding: 12px 17.2px;
        font-size: 14px;
      }
    }

    .form {
      gap: var(--bzr-spacing-large);

      .field {
        gap: var(--bzr-spacing-premedium);
        h2 {
          @include font($size: 20px, $weight: 600);
        }
        &_inner {
          flex-direction: column;
          gap: var(--bzr-spacing-small);
          p {
            font-size: 16px;
            @include font($weight: 600);
          }
          .values {
            width: 100%;
            gap: var(--bzr-spacing-small);
            .input_wrapper {
              width: 100%;
              .tax_id__input {
                width: 100%;
              }
            }
          }
          .docs {
            width: 100%;
            .label_button {
              button {
                font-size: 14px;
                font-weight: 600;
              }
              p {
                font-size: 12px;
              }
            }
            .file_list {
              .uploaded {
                @include font($size: 12px, $weight: 400, $color: var(--bzr-text-deep-medium-grey));
              }
              .file_wrapper {
                .file {
                  &_details {
                    &_name {
                      font-size: 12px;
                    }
                    &_size {
                      font-size: 10px;
                    }
                  }
                  .delete_icon {
                    width: 16px;
                    height: 16px;
                  }
                }
                .file_error {
                  font-size: 12px;
                }
              }
              .error {
                font-size: 12px;
              }
            }
          }
          .title_wrapper {
            p {
              font-size: 16px;
            }
            button {
              padding: 0;
              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .submit {
        width: 100%;
        padding: 13.6px var(--bzr-spacing-premedium);
        font-size: 14px;
        font-weight: 600;
      }
    }

    .sent {
      gap: var(--bzr-spacing-small);
      justify-content: center;
      align-items: center;
      margin-top: 40%;
      &_header {
        font-size: 20px;
      }
      &_text {
        @include font($size: 14px, $line-height: 18.9px, $color: var(--bzr-text-medium-grey));
        text-align: center;
        a {
          font-size: 14px;
        }
      }
      .assessment_icon {
        width: 92px;
        height: 92px;
        margin-top: var(--bzr-spacing-large);
        color: var(--bzr-secondary-very-light-grey);
      }
    }
  }
}
