.modal {
  position: absolute;
  z-index: 1;
  top: 24px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;

  svg {
    position: relative;
    top: 1px;
    left: -24px;
  }

  .choose_lang {
    box-shadow:
      0px 0px 1px 0px rgba(32, 32, 32, 0.05),
      0px 4px 12px 0px rgba(9, 9, 9, 0.15);
    width: 88px;
    height: 48px;
    border-radius: 12px;
    border: none;
    background-color: #ffffff;
    padding: 4px 8px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    .lang,
    .active_lang {
      width: 32px;
      font-size: 16px;
      font-weight: 500;
      color: #2c2d31;
    }

    .active_lang {
      color: #56b947;
      font-weight: 600;
    }
  }
}
