.wrapper {
  display: inline-flex;
  gap: var(--bzr-spacing-xxs);
  justify-content: center;
}

.wrapper input {
  color: var(--text-dark-black-primery, #2C2D31);
  font-size: 16px;
  text-align: center;

  border-radius: var(--spacing-spacing_small, 12px);
  border: 1px solid var(--secondary-light-grey, #E2E2E2);
  padding: 10px;
  width: 44px;
  height: 44px;
}

.wrapper_error {
  input {
    border: 1px solid var(--bzr-secondary-error);
  }
}