.section_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 159px;
  padding: 12px 20px;
  border-radius: 12px;
  font-family: Montserrat, sans-serif;

  h3 {
    flex: 1 1 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
    color: #2c2d31;
  }

  svg {
    width: 24px;
    height: 24px;
    color: #2c2d31;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}