@mixin flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.personal_settings {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-large-44);

  h2 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    color: #2c2d31;
  }

  .password_change_form {
    width: 100%;
    @include flex-column;
    gap: var(--bzr-spacing-medium-24);

    .title {
      @include flex-column;
      gap: var(--bzr-spacing-small);
      align-self: stretch;

      p {
        color: #414245;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 145%;
      }
    }

    form {
      width: 311px;
      @include flex-column;
      gap: var(--bzr-spacing-medium);

      .fields {
        @include flex-column;
        gap: var(--bzr-spacing-premedium);
        align-self: stretch;

        label {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: var(--bzr-spacing-xxs);
          font-size: 14px;
          font-weight: 500;
          color: #a7aaaf;

          .show_password {
            color: #a7aaaf;
            cursor: pointer;
          }
        }
      }

      .success, .error {
        color: #47c612;
        font-size: 14px;
        font-weight: 500;
      }
      .error {
        color: #E81313;
      }

      .save_new_password {
        max-width: 220px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  &_privacy {
    @include flex-column;
    gap: var(--bzr-spacing-medium-24);

    .change_settings {
      @include flex-column;
      gap: var(--bzr-spacing-premedium);
      align-self: stretch;

      .option {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          color: #2c2d31;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .delete_account {
    max-width: 145px;
    display: flex;
    justify-content: flex-start;
    color: var(--color-global-blue-100);
    font-size: 16px;
    font-weight: 500;
  }

  .mobile_save_new_password {
    position: absolute;
    bottom: 12px;
    left: 0;
  }
}

@media (max-width: 1024px) {
  .personal_settings {
    gap: var(--bzr-spacing-large);
    position: relative;
    height: calc(100dvh - var(--header-height, 0px));

    h2 {
      font-size: 18px;
    }

    .password_change_form {
      gap: var(--bzr-spacing-premedium);

      .title {
        gap: var(--bzr-spacing-xs);

        p {
          font-size: 12px;
        }
      }
    }

    &_privacy {
      gap: var(--bzr-spacing-premedium);

      .change_settings {
        gap: var(--bzr-spacing-small);
      }
    }
  }
}

@media (max-width: 480px) {
  .personal_settings {
    .password_change_form {
      form {
        width: 100%;
      }

      .save_new_password {
        max-width: 100%;
      }
    }

    .delete_account {
      max-width: 100%;
    }
  }
}
