@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/buttons';

.select_category_card {
  width: 288px;
  padding: 10px var(--bzr-spacing-small);
  border-radius: var(--bzr-radius-s);
  display: flex;
  justify-content: space-between;
  gap: var(--bzr-spacing-xxs);
  cursor: pointer;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--bzr-spacing-xxs);
    text-align: left;

    img {
      width: 24px;
      height: 24px;
    }

    .category_title {
      color: var(--bzr-dark-black-primary);
      @include --bzr-small-button-medium();
      font-weight: 500;
    }
  }

  .svg {
    font-size: 24px;
    color: var(--bzr-dark-black-primary);
  }
}

@media(min-width: 1440px) {
  .select_category_card:hover,
  .select_category_card.active {
    background-color: var(--bzr-secondary-very-light-grey-2);

    .category {
      .category_title {
        font-weight: 600;
        color: var(--bzr-main-blue-default);
      }
    }
    .svg {
      color: var(--bzr-main-blue-default);
    }
  }
}

@media (max-width: 1439px) {
  .select_category_card {
    width: 100%;
    min-width: 180px;
    max-width: 288px;
  }
}

@include bzr-breakpoint('tablet') {
  .select_category_card {
    width: 100%;
    max-width: none;
    padding: 0;
    height: 44px;
    align-items: center;

    .category {
      .category_title {
        font-size: 16px;
      }
    }

    .svg {
      color: var(--bzr-main-green-default);
    }
  }
}
@include bzr-breakpoint('mobile') {
  .select_category_card {
    .category {
      .category_title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}