.container {
  display: flex;
  width: 378px;
  height: 341px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
  padding-top: 8px;
}

.textareaWrapper {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-xs, 8px);

  .error {
    color: var(--bzr-secondary-error, #e81313);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}


.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--bzr-spacing-small, 12px);
  align-self: stretch;

  .button {
    padding: 12px 16px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .container {
    width: 346px;
    height: 100%;
    gap: 17px;
    padding-top: 0px;
  }

  .buttonWrapper {
    .button {
      padding: 13.5px 20px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    gap: auto;
  }

  .buttonWrapper {
    .hide {
      display: none;
    }
  }
}
