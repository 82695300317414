@mixin font($size: 14px, $weight: 500, $color: #2c2d31) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

@mixin flex-ul() {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer_container {
  width: 100%;
  padding: 32px 0;
  background-color: #fbfbfb;
  margin-top: 90px;
  font-family: Montserrat, sans-serif;

  .footer_nav {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

    ul {
      @include flex-ul();
      list-style-type: none;
      h2 {
        @include font($size: 16px, $weight: 600);
      }
      li {
        cursor: pointer;
        @include font($size: 12px);
        text-decoration: none;
        a:hover {
          color: var(--bzr-main-blue-default);
        }
      }
    }

    .logo {
      width: fit-content;
    }

    .contact {
      @include flex-ul();
      width: fit-content;
      h2 {
        @include font($size: 16px, $weight: 600);
      }
      li {
        a,
        span {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          @include font($color: #4B4D52);
          text-decoration: none;
          span {
            @include font($color: #4B4D52);
          }
          svg {
            width: 24px;
            height: 24px;
            color: #2C2D31;
          }
        }
        .phone {
          cursor: default;
        }
      }
    }

    .company {
      @include flex-ul();
      width: fit-content;
      h2 {
        @include font($size: 16px, $weight: 600);
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        a {
          cursor: pointer;
          @include font($size: 12px);
          text-decoration: none;
          word-wrap: break-word;
        }
      }
    }

    .categories {
      @include flex-ul();
      width: 322px;
      h2 {
        @include font($size: 16px, $weight: 600);
      }
      ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.8rem;
        height: 143px;
        a {
          cursor: pointer;
          @include font($size: 12px);
          text-decoration: none;
          word-wrap: break-word;
        }
      }
    }

    .social_block {
      @include flex-ul();
      width: fit-content;
      h2 {
        @include font($size: 16px, $weight: 600);
      }
      div {
        display: flex;
        gap: 0.8rem;
        a {
          text-decoration: none;
          svg {
            width: 32px;
            height: 35px;
          }
        }
      }
    }
  }

  .footer_ribbon {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: var(--bzr-spacing-large) auto 0;
    @include font($size: 12px, $weight: 400, $color: #7a7a7a);
  }
}

@media (max-width: 1240px) {
  .footer_container {
    padding: 32px 40px;
    .footer_nav {
      max-width: unset;

      .categories {
        width: fit-content;
        ul {
          width: 200px;
          height: 160px;

          a {
            width: fit-content;
            max-width: 100px;
          }
        }
      }
    }
    .footer_ribbon {
      max-width: unset;
    }
  }
}

@media (max-width: 1200px) {
  .footer_container {
    padding: 32px 120px;
    .footer_nav {
      .company {
        max-width: 140px;
      }
    }
  }
}
