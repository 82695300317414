@import '@/styles/variables/mixins/media-queries';

.input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  &_button {
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #a7aaaf;
    border-radius: 100px;
    transition:
      background 0.15s,
      color 0.15s;

    &_active {
      color: #2c2d31;
    }

    &:hover {
      background: #efefef;
      color: #2c2d31;
    }
  }
}

.input_textarea {
  width: 100%;

  .textarea {
    padding: 10.5px 15px 8px 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 145%;
  }
}

@include bzr-breakpoint('tablet') {
  .input {
    gap: var(--bzr-spacing-xs);

    &_button {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }

  .input_textarea {
    .textarea {
      padding: 6px 12px;
      font-size: 13px;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .input_textarea_active {
    width: 95%;
  }
}
