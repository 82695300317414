.answer_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-medium);


  .buttons_wrapper {
    display: flex;
    gap: var(--bzr-spacing-small);
  }
}