.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.end {
  align-items: flex-end;
}

.center {
  align-items: center;
}

.start {
  align-items: flex-start;
}

.pagination {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
}

.numeral {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 5px 4px;
  background: #fff;
  border-radius: 12px;
  border: 0.5px solid #e2e2e2;

  color: #2c2d31;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &_active {
    background: #efefef;
  }

  &:hover {
    background: #e2e2e2;
  }
}

.arrow {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: #efefef;

  svg {
    width: 24px;
    height: 24px;
    fill: #2c2d31;
  }

  &:hover {
    background: #e2e2e2;
  }

  &:disabled {
    cursor: not-allowed;
    background: #f5f5f5;

    svg {
      fill: #a7aaaf;
    }
  }
}


.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}
