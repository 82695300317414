.track {
  position: absolute;
  right: 0px !important;
  top: 0px !important;
  padding: 8px 2px !important;
  border-right: 0.5px solid #e2e2e2 !important;
  border-left: 0.5px solid #e2e2e2 !important;
  background: #fbfbfb !important;
  border-radius: 0px !important;
  height: 100% !important;
}

.thumb {
  stroke-width: 6px !important;
  stroke: #dcdbdb !important;
  background: #dcdbdb !important;
  border-radius: 30px !important;
  padding: 8px 2px !important;
}
