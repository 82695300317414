@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/buttons';

@mixin h4 {
  @include --bzr-h4;
  display: block;
  padding: 10px 0 10px 12px;
}

.container {
  display: flex;
  gap: var(--bzr-spacing-large);
  padding: 20px 0 40px 0;
  margin: 0 auto;
  width: 1200px;
  background-color: var(--bzr-main-bg);

  .list {
    display: flex;
    width: 330px;
    height: 460px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    background: #fff;

    h4 {
      display: none;
    }

    .subcategories {
      width: 300px;
      height: 520px;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

@include bzr-breakpoint('tablet') {
  .container {
    height: 100%;
    padding: 0 0 40px 0;

    .list {
      width: 310px;
      height: 100%;
      margin-left: var(--bzr-spacing-medium);
      padding: var(--bzr-spacing-premedium) var(--bzr-spacing-small) 0 0;
      border-right: 0.5px solid var(--bzr-secondary-grey-scroll);
    }
  }
}

@media (max-width: 767px) {
  .header {
    display: flex;
    justify-content: space-between;
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
    font-size: 24px;

    .logo {
      width: 77px;
      height: 24px;
    }
  }

  .container {
    flex-direction: column;
    gap: 0;

    h4 {
      @include h4();
    }

    .list {
      padding: 0;
      margin: 0;
      width: 100vw;

      h4 {
        @include h4();
      }
    }
  }

  .content {
    & div {
      width: 100%;
    }
  }

  .subcategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    width: 100vw;
    @include --bzr-small-button-semibold();

    h4 {
      padding: 10px 0;
    }

    p {
      line-height: 24px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}