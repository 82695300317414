/**
 * Обрезает строку, чтобы показывалось многоточие.
 * @param {number} $line - количество строк
 *
*/
@mixin bzr-dotted($line) {
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
}