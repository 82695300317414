@import './font';

// Body S / M
@mixin --bzr-body-semibold-115($fontSize) {
  // 16px / 14px
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 600;
  line-height: 115%;
}

@mixin --bzr-body-semibold($fontSize){
  // 16px / 14px
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 600;
  line-height: normal;
}

@mixin --bzr-body-medium($fontSize) {
  // 14px / 13px
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 500;
  line-height: normal;
}

@mixin --bzr-body-medium-145($fontSize) {
  // 16px / 13px
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 500;
  line-height: 145%;
}

@mixin --bzr-body-medium-155($fontSize) {
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 500;
  line-height: 155%;
}

@mixin --bzr-body-regular-135($fontSize) {
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 400;
  line-height: 135%;
}

@mixin --bzr-body-regular($fontSize) {
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 400;
  line-height: normal;
}

// для storybook
.bzr-body-semibold-14 { @include --bzr-body-semibold(14px); }
.bzr-body-semibold-16 { @include --bzr-body-semibold(16px); }
.bzr-body-semibold-115-14 { @include --bzr-body-semibold-115(14px); }
.bzr-body-semibold-115-16 { @include --bzr-body-semibold-115(16px); }
.bzr-body-medium-14 { @include --bzr-body-medium(14px); }
.bzr-body-medium-13 { @include --bzr-body-medium(13px); }
.bzr-body-medium-145-13 { @include --bzr-body-medium-145(13px); }
.bzr-body-medium-145-16 { @include --bzr-body-medium-145(16px); }
.bzr-body-medium-155-16 { @include --bzr-body-medium-155(16px); }
.bzr-s-body-regular-135-14 { @include --bzr-body-regular-135(14px); }
.bzr-s-body-regular-13 { @include --bzr-body-regular(13px); }

