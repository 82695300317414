.wrapper {
  position: relative;
  top: 0;
  left: 0;
}

.container {
  display: flex;
  width: 102px;
  height: 64px;
  align-items: center;
  justify-content: center;
}

.announcement {
  overflow: hidden;
  border-radius: var(--bzr-spacing-small);
  border: 0.5px solid var(--bzr-secondary-light-grey);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &_not_my {
    position: absolute;
    top: -6px;
    left: -6px;
    border: 3px solid #fff;
    border-radius: 100px;
    width: max-content;
  }
}

@media (max-width: 1024px) {
  .container {
    width: 80px;
    height: 50px;
    &_one_chat {
      height: 32px;
      width: 50px;
      &_no_ad {
        width: fit-content;
      }
    }
  }
  .announcement {
    border-radius: var(--bzr-spacing-xs);
  }
}
