.radioContainer {
  padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
  border-radius: var(--bzr-radius-s);
  border: 0.5px solid var(--bzr-secondary-light-grey);
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: var(--bzr-secondary-light-grey);
  }

  &.checked {
    background-color: var(--bzr-secondary-very-light-grey);
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    opacity: 0;
  }
}