@import '@/styles/variables/mixins/media-queries';

@mixin flex-center {
  display: flex;
  align-items: center;
}

.ad_headline {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Montserrat;

  &_title {
    display: flex;
    gap: 40px;

    .text {
      display: flex;
      gap: 40px;
      color: #0085c5;

      h1 {
        font-size: 40px;
        font-weight: 600;
      }

      .svg_icon,
      .svg_icon_active {
        font-size: 44px;
        color: #0085c5;
        cursor: pointer;
        path {
          fill-rule: evenodd;
          clip-rule: evenodd;
        }
      }

      .svg_icon_active {
        path {
          fill-rule: nonzero;
          clip-rule: nonzero;
        }
      }
    }

    .price {
      display: flex;
      align-items: baseline;
      gap: 8px;

      p {
        height: 49px;
        color: #2c2d31;
        font-size: 40px;
        font-weight: 600;
        white-space: nowrap;
        @include flex-center();
        margin-top: 2px;
        align-self: start;
      }

      svg {
        width: 29px;
        height: 49px;
      }
    }
  }
}

.copy_button {
  width: 230px;
  height: 32px;
  padding: 0px 16px;
  @include flex-center();
  justify-content: center;
  gap: 4px;
  border-radius: 12px;

  svg {
    font-size: 24px;
  }
}

@include bzr-breakpoint('tablet') {
  .ad_headline {
    &_title {
      .text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}
