@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.field {
  @include flex-column($gap: var(--bzr-spacing-medium-24));
  width: 100%;
  h2 {
    @include font($size: 24px, $weight: 600);
  }
  &_inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      @include font($color: var(--bzr-deep-medium-grey));
      line-height: 23.2px;
      span {
        color: var(--bzr-secondary-error);
      }
    }
    .values {
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-medium);
      flex-wrap: wrap;
      width: 67%;
      min-width: 67%;
      &_wrapper {
        @include flex-column($gap: var(--bzr-spacing-xs));
        .error {
          max-width: unset;
          width: 100%;
          padding: 0;
          @include font($size: 12px, $color: var(--bzr-secondary-error));
        }
        .get_sms_button {
          padding: var(--bzr-spacing-xs) var(--bzr-spacing-premedium);
        }
        .code_header {
          @include font($size: 14px);
        }
        .code_input {
          width: 110px;
          text-align: center;
          @include font($size: 14px);
        }
        .timer {
          @include font($size: 12px, $color: var(--bzr-text-medium-grey));
        }
        .get_new_code_button {
          @include font($size: 12px, $weight: 600, $color: var(--bzr-main-green-default));
        }
      }
    }
    .media {
      gap: var(--bzr-spacing-premedium);
      div {
        position: relative;
        input {
          height: 40px;
          padding-left: 42px;
          &::placeholder {
            color: var(--bzr-medium-grey);
            font-weight: 400;
          }
        }
        svg {
          position: absolute;
          top: 8px;
          left: 10px;
          width: 24px;
          height: 24px;
          color: #A7AAAF;
        }
        .whatsapp {
          left: 6px;
          top: 3px;
          width: 30px;
          height: 30px;
        }
      }
    }
    .radio {
      @include flex-column($gap: var(--bzr-spacing-small));
      padding-top: 6px;
      label {
        display: flex;
        gap: var(--bzr-spacing-xs);
        align-items: center;
      }
    }
  }
}

@media (max-width: 1024px) {
  .field {
    gap: var(--bzr-spacing-medium);
    &_inner {
      flex-direction: column;
      gap: var(--bzr-spacing-small);
      .media {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .field {
    gap: var(--bzr-spacing-premedium);
    &_inner {
      justify-content: flex-start;

      h2 {
        @include font($size: 20px, $weight: 600);
        span {
          font-weight: 500;
        }
      }

      p {
        @include font($size: 16px, $weight: 600);
      }

      .values {
        width: 100%;
      }
    }
  }
}
