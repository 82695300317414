.posters_button {
  width: 330px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #56B947;
  border-radius: 0.75rem;

  .svg {
    height: 44px;
    width: fit-content;
  }
}

@media (max-width: 1024px) {
  .posters_button {
    display: none;
  }
}
