@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/media-queries';

.modal {
  width: 502px;
  [class*="modal__title"] {
    text-align: start;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-medium);

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-premedium);

    &__old_price,
    &__new_price {
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-small);

      .header {
        @include --bzr-caption-medium-145($fontSize: 16px);
        color: var(--bzr-text-dark-black-primary);
      }

      .switcher {
        display: flex;
        gap: var(--bzr-spacing-xs);
        color: var(--bzr-text-dark-black-primary);
      }

      .error {
        font-size: 12px;
        color: var(--bzr-secondary-error);
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    gap: var(--bzr-spacing-small);
    button {
      flex: 1 0;
      font-size: 16px;
    }
  }
}

.buttons__success {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-small);
  margin-top: 10px;
  .button {
    height: 41px;
  }
}


@include bzr-breakpoint('tablet') {
  .modal {
    [class*="modal__title"] {
      font-size: 16px;
    }
  }

  .form {
    &__content__old_price,
    &__content__new_price {
      .header {
        font-size: 14px;
        font-weight: 600;
      }
    }
  
    .buttons button {
      font-size: 14px;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .modal {
    width: unset;
  }

  .buttons__success {
    margin-top: unset;
    .button {
      height: 39px;
      font-size: 12px;
    }
  }
}
