.input {
  width: 100%;
  height: 40px;
  display: inline-flex;
  padding: 11px 28px 11px 11px;
  align-items: center;
  gap: var(--bzr-spacing-xs);
  border-radius: var(--bzr-radius-s);
  border: 1px solid var(--bzr-secondary-light-grey);
  background: var(--color-white);
  line-height: 1;
  font-size: inherit;

  &:focus {
    border-color: transparent;
    outline: 3px solid var(--bzr-secondary-light-green);
  }

  &:disabled {
    background: var(--bzr-secondary-very-light-grey);
  }
}

.input_error {
  border: 1px solid var(--bzr-secondary-error);
}

.wrapper {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  .input[type='password'] {
    width: inherit;
    padding-right: 28px;
    font-size: 18px;
    line-height: 1;
    font-weight: 900;
  }

  .input[type='password']:placeholder-shown {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.password_icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  transform: translate(-5px, 8px);
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--bzr-text-medium-grey);
  }
}
