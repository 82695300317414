@mixin text-style($color, $font-size, $font-weight) {
  color: $color;
  font-family: Montserrat;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: normal;
}

@mixin hover-style {
  cursor: pointer;
  color: #0085c5;
}

.container {
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  .title {
    @include text-style(#2c2d31, 16px, 600);
    display: inline;

    &:hover {
      @include hover-style;
    }

    .arrow {
      white-space: nowrap;
    }
  }


  .items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    &_item {
      display: flex;
      padding: 0px;
      align-items: center;
      gap: 0px;
      align-self: stretch;

      @include text-style(#2c2d31, 14px, 500);

      &:hover {
        @include hover-style;
      }
    }
  }

  .showMore {
    @include text-style(#0085c5, 12px, 600);
    cursor: pointer;
  }
}