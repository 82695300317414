.wrapper {
    display: inline-flex;
    padding: 2px;
    align-items: center;
    gap: var(--bzr-spacing-xs);
    border-radius: var(--bzr-radius-s);
    border: 1px solid var(--bzr-secondary-light-grey);
    background: var(--color-white);
}

.wrapper_fullWidth {
    width: 100%;
}

.wrapper_error {
    border: 1px solid var(--bzr-secondary-error);
}

.wrapper:global {
    &.PhoneInput--focus {
        border-color: transparent;
        outline: 3px solid var(--bzr-secondary-light-green);
    }

    &.PhoneInput--disabled {
        background: var(--bzr-secondary-very-light-grey);
    }
}

.wrapper :global .PhoneInputCountry {
    display: flex;
    width: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: var(--bzr-secondary-very-light-grey);
    padding: 10px 0px;

    .PhoneInputCountryIcon--border {
        box-shadow: none;
    }

    .PhoneInputCountrySelectArrow {
        display: none;
    }
}