.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-small);
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--bzr-spacing-small);

  > div {
    width: 100%;
  }
}