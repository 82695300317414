@import '../../../../styles/variables/mixins/fonts/caption';

.container {
  position: relative;

  // класс используется в src/modules/ads/components/ads-infinity-list/ads-infinity-page.module.scss
  // поменять в том файле, если меняется здесь
  .sorting_button {
    gap: var(--bzr-spacing-xs);
    @include --bzr-caption-medium-fontsize(13px);
    padding: 0 !important;
    color: var(--bzr-text-dark-black-primary);
    &:hover,
    &:active {
      color: var(--bzr-main-blue-default);
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .modal_content {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-small);

    .options {
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-xs);

      label {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-small);
        height: var(--bzr-spacing-large);
      }
    }
  }

  // класс используется в src/modules/ads/components/ads-infinity-list/ads-infinity-page.module.scss
  // поменять в том файле, если меняется здесь
  .modal {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 2;
    display: flex;
    gap: var(--spacing-spacing_premedium, 16px);
    width: 210px;
    background-color: white;
    border-radius: var(--bzr-spacing-small);
    box-shadow: 0px 4px 12px 0px #09090926, 0px 0px 1px 0px #2020200D;
    &_right {
      left: unset;
      right: 0;
    }

    .options {
      width: 100%;
      .label {
        display: flex;
        align-items: center;
        padding: var(--bzr-spacing-small);
        font-size: 13px;
        cursor: pointer;
        input {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 0;
        }
        &:hover {
          background-color: var(--bzr-secondary-very-light-grey-2);
        }
      }
      .active {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xs);
        background-color: var(--bzr-secondary-very-light-grey-2);
        padding: var(--bzr-spacing-small);
        border-radius: var(--bzr-spacing-small) var(--bzr-spacing-small) 0 0;
        cursor: default;
        svg {
          width: 16px;
          height: 16px;
          color: var(--bzr-main-green-default);
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-small);

  .options {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);

    label {
      display: flex;
      align-items: center;
      gap: var(--bzr-spacing-small);
      height: var(--bzr-spacing-large);
    }
  }
}
