@mixin font($size: 12px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

.system_information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%; // заменить на 100%
  border-top: 0.5px solid var(--bzr-secondary-light-grey);
  padding-top: var(--bzr-spacing-premedium);
  font-family: Montserrat;
  grid-area: system_info;

  .data {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    p {
      @include font($size: 12px, $color: var(--bzr-text-medium-grey));
    }
  }

  .complaint_button {
    padding: 8.8px var(--bzr-spacing-premedium);
    @include font($size: 14px);
  }
}



@media (max-width: 768px) {
  .system_information {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: var(--bzr-spacing-premedium);
    border-top: none;
    padding-top: 0;

    .complaint_button {
      width: 100%;
      @include font();
    }

    .data {
      gap: var(--bzr-spacing-xxs);
      p {
        @include font($size: 11px, $weight: 400, $color: var(--bzr-text-medium-grey));
      }
    }
  }
}