.category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-xxs);
  font-family: Montserrat;

  .current {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);
    cursor: pointer;

    p {
      color: var(--bzr-text-dark-black-primary);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    svg {
      font-size: 24px;
    }

    &:hover {
      p,
      svg {
        color: var(--bzr-main-green-default);
      }
    }

    &.no_hover {
      &:hover {
        p,
        svg {
          color: var(--bzr-text-dark-black-primary);
        }
      }
    }

    &_active {
      p, svg {
        color: var(--bzr-main-green-default) !important;
      }
    }
  }

  .items {
    display: flex;
    padding-left: var(--bzr-spacing-large);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--bzr-spacing-xs);
    cursor: pointer;

    p {
      color: var(--bzr-text-dark-black-primary);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &:hover {
        color: var(--bzr-main-green-default);
      }
    }

    .active {
      color: var(--bzr-main-green-default);
    }
  }

  .items + .current:hover {
    .title,
    svg {
      color: var(--bzr-text-dark-black-primary);
    }
  }
}
