.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
  }
}

.container {
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1000px;
  aspect-ratio: 1.628;
  max-height: 614px;
  margin: 0 auto;
}


.prev, .next {
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 51px;
  box-shadow: 0px 0px 3.1px 0px rgba(127, 127, 127, 0.25);
  background: rgba(255, 255, 255, 0.60);
  color: #2C2D31;
}

.container_hideArrow .next, .container_hideArrow .prev {
  display: none;
}

.prev {
  left: 12px;
}

.next {
  right: 12px;
}

.prev:hover, .next:hover {
  background: rgba(255, 255, 255, 0.9);
}

.footer {
  height: 72px;
  display: flex;
  gap: 4px;
  overflow: auto;
  padding: 12px 12px 16px;
}

.navigate {
  display: flex;
  gap: 8px;
  overflow: auto;
  width: 100%;
}

.navigate__item {
  background-size: cover;
  width: 68px;
  height: 44px;
  flex-shrink: 0;
  border: 1px solid #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.navigate__item_active {
  border: 2px solid #47c612;
}

.footer__navAction {
  width: 32px;
  border-radius: 12px;
  background: var(--bzr-secondary-very-light-grey-2);
  flex-shrink: 0;
  font-size: 32px;
  color: var(--bzr-text-medium-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_hideArrow .footer__navAction {
  display: none;
}

@media (max-width: 480px) {
  .container {
    width: 100%;
  }

  .prev,
  .next {
    display: none;
  }
}

.modal {
  width: 90%;
  max-width: 1000px;

  @media (max-width: 1180px) {
    width: 100%;
  }
}
