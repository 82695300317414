.switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 61px;
  height: 24px;
  border-radius: var(--bzr-radius-s);
  background-color: var(--bzr-secondary-very-light-grey-2);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 24px;
    padding: 4px 6px;
    border-radius: var(--bzr-radius-s);
    background-color: var(--bzr-secondary-light-grey);
    transition: transform 0.3s ease;

    &.thumbMoving {
      transform: translateX(30px);
    }
  }

  .lang {
    width: 18px;
    height: 15px;
    z-index: 2;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bzr-text-medium-grey);
  }

  .active {
    width: 19px;
    color: var(--bzr-text-dark-black-primary);
  }
}
