@import '@/styles/variables/mixins/fonts/body';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-xs);
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: var(--bzr-spacing-xxs);

  > div {
    padding: var(--bzr-spacing-xxs) var(--bzr-spacing-xs);
    border-radius: var(--bzr-radius-m);
    background: var(--bzr-secondary-very-light-grey);
    color: var(--bzr-text-dark-black-primary);
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--bzr-spacing-xxs);
    user-select: none;
    max-width: 100%;

    svg {
      cursor: pointer;
      font-size: 16px;
    }

    button {
      all: unset;
      display: flex;
    }
  }
}

.itemsName {
  display: flex;
  max-width: 100%;
  flex-grow: 0;
  overflow: hidden;
}

.addBtn {
  all: unset;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    font-size: 24px;
    color: var(--bzr-placeholder-medium-grey);
  }
}

.inputWrapper {
  position: relative;
}

.input {
  width: 100%;
  padding-right: 34px;
}

.nothingFound {
  color: var(--bzr-main-green-hover);
  @include --bzr-body-medium(13px);
}
