@import "@/styles/variables/mixins/media-queries";

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin font-size($size: 14px, $weight: 400) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
}

.search_input {
  @include flex-center;
  width: 100%;
  gap: 8px;
  background-color: #f5f5f5;
  font-size: 24px;
  border-radius: 12px;
  border: 2px solid transparent;

  .field {
    width: 100%;
    @include flex-center;
    gap: 8px;
    padding: 8px 0 8px 12px;

    input {
      width: 100%;
      background-color: inherit;
      @include font-size($weight: 500);
      text-align: left;
    }

    .button_delete {
      visibility: hidden;
      @include font-size($size: 24px);
      @include flex-center;
    }

    .icon {
      svg path {
        fill: #a7aaaf;
      }
    }
  }

  .search_button {
    height: 40px;
    background-color: var(--bzr-main-green-default);
    border-radius: 10px;
    border: none;
    padding: 10px 16px;
    margin-right: 2px;
    color: #ffff;
    text-align: left;
    @include font-size($size: 16px, $weight: 500);
  }

  &:focus-within {
    box-shadow: 0 0 0 2px #d9ffcb;

    .field {
      .button_delete {
        color: #a7aaaf;
        visibility: visible;
      }
    }
  }

  &.focus-within-field-button_delete {
    border: none;
  }

  &.focus-within-search_button {
    border: none;
  }
}

@media (max-width: 1024px) {
  .search_input {
    min-width: inherit;

    .field {
      padding: 8px 12px;
    }

    .search_button {
      display: none;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .search_input .field {
    input {
      pointer-events: none;
    }
  }
}
