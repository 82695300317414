.container {
  display: flex;
  align-items: center;
}

.content {
  position: relative;
  overflow: hidden;
}

.content.shadowLeft::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(
                  to right,
                  #fff 43.77%,
                  rgba(255, 255, 255, 0) 95.11%
  );
}

.content.shadowRight::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(
                  to left,
                  #fff 43.77%,
                  rgba(255, 255, 255, 0) 95.11%
  );
}

.scrollArea {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
}

.scrollArea::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}