.dots {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.dot {
  width: 12px;
  height: 12px;
  background-color: #efefef;
  border-radius: 100px;
  cursor: pointer;
}

.active {
  background-color: #47c612;
}
