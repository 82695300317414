.not_ads {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-large);
  align-self: stretch;

  .titles {
    h2 {
      color: var(--bzr-text-dark-black-primary);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 145%;
    }
  }
}

@media (max-width: 1024px) {
  .not_ads {
    align-items: center;
    margin-top: 232px;

    .titles {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      
      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24.38px;
        text-align: center;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18.9px;
        text-align: center;
        color: #a7aaaf;
      }
    }

    .button_container {
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 68px;
      padding: 12px;
      background: #fff;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media (max-width: 768px) {
  .not_ads {
    margin-top: 124px;
  }
}
