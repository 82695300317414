@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/headlines';

.ad_page {
  margin: 0 auto;

  & > div { // заголовок
    [class*="ad-headline_ad_headline_title"] {
      [class*="ad-headline_text"] {
        width: 63%;
        justify-content: space-between;

        h1 {
          word-break: break-word; // если название объявления длинное и без пробелов, то переносим слова
        }

        button {
          align-self: start;
          margin-top: 2px;

          svg {
            height: 44px !important;
            width: 44px !important;
          }
        }
      }
    }
  }

  .ad_block {
    display: grid;
    grid-template-areas:
        'photos contacts'
        'product_info .'
        'system_info .';
    grid-template-columns: 63% 34%;
    gap: 0 3%;
    margin-top: var(--bzr-spacing-large);


    .images_block {
      grid-area: photos; // все что ниже пока просто заглушка для картинок
      //width: 753px;
      width: 100%;
      margin: 0 0 44px 0;
    }

    .contacts_block {
      grid-area: contacts;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-medium-24);
    }
  }

  .all_sellers_ads {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-large);
    margin: 80px 0;

    .all_sellers_ads_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: var(--bzr-main-blue-default);
        @include --bzr-h1();
      }

      a {
        padding: var(--bzr-spacing-xxs) var(--bzr-spacing-premedium);

        svg {
          color: white;
          width: 25px;
          height: 24px;
        }
      }
    }

    .all_sellers_ads_list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 227px);
      gap: 16px;
      height: 290px;
      overflow-y: hidden;
    }
  }
}

@media (max-width: 1200px) {
  .ad_page {
    .all_sellers_ads {
      .all_sellers_ads_list {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@include bzr-breakpoint('tablet') {
  .ad_page {
    position: relative;

    .share_and_like {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--bzr-spacing-xs);
      width: fit-content;
    }

    & > div { // header
      width: 100vw;
      padding: var(--bzr-spacing-small) var(--bzr-spacing-medium);
      position: fixed;
      top: 0;
      left: 0;
      background-color: var(--bzr-main-bg);
      height: 48px;
      z-index: 1;
      margin: 0;
    }

    .ad_block {
      margin-top: 64px;

      grid-template-areas:
      'photos contacts'
      'title .'
      'product_info .'
      'system_info .';

      .images_block {
        margin: 0 0 20px 0;
      }

      .ad_title_mobile {
        grid-area: title;
        color: var(--bzr-dark-black-primary);
        margin-bottom: var(--bzr-spacing-large);
        max-width: 100%;

        h1 {
          @include --bzr-h4();
          word-break: break-all;
          margin-bottom: var(--bzr-spacing-xs);
        }

        .mobile_price {
          display: flex;
          align-items: center;
          @include --bzr-h2();
        }
      }
    }

    .all_sellers_ads {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .ad_page {
    & > div { // header
      height: 40px;
    }

    .ad_block {
      grid-template-areas:
          'photos'
          'title'
          'contacts'
          'product_info'
          'system_info';
      grid-template-columns: 100%;
      margin-top: 60px;

      .images_block {
        margin: 0 0 12px 0;
      }

      .ad_title_mobile {
        margin-bottom: var(--bzr-spacing-medium-24);

        h1 {
          @include --bzr-h5();
          margin-bottom: var(--bzr-spacing-xs);
        }

        .mobile_price {
          @include --bzr-h3();
        }
      }
    }

    .contacts_block {
      background-color: var(--bzr-secondary-white-grey);
      border-radius: var(--bzr-radius-s);
      margin-bottom: var(--bzr-spacing-medium-24);
      padding: var(--bzr-spacing-small);
      position: relative;
    }
  }
}