@import '@/styles/variables/mixins/fonts/buttons';

.switch_category {
  display: flex;
  justify-content: center;
  margin-top: var(--bzr-spacing-xxs);

  button {
    padding: 0 0 var(--bzr-spacing-premedium);
    background: none;
  }
}

.category_list {
  display: flex;
  flex-direction: column;

  .row,
  .link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    h4 {
      @include --bzr-small-button-semibold();
      color: var(--bzr-text-dark-black-primary);
    }

    svg {
      font-size: 24px;
      color: var(--bzr-text-dark-black-primary);
    }
  }

  .link .svg_checked {
    color: var(--bzr-main-green-default);
  }
}