@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $size * 1.2;
  font-weight: $weight;
  color: $color;
}

.star_line {
  display: flex;
  align-items: center;
  width: 100%;

  .line {
    position: relative;
    flex: 1 1 0;
    height: 4px;
    background-color: var(--bzr-secondary-very-light-grey);
    border-radius: var(--bzr-radius-xxs);
    margin-left: var(--bzr-spacing-premedium);
    
    .inner_line {
      position: absolute;
      left: 0;
      z-index: 1;
      display: inline-block;
      height: 4px;
      background-color: #cdcdcf;
      border-radius: var(--bzr-radius-xxs);
    }
  }

  .quantity {
    min-width: 30px;
    @include font($size: 13px);
    text-align: end;
  }
}
