@import '@/styles/variables/mixins/media-queries';

@mixin font($size: 16px, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.success {
  display: flex;
  gap: var(--bzr-spacing-large-44);
  flex: 1 1;
  &_content {
    @include flex-column($gap: var(--bzr-spacing-large-44));
    width: 50%;
    margin-top: 50px;
    .text {
      @include flex-column($gap: var(--bzr-spacing-premedium));
      h1 {
        width: 100%;
        @include font($size: 40px, $weight: 600, $color: var(--bzr-main-blue-default));
      }
      p {
        width: 100%;
        @include font();
        line-height: 24.8px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: var(--bzr-spacing-small);
      .button_new,
      .button_link {
        height: 52px;
        font-size: 16px;
      }
      .button_new {
        width: 203px;
      }
    }
  }
  .image_wrapper {
    width: 50%;
    text-align: center;
    img {
      width: 306px;
      height: 368px;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .success {
    flex-direction: column;
    align-items: center;
    gap: var(--bzr-spacing-medium);
    width: 70%;
    margin: auto;
    &_content {
      align-items: center;
      width: 100%;
      margin-top: 100px;
      .text {
        gap: var(--bzr-spacing-small);
        h1 {
          font-size: 32px;
          text-align: center;
        }
        p {
          font-size: 14px;
          line-height: 20.3px;
          text-align: center;
        }
      }
      .buttons {
        flex-direction: column;
        width: 61.5%;
        min-width: fit-content;
        .button_new,
        .button_link {
          width: 100%;
          height: 44px;
          font-size: 14px;
        }
        .button_new {
          font-weight: 600;
        }
      }
    }
    .image_wrapper {
      width: 100%;
      img {
        width: 150px;
        height: 180px;
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .success {
    width: 100%;
    &_content {
      margin-top: 70px;
      .text {
        h1 {
          font-size: 18px;
        }
      }
      .buttons {
        width: 100%;
      }
    }
    .image_wrapper {
      img {
        width: 137px;
        height: 165px;
      }
    }
  }
}
