.container {
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  border: 0.5px solid #E2E2E2;
  overflow: hidden;
  min-width: 208px;
}

.container_fullWidth {
  width: 100%;
}

.item {
  display: flex;
  flex-grow: 1;
  height: 32px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: #FFF;
  border: none;
  border-right: 0.5px solid #E2E2E2;

  cursor: pointer;
  color: #2C2D31;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:last-child {
    border-right: none;
  }
}

.kindOutline {
  &.container {
    padding: 2px;
  }

  .item {
    border-radius: inherit;
    border: none;
  }
}

.buttonSizeL {
  .item  {
    min-height: 40px;
  }
}

.pressed {
  background: #EFEFEF;
  font-weight: 600;
}