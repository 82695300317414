.wrapper {
  display: flex;
  align-items: center;
  gap: var(--bzr-spacing-xs);
  width: 100%;
  background-color: var(--bzr-secondary-very-light-green-tags);
  padding: var(--bzr-spacing-premedium) var(--bzr-spacing-small);
  border-radius: var(--bzr-spacing-premedium);

  svg {
    width: 24px;
    height: 24px;
    color: var(--bzr-main-green-default);
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: var(--bzr-text-dark-black-primary);
    span {
      color: var(--bzr-secondary-error);
    }
  }
}

@media (max-width: 480px) {
  .wrapper {
    align-items: start;
    padding: var(--bzr-spacing-small);
    svg {
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 12px;
      line-height: 17.4px;
    }
  }
}
