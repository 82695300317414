.code_inputs {
  display: flex;
  align-items: center;
  gap: 16px;

  input {
    border-radius: 8px;
    border: 1px solid #adadad;
    font-size: 16px;
    text-align: center;
    width: 50px;
    height: 47px;
  }
}

@media screen and (max-width: 748px) {
  .code_inputs {
    gap: 10px;

    input {
      font-size: 14px;
      width: 45px;
      height: 42px;
    }
  }
}

@media screen and (max-width: 400px) {
  .code_inputs {
    input {
      width: 40px;
      height: 37px;
    }
  }
}
