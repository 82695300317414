@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/buttons';

.modal_wrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  height: 100%;
  width: 100vw;
  padding-top: 152px;

  .header {
    display: none;
  }

  .modal_content {
    background-color: var(--bzr-main-bg);
  }

  .background {
    height: 100%;
    width: 100%;
    background-color: rgba(19, 19, 21, 40%);
  }
}

@media (max-width: 1200px) {
  .modal_wrapper {
    padding-top: 133px;
  }
}

@include bzr-breakpoint('tablet') {
  .modal_wrapper {
    position: fixed;
    padding-top: 0;
    z-index: 1001;
    background-color: var(--bzr-main-bg);

    .header {
      display: flex;
      padding: 14px var(--bzr-spacing-medium);
      justify-content: space-between;
      border-bottom: 0.5px solid var(--bzr-secondary-grey-scroll);
      @include --bzr-m-button-semibold();

      svg {
        font-size: 24px;
      }
    }

    .modal_content {
      width: 100%;
      height: 100%;
    }
    .background {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .modal_wrapper {
    .header {
      border: none;
      padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);

      .logo {
        width: 77px;
        height: 24px;
      }
    }
  }
}