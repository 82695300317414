@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/caption';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-xs);
  width: 100%;
  .error {
    font-size: 12px;
    color: var(--bzr-secondary-error);
  }
}

.input_wrapper {
  width: 110px;

  .input {
    @include --bzr-caption-medium-fontsize(14px);
    padding-right: 38px;
  }

  [class*="input-text_postfix"] {
    width: fit-content;
    height: fit-content;
    @include --bzr-caption-medium-fontsize(14px);
    right: 0;
    transform: translate(-12px, 12px);
  }
}