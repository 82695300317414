.found {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 100%;
  margin: 0 auto;
  img {
    width: 400px;
    height: 400px;
  }
  h2 {
    color: var(--text-text_black, #252528);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
  }
  h3 {
    color: var(--text-text_black, #252528);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
  }
}

@media screen and (max-width: 600px) {
  .found {
    width: 300px;
    height: 300px;
    margin-left: 40px;
    img {
      width: 300px;
      height: 300px;
    }
    h2 {
      font-size: 24px;
      line-height: 31px;
    }
    h3 {
      font-size: 24px;
      line-height: 31px;
    }
  }
}

@media screen and (max-width: 425px) {
  .found {
    width: 300px;
    height: 300px;
    margin: 0 0 100px 50px;
  }
}

@media screen and (max-width: 375px) {
  .found {
    width: 300px;
    height: 300px;
    margin: 0 0 100px 0;
  }
}
