@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/dotted';

@mixin font($size: 16px, $line-height: normal, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.personal_account_header {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  width: 100vw;
  background-color: var(--bzr-main-bg);
  padding: var(--bzr-spacing-xs) var(--bzr-spacing-medium);
  border-bottom: 0.5px solid var(--bzr-secondary-light-grey);
}

.title {
  @include --bzr-h1();
  color: var(--bzr-main-blue-default);
  width: 100%;
  padding-bottom: var(--bzr-spacing-premedium);
  border-bottom: 0.5px solid var(--bzr-secondary-grey-scroll);
  margin-bottom: var(--bzr-spacing-medium-24);
}

.personal_account_content {
  @include flex-column($gap: 36px);
  width: 100%;

  .user_info {
    display: flex;
    gap: var(--bzr-spacing-xxl);
    width: 100%;

    .user_avatar_wrapper {
      position: relative;
      width: 187px;

      .add_photo_icon {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background-color: var(--bzr-secondary-light-grey);
        border: none;
        border-radius: 50%;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
          color: var(--bzr-text-dark-black-primary);
        }
      }
    }

    .info {
      @include flex-column($gap: var(--bzr-spacing-small));
      width: 70%;

      p {
        @include font($line-height: 23.2px);
      }
      .user_name {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xs);
        @include font($line-height: 23.2px);
        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
          color: var(--bzr-text-dark-black-primary);
        }
      }
    }
  }

  .contacts,
  .section {
    @include flex-column($gap: var(--bzr-spacing-medium-24));
    width: 100%;

    h2 {
      @include font($size: 24px, $weight: 600);
    }
  }

  .toast {
    padding: var(--bzr-spacing-small) var(--bzr-spacing-medium);
    font-size: 16px;
  }
}

@media (max-width: 1025px) {
  .personal_account_header .header_title {
    font-size: 16px;
    font-weight: 600;
    color: var(--bzr-dark-black-primary);
  }
  .personal_account_content {
    margin-top: 55px;
  }
}

@media (max-width: 600px) {
  .personal_account_content {
    gap: var(--bzr-spacing-large);
    .user_info {
      flex-direction: column;
      align-items: center;
      gap: var(--bzr-spacing-small);
      width: 100%;
      .user_avatar_wrapper {
        width: fit-content;
        .add_photo_icon {
          left: 70%;
          width: 24px;
          height: 24px;
          background-color: #cdcdcf;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .info {
        gap: var(--bzr-spacing-xs);
        align-items: center;
        p {
          font-size: 14px;
        }
        .id {
          font-size: 12px;
          color: var(--bzr-text-medium-grey);
        }
      }
    }
    .contacts,
    .section {
      gap: var(--bzr-spacing-premedium);
      width: 100%;
      h2 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 480px) {
  .personal_account_content {
    .user_info .info {
      .user_name,
      .seller {
        display: none;
      }
    }
  } 
}
