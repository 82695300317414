@import './font';

@mixin --bzr-caption-semibold($fontSize) {
  // 12px или 11px
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 600;
  line-height: normal;
}

@mixin --bzr-caption-medium-fontsize($fontSize) {
  // 12px или 10px
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 500;
  line-height: normal;
}
@mixin --bzr-caption-medium-125() {
  @include bzr-montserrat();
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
}
@mixin --bzr-caption-medium-145($fontSize: 12px) {
  @include bzr-montserrat();
  font-size: $fontSize;
  font-weight: 500;
  line-height: 145%;
}

@mixin --bzr-caption-regular() {
  @include bzr-montserrat();
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

@mixin --bzr-caption-medium() {
  @include bzr-montserrat();
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
}

.bzr-caption-medium-125 {
  @include --bzr-caption-medium-125();
}
.bzr-caption-medium-145 {
  @include --bzr-caption-medium-145();
}
.bzr-caption-regular {
  @include --bzr-caption-regular();
}
.bzr-caption-medium {
  @include --bzr-caption-medium();
}

// для storybook
.bzr-caption-semibold-12 {
  @include --bzr-caption-semibold(12px);
}

.bzr-caption-medium-fontsize-12 {
  @include --bzr-caption-medium-fontsize(12px);
}

.bzr-caption-semibold-11 {
  @include --bzr-caption-semibold(11px);
}

.bzr-caption-medium-fontsize-10 {
  @include --bzr-caption-medium-fontsize(10px);
}
