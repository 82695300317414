@import '@/styles/variables/mixins/fonts/caption';

.label {
  display: flex;
  align-items: center;
  gap: 3.5px;
  cursor: pointer;

  span {
    @include --bzr-caption-medium-fontsize(14px);
    color: var(--bzr-dark-black-primary);
  }
}