@mixin font($size: 16px, $line-height: normal, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.form {
  @include flex-column($gap: var(--bzr-spacing-large-44));
  .submit_wrapper {
    display: grid;
    grid-template-columns: 187px auto auto;
    grid-template-areas: 'empty submit view';
    gap: 10px;
    .submit,
    .view {
      width: fit-content;
      font-size: 18px;
      white-space: nowrap;
    }
    .submit {
      grid-area: submit;
      margin-left: 70px;
    }
    .view {
      grid-area: view;
    }
  }
}

.input_wrapper {
  position: relative;
  width: 100%;
  .path_input {
    padding-left: 285px;
  }
  input {
    padding-left: 42px;
    &::placeholder {
      @include font($size: 14px, $weight: 400, $color: var(--bzr-text-medium-grey));
    }
  }
  .path {
    position: absolute;
    left: 10px;
    top: 11px;
    @include font($size: 14px, $color: var(--bzr-text-medium-grey));
  }
  .media_icon,
  .close_icon {
    position: absolute;
    top: 8px;
    width: 24px;
    height: 24px;
    color: var(--bzr-text-medium-grey);
  }
  .whatsapp {
    left: 6px;
    top: 3px;
    width: 30px;
    height: 30px;
  }
  .media_icon {
    left: 10px;
  }
  .close_icon {
    right: 10px;
    cursor: pointer;
    &:hover {
      color: var(--bzr-text-dark-black-primary);
    }
  }

  &_images {
    @include flex-column($gap: var(--bzr-spacing-xs));
    label {
      position: relative;
      width: 100%;
      height: 108px;
      border: none;
      padding: 0;
      input {
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--bzr-secondary-very-light-grey-2);
        border-radius: var(--bzr-radius-s);
        padding: 0;
        cursor: pointer;
        color: transparent;
        &::file-selector-button {
          opacity: 0;
        }
        &::-webkit-file-upload-button {
          opacity: 0;
        }
        &::-ms-browse {
          opacity: 0;
        }
        &:focus {
          background-color: var(--bzr-secondary-very-light-grey);
          outline: 3px solid var(--bzr-secondary-light-green);
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 48px;
        cursor: pointer;
        color: var(--bzr-text-dark-black-primary);
      }
      div {
        position: absolute;
        top: 20%;
        left: 30%;
      }
    }
  }
}

.images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  .chosen_banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 108px;
    img {
      width: 100%;
      height: 108px;
      object-fit: cover;
      border-radius: var(--bzr-radius-s);
    }
    span {
      position: absolute;
      top: 7px;
      right: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
      svg {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 16px;
        height: 16px;
        color: var(--bzr-text-dark-black-primary);
      }
    }
  }
}

@media (max-width: 1024px) {
  .form {
    margin-bottom: 32px;
    br {
      display: block;
    }
  }
  .input_wrapper {
    .path_input {
      padding-left: 235px;
    }
  }
}

@media (max-width: 600px) {
  .form {
    gap: var(--bzr-spacing-large);
  }
  .contacts .contact_item {
    gap: var(--bzr-spacing-small);
    p {
      width: 150px;
    }
    .input_view {
      width: 280px;
    }
  }
}

@media (max-width: 480px) {
  .images {
    grid-template-columns: repeat(2, 1fr);
    gap: 9px;
  }
}
