@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/media-queries';

.favourite_ads {
  .title_no_ads {
    @include --bzr-h3();
    color: var(--bzr-text-dark-black-primary);
  }

  .favourite_ads_wrapper {
    .favourite_ads_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--bzr-spacing-medium);

      h3 {
        @include --bzr-h3();
        color: var(--bzr-text-dark-black-primary);
      }
    }

    .ads {
      min-height: 528px;

      .favourite_pagination_wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--bzr-spacing-medium);
      }
    }
  }
}

@include bzr-breakpoint('tablet') {
  .favourite_ads {
    .title_no_ads {
      text-align: center;
      margin-top: 120px;
      font-size: 14px;
      line-height: 18.9px;
      color: var(--bzr-text-medium-grey);
    }

    .favourite_ads_wrapper {
      .favourite_ads_header {
        overflow-y: scroll;
        width: 100%;
        justify-content: left;
        gap: var(--bzr-spacing-xxs);
        margin-bottom: var(--bzr-spacing-premedium);

        .label {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: max-content;
          @include --bzr-caption-semibold(12px);
          color: var(--bzr-text-dark-black-primary);
          background-color: var(--bzr-secondary-very-light-grey-2);
          padding: 6.75px var(--bzr-spacing-small);
          border-radius: var(--bzr-radius-m);
          border: none;

          &_active {
            color: var(--bzr-text-white);
            background-color: var(--bzr-main-green-default);
          }

          input[type="checkbox"] {
            width: 0;
            height: 0;
            opacity: 0;
          }
        }
      }

      .ads {
        .favourite_pagination_wrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: var(--bzr-spacing-premedium);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .favourite_ads {
    .favourite_ads_wrapper {
      .ads {
        .favourite_pagination_wrapper {
          gap: 9px;
          justify-content: space-evenly;
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .favourite_ads {
    .favourite_ads_wrapper {
      .ads {
        .favourite_pagination_wrapper {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}


@media (max-width: 480px) {
  .favourite_ads {
    .favourite_ads_wrapper {
      .ads {
        .favourite_pagination_wrapper {
          gap: 12px 9px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .favourite_ads {
    .favourite_ads_wrapper {
      .ads {
        .favourite_pagination_wrapper {
          grid-template-columns: repeat(auto-fit, 171px);
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .favourite_ads {
    .favourite_ads_wrapper {
      .ads {
        .favourite_pagination_wrapper {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}