.wrapper {
  width: 288px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-small);
  font-family: Montserrat;

  &_fullWidth {
    width: 100%;
  }

  .icon {
    display: none;
  }

  .inputs {
    display: flex;
    align-items: flex-start;
    gap: var(--bzr-spacing-xs);

    .postfix {
      color: var(--bzr-text-dark-black-primary);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      .superscript {
        vertical-align: super;
        font-size: smaller;
      }
    }
  }
}
