@import '../../../../../styles/variables/mixins/fonts/caption';

.sorting_button {
  @include --bzr-caption-medium-fontsize(12px);
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-small);

  .options {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);

    label {
      display: flex;
      align-items: center;
      gap: var(--bzr-spacing-small);
      height: var(--bzr-spacing-large);
    }
  }
}