@import '@/styles/variables/mixins/media-queries';

.header {
  position: static;
  top: 0;
  z-index: 100;
}

.sticky {
  position: sticky;
}
