.container {
  display: flex;
  height: 32px;
  padding: 8px 12px 8px 4px;
  justify-content: center;
  align-items: center;
  gap: var(--bzr-spacing-xxs, 4px);
  border-radius: var(--bzr-spacing-premedium, 16px);
  border: 0.5px solid var(--bzr-secondary-light-grey, #e2e2e2);
  background: var(--bzr-main-bg, #fff);
  cursor: pointer;

  &_selected {
    border: 0.5px solid var(--bzr-main-green-default, #47c612);
    background: var(--bzr-secondary-very-light-green-tags, #eaffe3);
  }

  .colorOption {
    height: 24px;
    width: 24px;
    border-radius: 100%;
  }

  p {
    color: var(--bzr-text-dark-black-primary, #2c2d31);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
