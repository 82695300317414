.nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 14px;
  padding: var(--bzr-spacing-xs) var(--bzr-spacing-small) var(--bzr-spacing-medium);
  background-color: #ffffff;
  border-top: 0.5px solid #e2e2e2;
}

.route {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: #cdcdcf;
  font-size: 24px;

  .chat {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    &_count {
      margin-top: 0;
      margin-left: -10px;
    }
  }

  p {
    font-size: 10px;
    font-weight: 500;
    line-height: 12.19px;
    text-align: left;
    transition: color 0.3s;
  }

  &.active {
    color: #56b947;

    p {
      color: #2c2d31;
    }
  }
}
