.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  z-index: 10000;

  svg { // перекрываем тень рамки
    position: relative;
    top: 1px;
  }

  .city_name {
    margin-bottom: 5px;
    color: #0285c5;
    font-weight: 600;
  }

  .confirm_city {
    box-shadow: 0 0 1px 0 rgba(32, 32, 32, 0.05), 0 4px 12px 0 rgba(9, 9, 9, 0.15);
    width: 169px;
    border-radius: 12px;
    border: none;
    background-color: #FFFFFF;
    color: #1b1b1b;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 12px;
    }
    button {
      height: 32px;
      padding: 0 16px;
      border-radius: 12px;
      border: none;
    }
    .city_selector_change {
      background-color: #56B947;
      margin-bottom: 8px;
      color: white;
    }
    .city_selector_no  {
      background-color: #EFEFEF;
    }
  }

}