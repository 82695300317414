.container {
  --filter-box-height: calc(100vh - 318px);
  height: var(--filter-box-height);
}

.wrapper {
  padding-inline: 10px;
}

.mobile {
  .wrapper {
    width: 100%;
  }
}

.desktop {
  width: 288px;

  .title {
    padding: 10px 6px var(--bzr-spacing-medium);
  }

  .wrapper {
    max-height: var(--filter-box-height);
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    transition: max-height 0.3s ease;

    &_fixed {
      width: 288px;
      max-height: calc(100vh - 100px);
      position: fixed;
      top: 100px;
      z-index: 1000;
    }

    &_footerShowing {
      max-height: calc(100vh - 370px);
    }
  }
}