@import '@/styles/variables/mixins/fonts/caption';

.breadcrumbs {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: var(--bzr-spacing-premedium);

  p,
  button {
    @include --bzr-caption-medium-fontsize(12px);
    color: var(--bzr-text-medium-grey);
    cursor: pointer;
  }

  svg {
    fill: var(--bzr-text-medium-grey);
    font-size: 16px;
  }

  .last_breadcrumb {
    color: var(--bzr-dark-black-primary);
  }

}
