@import '@/styles/variables/mixins/media-queries';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttonCancel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nothingFound {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  color: #a7aaaf;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@include bzr-breakpoint('tablet') {
  .container {
    padding: 65px 0 67px 0;

    &_support {
      order: 1;
    }

    &_filter {
      order: 0;
    }

    &_list {
      order: 2;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .container {
    padding-top: 56px;
    gap: 16px;

    &_search_focus {
      padding-top: 64px;
    }

    &_support {
      margin-top: 4px;
    }

    &_list {
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  .nothingFound {
    font-size: 16px;
  }
}
