.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-xs);
  width: 100%;
}

.items {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-xs);
}

.items_full {
  overflow-y: auto;
  max-height: calc(var(--filter-box-height) / 2);
  scrollbar-width: thin;
}

.item {
  label {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.showMore {
  display: inline-block;
  user-select: none;
  color: var(--bzr-main-green-default);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

  &:hover {
    color: var(--bzr-main-green-hover);
  }
}