
.button {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  min-height: var(--bzr-spacing-large);
  border-radius: var(--corner_radius-radius_s, 12px);
  color: var(--bzr-text-medium-grey);
  background: var(--bzr-secondary-very-light-grey);

  &_animated {
    color: var(--text-white, var(--blue_main_buttons_primery-white_text_button_default, #FFF));
    background: var(--bzr-main-green-default);
    mask-image: radial-gradient(#fff, #000);
    animation: pulse 3s ease infinite;
  }

  &_reset {
    background: transparent;
    transition: opacity 0.1s ease;
    opacity: 0;
    color: var(--Main-blue-default, #0085C5);
  }
}

.footer {
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  height: calc(var(--bzr-spacing-large) + 12px);
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--bzr-spacing-xs);
  overflow: hidden;
  transition: height 0.2s ease;

  &_showReset {
    height: calc(var(--bzr-spacing-large) * 2 + 12px + var(--bzr-spacing-xs));

    .button_reset {
      opacity: 1;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  5% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  15% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}