@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/caption';

.modal {
  max-width: 661px;
  max-height: 80vh;
  [class*="modal-desktop_modal__box"] {
    overflow-x: scroll;
  }
}
.leave_feedback_form {
  .step_one {
    display: flex;
    flex-direction: column;

    h2 {
      @include --bzr-h2();
      color: var(--bzr-text-dark-black-primary);
      margin-bottom: var(--bzr-spacing-premedium);
    }

    .options {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--bzr-spacing-large);

      .option {
        display: flex;
        align-items: center;
        gap: var(--bzr-spacing-xs);
        padding: 10px 0;
        cursor: pointer;
      }
    }

    .button_continue {
      align-self: end;
      @include --bzr-m-button-medium();
    }
  }

  .loading {
    height: 35vh;
    width: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &_text {
      max-width: 20vw;
      text-align: center;
      color: #47c612;
      @include --bzr-caption-medium-fontsize(14px);
    }
  }

  .step_two {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-large);

    h2 {
      @include --bzr-h2();
      color: var(--bzr-text-dark-black-primary);
    }

    .ad_description {
      display: flex;
      padding-bottom: var(--bzr-spacing-small);
      gap: var(--bzr-spacing-premedium);
      border-bottom: 0.5px solid var(--bzr-secondary-grey-scroll);

      .image {
        width: 102px;
        height: 64px;
        border-radius: var(--bzr-radius-s);
        object-fit: cover;
      }

      .ad_description_info {
        .ad_description_title {
          @include --bzr-caption-semibold(16px);
          color: var(--bzr-dark-black-primary);
          margin-bottom: var(--bzr-spacing-xxs);
        }

        .ad_description_description {
          @include --bzr-caption-medium-fontsize(16px);
          color: var(--bzr-dark-black-primary);

          svg {
            margin-bottom: -2px;
          }
        }
      }
    }

    .info {
      display: flex;
      padding: var(--bzr-spacing-premedium) var(--bzr-spacing-small);
      gap: var(--bzr-spacing-xs);
      border-radius: var(--bzr-radius-s);
      border: none;
      background-color: var(--bzr-secondary-very-light-blue);

      .svg_wrapper {
        width: 24px;
        height: 24px;

        svg {
          font-size: 24px;
          color: var(--bzr-main-blue-default);
        }
      }

      p {
        @include --bzr-caption-medium-145();
        font-size: 13px;
        color: var(--bzr-dark-black-primary);
      }
    }

    .rating {
      h5 {
        @include --bzr-h5();
        margin-bottom: var(--bzr-spacing-small);
      }
    }

    .comment {
      h5 {
        @include --bzr-h5();
        margin-bottom: var(--bzr-spacing-small);
      }

    }

    .load_photos {
      h5 {
        @include --bzr-h5();
        margin-bottom: var(--bzr-spacing-small);
      }

      .load_photo_wrapper {
        display: grid;
        grid-template-columns: 171px auto;
        gap: 12px;
        align-items: center;
        width: 537px;

        .input {
          width: 171px;
          height: 108px;
          background-color: var(--bzr-secondary-very-light-grey-2);
          border-radius: var(--bzr-radius-s);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          input[type="file"] {
            position: absolute;
            cursor: pointer;
            width: 171px;
            height: 108px;
            opacity: 0;
          }

          svg {
            width: 40px;
            height: 36px;
            color: var(--bzr-text-dark-black-primary);
          }
        }

        p {
          width: 269px;
          @include --bzr-caption-medium-fontsize(14px);
          color: var(--bzr-text-medium-grey);
        }

        .photo {
          width: 171px;
          height: 108px;
          background-size: cover;
          background-position: center;
          border-radius: var(--bzr-radius-s);
          border: 0.5px solid var(--bzr-secondary-light-grey);
          position: relative;

          .delete_img {
            box-shadow: 0 0 6px 0 rgba(116, 116, 116, 0.25);
            display: flex;
            width: 24px;
            height: 24px;
            background-color: white;
            border-radius: var(--bzr-radius-s);
            border: none;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            right: 8px;
            top: 8px;
          }
        }

        &.has_photos {
          grid-template-columns: repeat(auto-fit, minmax(171px, 1fr));
          grid-auto-flow: dense;
          align-items: start;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: end;
      gap: var(--bzr-spacing-small);
      @include --bzr-m-button-medium();
    }
  }

  .step_three {
    h3 {
      @include --bzr-h3();
      color: var(--bzr-text-dark-black-primary);
      margin-bottom: var(--bzr-spacing-medium-24);
    }

    p {
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
      color: var(--bzr-text-dark-black-secondary);
      align-self: stretch;
    }

    svg {
      display: none;
    }
  }
  .error {
    @include --bzr-small-button-medium();
    color: var(--bzr-secondary-error);
  }
}

@media (max-width: 768px) {
  .modal {
    max-width: 100%;
    max-height: 100vh;
  }
  .leave_feedback_form {
    height: 100%;

    .loading {
      max-width: unset;
      max-height: unset;
      height: 100%;
      width: unset;

      &_text {
        max-width: 50vw;
      }
    }

    .step_two {
      h2 {
        display: none;
      }

      .rating {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          margin-bottom: 0;
        }
      }

      .load_photos {
        .load_photo_wrapper {
          width: 100%;
          p {
            width: 100%;
          }
          &.has_photos {
            grid-template-columns: repeat(auto-fit, 171px);
          }
        }
      }
    }

    .step_three {
      p {
        margin-bottom: var(--bzr-spacing-large-44);
      }

      svg {
        display: block;
        font-size: 100px;
        margin: 0 auto;
      }
    }
  }
}
