@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/dotted';
@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/fonts/buttons';

.ad_card_wrapper {
  width: 100%;
}

.ad_card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  gap: var(--bzr-spacing-xs);
  width: 227px;
  padding-bottom: var(--bzr-spacing-xs);

  img {
    border: 0.5px solid #E2E2E2;
  }

  .image {
    display: block;
    border-radius: 12px;
    width: 227px;
    aspect-ratio: 1.62; // соотношение сторон 16:10 (16.2/10)
    object-fit: cover;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xxs);
    padding: 0 var(--bzr-spacing-xxs);

    .title {
      text-decoration: none;
      h4 {
        color: var(--bzr-text-dark-black-secondary);
        text-decoration: none;
        @include --bzr-caption-medium-fontsize(14px);
        word-break: break-word;
        @include bzr-dotted(2);
      }
    }

    .price {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24.38px;
      color: var(--bzr-text-dark-black-primary);

      svg {
        width: 13px;
        height: 24px;
        color: var(--bzr-text-dark-black-primary);
      }
    }

    .location {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      color: var(--bzr-text-medium-grey);
    }
  }

  .labels {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    padding: 0 var(--bzr-spacing-xxs);
    .label {
      display: flex;
      align-items: center;
      gap: var(--bzr-spacing-xxs);
      width: fit-content;
      padding: 2px var(--bzr-spacing-xxs);
      border-radius: var(--bzr-radius-xxs);
      font-size: 12px;
      font-weight: 500;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .type {
      background-color: var(--bzr-secondary-light-green);
      color: var(--bzr-main-green-default);
      &_individual {
        background-color: var(--bzr-secondary-grey-tag);
        color: var(--bzr-text-dark-black-secondary);
        svg {
          color: var(--bzr-text-deep-medium-grey);
        }
      }
    }
    .rating {
      background-color: var(--bzr-secondary-grey-tag);
      color: var(--bzr-text-medium-grey);
      p span {
        color: var(--bzr-text-dark-black-secondary);
      }
    }
  }

  .slider_container {
    position: relative;
    width: 100%;
    height: fit-content;
    &__images {
      width: 100%;
      aspect-ratio: 1.62;
      border-radius: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    &__dots {
      position: absolute;
      bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 100%;
      height: fit-content;
    }
  }

  &__seller {
    height: 238px;
  }
}

.svg_wrapper {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 32px;
  height: 32px;
}

.extendedCard {
  display: flex;
  max-width: 872px;
  width: 100%;
  padding: 12px 0;
  align-items: flex-start;
  gap: 20px;


  &_img {
    width: 227px;
    aspect-ratio: 1.62;
    flex-shrink: 0;
    border-radius: 12px;
    background-color: lightgray;
    overflow: hidden;

    img {
      border-radius: 12px;
      border: 0.5px solid #e2e2e2;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &_info {
    width: 100%;
    //flex: 1;
    margin-right: 24px;

    &_header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      h3 {
        color: #0085c5;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 549px;
        @include bzr-dotted(1);
      }

      &_price {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        color: #2c2d31;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        svg {
          font-size: 20px;
        }
      }
    }

    &_description {
      max-width: 549px;
      padding-top: 12px;
      overflow: hidden;
      color: #414245;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Количество строк */
      -webkit-box-orient: vertical;
      white-space: normal;

      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%; /* 18.85px */
    }

    &_footer {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      &_location {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #787b80;

        svg {
          font-size: 24px;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          white-space: nowrap;
        }
      }

      &_date {
        color: #787b80;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 135%; /* 18.9px */
      }
    }
  }

  .svg_wrapper_static {
    position: relative;
    top: unset;
    right: unset;
    background-color: unset;
    padding-top: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .ad_card {
    width: 100%;
    .image {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .ad_card {
    gap: var(--bzr-spacing-small);
    height: unset;

    &__seller .image {
      min-width: 152px;
    }

    .slider_container {
      &__images {
        min-width: 152px;
      }
    }

    .description {
      .price {
        @include --bzr-small-button-semibold();
        svg {
          width: 9px;
          height: 16px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ad_card {
    &__seller {
      .image {
        min-width: 171px;
      }
    }
  }
}

@media (max-width: 490px) {
  .ad_card {
    gap: var(--bzr-spacing-xs);
    height: fit-content;
    width: 100%;

    .description {
      gap: var(--bzr-spacing-xxs);
      .title h4 {
        -webkit-line-clamp: 1;
        @include --bzr-caption-medium-fontsize(12px);
      }

      .location {
        line-height: 12.19px;
        white-space: normal;
        font-size: 10px;
      }
    }

    .labels {
      .label {
        font-size: 10px;
      }
    }

    .svg_wrapper {
      top: 7px;
      right: 7px;
      width: 24px;
      height: 24px;
      padding-top: 5px;
    }

    &__seller {
      height: fit-content;
    }
  }
}
@media (max-width: 375px) {
  .ad_card {
    &__seller {
      height: unset;
      width: calc((100vw - 24px - 9px) / 2);

      .image {
        min-width: unset;
        width: 100%;
      }
    }
  }
}