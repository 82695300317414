@import '@/styles/variables/mixins/media-queries';

@mixin flex-center {
  display: flex;
  align-items: center;
}

.toast {
  position: fixed;
  top: calc(100dvh - 20%);
  left: 50%;
  transform: translate(-50%);
  max-width: 1030px;
  width: 80vw;
  padding: 16px 20px;
  @include flex-center;
  justify-content: space-between;
  gap: 16px;
  border-radius: var(--bzr-radius-m);
  background: rgba(19, 19, 21, 0.4);
  z-index: 99;

  &_text {
    @include flex-center;
    gap: 8px;
    color: #fff;

    svg {
      flex-shrink: 0;
      font-size: 24px;
      color: #fff;
    }
  }

  .close_button {
    color: #fff;
    font-size: 24px;
    @include flex-center;
  }
}

@include bzr-breakpoint('tablet') {
  .toast {
    width: 95vw;
    &_text {
      font-size: 14px;
    }
  }
}
