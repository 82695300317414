.link {
  flex-grow: 1;

  .mini_category_card {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px var(--bzr-spacing-small);
    border-radius: var(--bzr-radius-s);
    background: var(--bzr-secondary-very-light-grey);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--bzr-text-dark-black-primary);

    .icon,
    img {
      width: 32px;
      height: 32px;
    }

    &:hover,
    &:active {
      background: var(--bzr-secondary-light-blue-categories);
    }
  }
}
