.container {
  display: flex;
  width: 378px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--bzr-spacing-large, 32px);
  padding-top: 8px;
}

.modal_header {
  gap: 0px;
  width: 378px;
}

.description {
  color: var(--bzr-text-dark-black-secondary, #414245);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
}

.button {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 0 16px !important;
  height: 44px;
}

@media (max-width: 768px) {
  .container {
    width: 346px;
    padding-top: 0px;
    gap: 24px;
  }

  .modal_header {
    width: 346px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 100%;
  }
}
