.wrapper {
  width: 288px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--bzr-spacing-small);
  font-family: Montserrat;

  .label {
    color: var(--bzr-text-dark-black-primary);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tags_list {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--bzr-spacing-xs) var(--bzr-spacing-xxs);
    flex-wrap: wrap;

    .tag {
      color: var(--bzr-text-dark-black-primary);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}
