.photo {
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: var(--bzr-text-dark-black-primary);
}

@media (max-width: 1024px) {
  .photo {
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
  }
}
