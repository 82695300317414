$color-primary: #0085c5;
$color-modern-bg: rgba(235, 235, 235, 0.4);
$color-modern-hover-bg: rgba(235, 235, 235, 0.8);
$color-modern-hover-svg: #0093db;

// Вариант classic
.classic {
  .svg_icon {
    color: $color-primary;
    width: 20px;
    height: 20px;

    path {
      fill-rule: evenodd;
      clip-rule: evenodd;
    }

    &:hover {
      width: 24px;
      height: 24px;
    }
  }

  .svg_icon_active {
    width: 20px;
    height: 20px;

    path {
      fill-rule: nonzero;
      clip-rule: nonzero;
    }
  }

  @media (max-width: 1024px) {
    .svg_icon {
      width: 20px;
      height: 20px;
    }
    .svg_icon:hover {
      width: 24px;
      height: 24px;
    }

    .svg_icon_active {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    .svg_icon {
      width: 16px;
      height: 16px;
    }

    .svg_icon:hover {
      width: 16px;
      height: 16px;
    }

    .svg_icon_active {
      width: 16px;
      height: 16px;
    }
  }
}

// Вариант modern
.modern {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background-color: $color-modern-bg;
  border-radius: 100%;

  .svg_icon {
    color: #ffffff;
    font-size: 32px;

    path {
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
  }

  &:hover {
    background-color: $color-modern-hover-bg;

    .svg_icon {
      color: $color-modern-hover-svg;
      path {
        fill-rule: nonzero;
        clip-rule: nonzero;
      }
    }
  }

  &_active {
    background: $color-modern-hover-bg;

    .svg_icon {
      font-size: 32px;
      color: $color-primary;

      path {
        fill-rule: nonzero;
        clip-rule: nonzero;
      }
    }
  }

  @media (max-width: 490px) {
    width: 24px;
    height: 24px;

    .svg_icon {
      width: 16px;
      height: 16px;
    }

    &_active {
      width: 24px;
      height: 24px;

      .svg_icon {
        width: 16px;
        height: 16px;
      }
    }

    &_mobile__not_active {
      &:hover {
        background: $color-modern-bg;
        .svg_icon {
          color: #ffffff;
      
          path {
            fill-rule: evenodd;
            clip-rule: evenodd;
          }
        }
      }
    }
  }
}
