.user_card {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-medium-24);
}

@media (max-width: 1024px) and (min-width: 769px) {
  .user_card {
    gap: var(--bzr-spacing-premedium);
  }
}

@media (max-width: 768px) {
  .user_ad_wrapper {
    width: 100%;
    background-color: var(--bzr-secondary-white-grey);
    border-radius: var(--bzr-radius-s);
    margin-bottom: var(--bzr-spacing-medium-24);
    padding: var(--bzr-spacing-small);
    position: relative;
  }
}

@media (max-width: 480px) {
  .user_card {
    gap: var(--bzr-spacing-premedium);
  }
}
