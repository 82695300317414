@import '@/styles/variables/mixins/media-queries';

@mixin container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.wrapper {
  display: flex;
  width: 100%;
  height: 96px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px 20px;
  border-radius: 0;
  background: #fff;

  .section {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .user {
    @include container;

    &_ad {
      align-items: flex-start;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 2px;
      height: 100%;
    }
  }

  .support {
    @include container;

    &_logo {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      border-radius: 12px;
      width: 102px;
      height: 64px;

      &_svg {
        width: 77px;
        height: 24px;
      }
    }

    &_title {
      color: #2c2d31;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .wrapper {
    height: unset;
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-medium);
    gap: 8px;

    &_buttonback {
      width: 32px;
      height: 32px;

      svg {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }
    }

    .user {
      gap: var(--bzr-spacing-xs);
      .info {
        display: flex;
        flex-direction: column;
        gap: 2px;
        height: 100%;

        &_name {
          gap: 4px;
        }
      }
    }

    .support {
      &_logo {
        width: 50px;
        height: 32px;
        &_svg {
          width: 43px;
          height: 13px;
        }
      }
      &_title {
        font-size: 14px;
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .wrapper {
    padding: var(--bzr-spacing-xs) var(--bzr-spacing-small);
  }
}
