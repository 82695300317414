@import '@/styles/variables/mixins/media-queries';

.label {
  display: flex;
  align-items: center;
  gap: var(--bzr-spacing-xxs);
  width: fit-content;
  padding: 6px var(--bzr-spacing-xs);
  border-radius: var(--bzr-radius-xxs);
  font-size: 13px;
  font-weight: 500;
  svg {
    width: 16px;
    height: 16px;
  }
}

.type {
  background-color: var(--bzr-secondary-light-green);
  color: var(--bzr-main-green-default);
  &_individual {
    background-color: var(--bzr-secondary-grey-tag);
    color: var(--bzr-dark-black-secondary);
    svg {
      color: var(--bzr-text-deep-medium-grey);
    }
  }
}

.mini {
  padding: 2px var(--bzr-spacing-xxs);
  font-size: 12px;
}

@include bzr-breakpoint('mobile') {
  .label {
    padding: 2px var(--bzr-spacing-xxs);
    font-size: 12px;
  }
}
