@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/body';
@import '@/styles/variables/mixins/media-queries';

.profile_summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 284px;

  &_name {
    color: #2c2d31;
    text-align: center;
    @include --bzr-h3;
  }

  &_rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }

    &__number {
      color: #252528;
      @include --bzr-m-button-semibold;
    }

    &__reviews {
      color: #0085c5;
      @include --bzr-m-button-medium;
    }
  }

  &_created {
    color: #2c2d31;
    @include --bzr-body-medium(14px);
  }
}

.description {
  color: #414245;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@include bzr-breakpoint('tablet') {
  .header {
    width: 237px;

    &_name {
      margin-top: 4px;
      @include --bzr-h4;
    }

    &_rating {
      flex-direction: column;
      &__content {
        gap: 8px;
      }

      &__reviews {
        @include --bzr-small-button-medium;
      }
    }
  }
}
