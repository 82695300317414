.search_input {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #000;
  padding: 8px 16px;
  border-radius: 100px;
}
@media screen and (max-width: 600px) {
  .search_input {
    padding: 8px 3px 8px 16px;
  }
}

@media screen and (max-width: 390px) {
  .search_input {
    padding: 8px 1px 8px 5px;
    button {
      margin-left: 5px;
    }
    input {
      width: 100%;
    }
  }
}
