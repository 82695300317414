.reason_modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Montserrat;

  .reasons {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-premedium);

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--bzr-text-dark-black-primary);
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        padding: 4px 0px;
        align-items: center;
        gap: var(--bzr-spacing-xs);
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: var(--bzr-text-dark-black-primary);
      }
    }
  }
}
