@import '@/styles/variables/mixins/media-queries';

.evants {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}

.tagItem {
  flex: 0 0 auto;
  cursor: pointer;
}

@include bzr-breakpoint('mobile') {
  .evants {
    gap: 4px;
  }
}
