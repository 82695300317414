.sorting {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  &_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    &_delete {
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      color: #a7aaaf;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.search {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  &_searchbar {
    height: 100%;

    &:hover {
      cursor: pointer;
      background-color: #efefef !important;
    }

    &:focus-within {
      background-color: #fff !important;
    }
  }

  &_field {
    padding: 4px 12px !important;

    input {
      color: #2c2d31;
      line-height: normal !important;

      &::placeholder {
        color: #a7aaaf;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &:hover {
        cursor: pointer;
      }
    }

    svg {
      font-size: 24px;
    }
  }

  &_button_delete {
    svg {
      color: #2c2d31;
    }
  }
}

.nav_delete {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 52px;
  padding: var(--bzr-spacing-xs) var(--bzr-spacing-small) var(--bzr-spacing-medium);
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-top: 0.5px solid #e2e2e2;
  background: #fff;
  z-index: 1001;

  &_button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:disabled {
      color: #a7aaaf;
    }
  }
}

@media (max-width: 600px) {
  .sorting {
    gap: 8px;

    &_buttons {
      gap: 8px;
    }
  }
}

@media (max-width: 480px) {
  .search {
    width: unset;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--secondary-very-light-grey, #efefef);

    &_field {
      padding: 0 12px !important;
    }

    &_container {
      height: unset;

      &_filed{
        padding-bottom: 0 !important;
      }
    }

    &_not_background {
      background: transparent;
    }

    button {
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: #252528;
    }
  }
}
