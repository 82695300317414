@import '@/styles/variables/mixins/dotted';

@mixin card-poster-12px {
  .card_poster {
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .poster_price {
      height: 24px;
      font-size: 12px;

      svg {
        width: 8px;
        font-size: 14px;
      }
    }

    .poster_info {
      gap: 4px;

      &_header {
        gap: 8px;

        &_title {
          font-size: 12px;
        }
      }

      &_location {
        p {
          font-size: 11px;
        }
      }
    }

    .poster_img {
      &_favorite {
        position: absolute;
        right: 8px;
        top: 8px;

        &_button {
          width: 24px;
          height: 24px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

@mixin card-poster-16px {
  .card_poster {
    .poster_img {
      .poster_price {
        font-size: 14px;

        svg {
          width: 10px;
          font-size: 17px;
        }
      }
    }

    .poster_info {
      &_header {
        gap: 8px;

        &_title {
          font-size: 16px;
        }
      }

      &_location {
        p {
          font-size: 12px;
        }
      }
    }
  }
}

.card_poster {
  display: flex;
  width: 100%;
  max-width: 288px;
  min-width: 171px;
  height: auto;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .poster_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    padding: 0px 4px;

    &_header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;

      &_title {
        color: var(--bzr-main-blue-default, #0085c5);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 135%; /* 24.3px */

        &_single {
          @include bzr-dotted(1);
          word-break: break-word;
          overflow-wrap: break-word;
        }

        &_multi {
          @include bzr-dotted(2);
          word-wrap: normal;
        }
      }
    }

    &_location {
      width: 100%;
      p {
        color: var(--bzr-text-deep-medium-grey, #787b80);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .poster_img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%; /* Создаем квадратное соотношение сторон */
    max-width: 288px;
    min-width: 171px;
    overflow: hidden;
    border-radius: 12px;
    background: #f4f4f4;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
      border: 0.5px solid var(--bzr-secondary-light-grey, #e2e2e2);
      background: #ededed;
    }

    &_favorite {
      position: absolute;
      right: 12px;
      top: 12px;
    }

    .poster_price {
      position: absolute;
      bottom: 0px;
      left: 0;
      display: flex;
      height: 28px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 0px 12px 0px 12px;
      background: var(--bzr-main-green-default, #47c612);

      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      svg {
        width: 10px;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

.extendedCard {
  max-width: 872px;
  min-width: 351px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 0px;
  align-items: flex-start;
  gap: 20px;

  &_poster_img {
    height: 171px;
    width: 171px;
    overflow: hidden;
    border-radius: 12px;
    background: #f4f4f4;
    border: 0.5px solid var(--bzr-secondary-light-grey, #e2e2e2);
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }
  }

  &_poster {
    &_section {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 44px;
      flex: 1;
      overflow: hidden;
    }

    &_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      overflow: hidden;

      &_title {
        color: var(--bzr-main-blue-default, #0085c5);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &_price {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2c2d31;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        svg {
          font-size: 20px;
          font-weight: 500;
        }
      }

      &_location {
        color: #2c2d31;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_time {
        display: flex;
        width: 199px;
        align-items: center;
        gap: 4px;

        overflow: hidden;
        color: #a7aaaf;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 15px */
        svg {
          font-size: 16px;
          fill: #a7aaaf;
        }
      }

      &_description {
        &_wrapper {
          padding-top: 8px;
        }
      }
    }
  }
}

.button_hide {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #2c2d31;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    font-size: 24px;
  }
}

.button_info {
  display: flex;
  height: 32px;
  max-width: max-content;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;

  svg {
    font-size: 24px;
  }
}

.button_moderation {
  background: #f6fbff;
  color: #0085c5;

  svg {
    color: #0085c5;
  }
}

.button_rejected {
  background: #ffe9e9;
  color: #e81313;

  svg {
    color: #e81313;
  }
}

.modal_container {
  width: 327px;
  padding: 20px;

  .modal_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;

    &_p {
      color: #2c2d31;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%; /* 18.9px */
    }

    &_button {
      display: flex;
      height: 32px;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      color: #2c2d31;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  @include card-poster-16px();
}

@media (max-width: 1024px) {
  .button_hide {
    padding: 0 !important;
    height: 24px;
  }

  .button_moderation {
    background: transparent;
  }

  .button_rejected {
    background: transparent;
  }
}

@media (min-width: 981px) and (max-width: 1024px) {
  @include card-poster-12px();
}

@media (max-width: 760px) {
  @include card-poster-16px();

  .extendedCard {
    &_poster_img {
      height: 140px;
      width: 140px;
    }

    &_poster {
      &_section {
        gap: 30px;
      }

      &_info {
        &_title {
          font-size: 16px;
        }

        &_price {
          font-size: 16px;
        }
      }

      &_location {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 650px) {
  @include card-poster-12px();
}

@media (max-width: 590px) {
  @include card-poster-16px();
}

@media (max-width: 480px) {
  @include card-poster-12px();

  .button_hide {
    padding: 0 !important;
    height: 24px;
    background: transparent;
  }

  .button_info {
    padding: 0px;
    height: 24px;
  }

  .button_moderation {
    background: transparent;
  }

  .button_rejected {
    background: transparent;
  }

  .extendedCard {
    padding: 8px 0px;
    gap: 8px;

    &_poster_img {
      height: 112px;
      width: 112px;

      img {
        height: 112px;
        width: 112px;
      }
    }

    &_poster {
      &_section {
        gap: 6px;
      }

      &_info {
        &_title {
          margin-top: -6px;
          display: flex;
          width: 199px;
          height: 16px;
          flex-direction: column;
          justify-content: center;
          color: #414245;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 115%; /* 16.1px */
          order: 2;

          a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &_price {
          p {
            color: #2c2d31;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            order: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          svg {
            font-size: 16px;
          }
        }

        &_time {
          order: 3;
        }

        &_description {
          display: none;
        }
      }
    }
  }
}
