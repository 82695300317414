// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import './variables/variables.scss';


@font-face {
  font-family: 'Montserrat';
  src: url('../../public/assets/fonts/Montserrat-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../public/assets/fonts/Montserrat-SemiBold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  border-collapse: collapse;
  border-spacing: 0;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #000;
}

.alladvh1 {
  font-size: 40px;
  color: #0085c5;
  font-weight: 600;
  line-height: 51px;
}

.global_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.main_sda {
  flex-grow: 1;
}

.layout_container {
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 1200px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  br {
    display: none;
  }

  .global_container {
    min-height: var(--global-container-height, 100vh);
  }
}

@media (max-width: 600px) {
  .layout_container {
    display: flex;
    justify-content: center;
    gap: 0px;
  }
}
@media (max-width: 480px) {
  .container {
    padding: 0 12px;
  }
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

button {
  background: none;
  cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Убираем отступ, который остается после скрытия стрелок */
}

/* Если вы хотите скрыть стрелки в Firefox, добавьте следующие стили */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Для веб-браузеров на основе Webkit (например, Chrome и Safari) */
*::-webkit-scrollbar {
  width: 0; /* Убираем горизонтальный скроллбар */
  height: 0; /* По желанию: убираем вертикальный скроллбар */
}

/* Для браузера Firefox */
* {
  scrollbar-width: none; /* Убираем горизонтальный и вертикальный скроллбары */
}

/* Для Internet Explorer */
* {
  -ms-overflow-style: none; /* Убираем горизонтальный и вертикальный скроллбары */
}

/* Основные стили редактора */
.text-editor-container .ql-container {
  height: 300px; /* Задаем фиксированную высоту */
  font-size: 16px; /* Размер шрифта в редакторе */
}

/* Стилизация панели инструментов */
.text-editor-container .ql-toolbar {
  border: 1px solid #ccc; /* Рамка вокруг панели инструментов */
  border-radius: 12px 12px 0 0; /* Скругление углов сверху */
}

/* Стилизация области редактирования */
.text-editor-container .ql-container {
  border: 1px solid #ccc; /* Рамка вокруг области редактирования */
  border-top: none; /* Убираем верхнюю границу */
  border-radius: 0 0 12px 12px; /* Скругление углов снизу */
}

/* Стилизация текста внутри редактора */
.text-editor-container .ql-editor {
  min-height: 200px; /* Минимальная высота */
  padding: 12px; /* Отступы внутри редактора */
  line-height: 1.5; /* Высота строки */
}

/* Стилизация изображений внутри редактора */
.text-editor-container .ql-editor img {
  max-width: 100%; /* Чтобы изображения не выходили за границы */
  height: auto; /* Автоматическая высота */
}

