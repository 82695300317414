.confirm_deletion {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Montserrat;

  .consequences {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-premedium);

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: var(--bzr-text-dark-black-primary);
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 145%;
      color: var(--bzr-text-dark-black-secondary);
    }
  }
}
