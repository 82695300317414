@mixin bzr-secondary-blue-button() {
  color: var(--bzr-main-blue-default);
  transition: background-color 0.15s, color 0.15s;

  &:hover:not(:disabled) {
    color: var(--bzr-main-blue-hover);
  }
  &:active:not(:disabled) {
    color: var(--bzr-main-blue-pressed);
  }
}
